import axios from './../../request/axios.js'
import api from './../../request/api.js'

const schoolService = {
  add: function (params) {
    return axios.post(api.school.add, params)
  },
  update: function (params) {
    return axios.post(api.school.update, params)
  },
  list: function (params) {
    return axios.post(api.school.list, params)
  },
  del: function (params) {
    return axios.post(api.school.del, params)
  },
  search: function (params) {
    return axios.post(api.school.search, params)
  },
  import: function (params) {
    return axios.post(api.school.import, params)
  }
}
export default schoolService
