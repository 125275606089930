export function PlaceCode () {
  const cityData = [
    {
      value: '110000',
      name: '北京市',
      children: [
        {
          value: '110100',
          name: '市辖区',
          children: [
            {
              value: '110101',
              name: '东城区'
            },
            {
              value: '110102',
              name: '西城区'
            },
            {
              value: '110105',
              name: '朝阳区'
            },
            {
              value: '110106',
              name: '丰台区'
            },
            {
              value: '110107',
              name: '石景山区'
            },
            {
              value: '110108',
              name: '海淀区'
            },
            {
              value: '110109',
              name: '门头沟区'
            },
            {
              value: '110111',
              name: '房山区'
            },
            {
              value: '110112',
              name: '通州区'
            },
            {
              value: '110113',
              name: '顺义区'
            },
            {
              value: '110114',
              name: '昌平区'
            },
            {
              value: '110115',
              name: '大兴区'
            },
            {
              value: '110116',
              name: '怀柔区'
            },
            {
              value: '110117',
              name: '平谷区'
            },
            {
              value: '110118',
              name: '密云区'
            },
            {
              value: '110119',
              name: '延庆区'
            }
          ]
        }
      ]
    },
    {
      value: '120000',
      name: '天津市',
      children: [
        {
          value: '120100',
          name: '市辖区',
          children: [
            {
              value: '120101',
              name: '和平区'
            },
            {
              value: '120102',
              name: '河东区'
            },
            {
              value: '120103',
              name: '河西区'
            },
            {
              value: '120104',
              name: '南开区'
            },
            {
              value: '120105',
              name: '河北区'
            },
            {
              value: '120106',
              name: '红桥区'
            },
            {
              value: '120110',
              name: '东丽区'
            },
            {
              value: '120111',
              name: '西青区'
            },
            {
              value: '120112',
              name: '津南区'
            },
            {
              value: '120113',
              name: '北辰区'
            },
            {
              value: '120114',
              name: '武清区'
            },
            {
              value: '120115',
              name: '宝坻区'
            },
            {
              value: '120116',
              name: '滨海新区'
            },
            {
              value: '120117',
              name: '宁河区'
            },
            {
              value: '120118',
              name: '静海区'
            },
            {
              value: '120119',
              name: '蓟州区'
            }
          ]
        }
      ]
    },
    {
      value: '130000',
      name: '河北省',
      children: [
        {
          value: '130100',
          name: '石家庄市',
          children: [
            {
              value: '130102',
              name: '长安区'
            },
            {
              value: '130104',
              name: '桥西区'
            },
            {
              value: '130105',
              name: '新华区'
            },
            {
              value: '130107',
              name: '井陉矿区'
            },
            {
              value: '130108',
              name: '裕华区'
            },
            {
              value: '130109',
              name: '藁城区'
            },
            {
              value: '130110',
              name: '鹿泉区'
            },
            {
              value: '130111',
              name: '栾城区'
            },
            {
              value: '130121',
              name: '井陉县'
            },
            {
              value: '130123',
              name: '正定县'
            },
            {
              value: '130125',
              name: '行唐县'
            },
            {
              value: '130126',
              name: '灵寿县'
            },
            {
              value: '130127',
              name: '高邑县'
            },
            {
              value: '130128',
              name: '深泽县'
            },
            {
              value: '130129',
              name: '赞皇县'
            },
            {
              value: '130130',
              name: '无极县'
            },
            {
              value: '130131',
              name: '平山县'
            },
            {
              value: '130132',
              name: '元氏县'
            },
            {
              value: '130133',
              name: '赵县'
            },
            {
              value: '130171',
              name: '石家庄高新技术产业开发区'
            },
            {
              value: '130172',
              name: '石家庄循环化工园区'
            },
            {
              value: '130181',
              name: '辛集市'
            },
            {
              value: '130183',
              name: '晋州市'
            },
            {
              value: '130184',
              name: '新乐市'
            }
          ]
        },
        {
          value: '130200',
          name: '唐山市',
          children: [
            {
              value: '130202',
              name: '路南区'
            },
            {
              value: '130203',
              name: '路北区'
            },
            {
              value: '130204',
              name: '古冶区'
            },
            {
              value: '130205',
              name: '开平区'
            },
            {
              value: '130207',
              name: '丰南区'
            },
            {
              value: '130208',
              name: '丰润区'
            },
            {
              value: '130209',
              name: '曹妃甸区'
            },
            {
              value: '130224',
              name: '滦南县'
            },
            {
              value: '130225',
              name: '乐亭县'
            },
            {
              value: '130227',
              name: '迁西县'
            },
            {
              value: '130229',
              name: '玉田县'
            },
            {
              value: '130271',
              name: '河北唐山芦台经济开发区'
            },
            {
              value: '130272',
              name: '唐山市汉沽管理区'
            },
            {
              value: '130273',
              name: '唐山高新技术产业开发区'
            },
            {
              value: '130274',
              name: '河北唐山海港经济开发区'
            },
            {
              value: '130281',
              name: '遵化市'
            },
            {
              value: '130283',
              name: '迁安市'
            },
            {
              value: '130284',
              name: '滦州市'
            }
          ]
        },
        {
          value: '130300',
          name: '秦皇岛市',
          children: [
            {
              value: '130302',
              name: '海港区'
            },
            {
              value: '130303',
              name: '山海关区'
            },
            {
              value: '130304',
              name: '北戴河区'
            },
            {
              value: '130306',
              name: '抚宁区'
            },
            {
              value: '130321',
              name: '青龙满族自治县'
            },
            {
              value: '130322',
              name: '昌黎县'
            },
            {
              value: '130324',
              name: '卢龙县'
            },
            {
              value: '130371',
              name: '秦皇岛市经济技术开发区'
            },
            {
              value: '130372',
              name: '北戴河新区'
            }
          ]
        },
        {
          value: '130400',
          name: '邯郸市',
          children: [
            {
              value: '130402',
              name: '邯山区'
            },
            {
              value: '130403',
              name: '丛台区'
            },
            {
              value: '130404',
              name: '复兴区'
            },
            {
              value: '130406',
              name: '峰峰矿区'
            },
            {
              value: '130407',
              name: '肥乡区'
            },
            {
              value: '130408',
              name: '永年区'
            },
            {
              value: '130423',
              name: '临漳县'
            },
            {
              value: '130424',
              name: '成安县'
            },
            {
              value: '130425',
              name: '大名县'
            },
            {
              value: '130426',
              name: '涉县'
            },
            {
              value: '130427',
              name: '磁县'
            },
            {
              value: '130430',
              name: '邱县'
            },
            {
              value: '130431',
              name: '鸡泽县'
            },
            {
              value: '130432',
              name: '广平县'
            },
            {
              value: '130433',
              name: '馆陶县'
            },
            {
              value: '130434',
              name: '魏县'
            },
            {
              value: '130435',
              name: '曲周县'
            },
            {
              value: '130471',
              name: '邯郸经济技术开发区'
            },
            {
              value: '130473',
              name: '邯郸冀南新区'
            },
            {
              value: '130481',
              name: '武安市'
            }
          ]
        },
        {
          value: '130500',
          name: '邢台市',
          children: [
            {
              value: '130502',
              name: '桥东区'
            },
            {
              value: '130503',
              name: '桥西区'
            },
            {
              value: '130521',
              name: '邢台县'
            },
            {
              value: '130522',
              name: '临城县'
            },
            {
              value: '130523',
              name: '内丘县'
            },
            {
              value: '130524',
              name: '柏乡县'
            },
            {
              value: '130525',
              name: '隆尧县'
            },
            {
              value: '130526',
              name: '任县'
            },
            {
              value: '130527',
              name: '南和县'
            },
            {
              value: '130528',
              name: '宁晋县'
            },
            {
              value: '130529',
              name: '巨鹿县'
            },
            {
              value: '130530',
              name: '新河县'
            },
            {
              value: '130531',
              name: '广宗县'
            },
            {
              value: '130532',
              name: '平乡县'
            },
            {
              value: '130533',
              name: '威县'
            },
            {
              value: '130534',
              name: '清河县'
            },
            {
              value: '130535',
              name: '临西县'
            },
            {
              value: '130571',
              name: '河北邢台经济开发区'
            },
            {
              value: '130581',
              name: '南宫市'
            },
            {
              value: '130582',
              name: '沙河市'
            }
          ]
        },
        {
          value: '130600',
          name: '保定市',
          children: [
            {
              value: '130602',
              name: '竞秀区'
            },
            {
              value: '130606',
              name: '莲池区'
            },
            {
              value: '130607',
              name: '满城区'
            },
            {
              value: '130608',
              name: '清苑区'
            },
            {
              value: '130609',
              name: '徐水区'
            },
            {
              value: '130623',
              name: '涞水县'
            },
            {
              value: '130624',
              name: '阜平县'
            },
            {
              value: '130626',
              name: '定兴县'
            },
            {
              value: '130627',
              name: '唐县'
            },
            {
              value: '130628',
              name: '高阳县'
            },
            {
              value: '130629',
              name: '容城县'
            },
            {
              value: '130630',
              name: '涞源县'
            },
            {
              value: '130631',
              name: '望都县'
            },
            {
              value: '130632',
              name: '安新县'
            },
            {
              value: '130633',
              name: '易县'
            },
            {
              value: '130634',
              name: '曲阳县'
            },
            {
              value: '130635',
              name: '蠡县'
            },
            {
              value: '130636',
              name: '顺平县'
            },
            {
              value: '130637',
              name: '博野县'
            },
            {
              value: '130638',
              name: '雄县'
            },
            {
              value: '130671',
              name: '保定高新技术产业开发区'
            },
            {
              value: '130672',
              name: '保定白沟新城'
            },
            {
              value: '130681',
              name: '涿州市'
            },
            {
              value: '130682',
              name: '定州市'
            },
            {
              value: '130683',
              name: '安国市'
            },
            {
              value: '130684',
              name: '高碑店市'
            }
          ]
        },
        {
          value: '130700',
          name: '张家口市',
          children: [
            {
              value: '130702',
              name: '桥东区'
            },
            {
              value: '130703',
              name: '桥西区'
            },
            {
              value: '130705',
              name: '宣化区'
            },
            {
              value: '130706',
              name: '下花园区'
            },
            {
              value: '130708',
              name: '万全区'
            },
            {
              value: '130709',
              name: '崇礼区'
            },
            {
              value: '130722',
              name: '张北县'
            },
            {
              value: '130723',
              name: '康保县'
            },
            {
              value: '130724',
              name: '沽源县'
            },
            {
              value: '130725',
              name: '尚义县'
            },
            {
              value: '130726',
              name: '蔚县'
            },
            {
              value: '130727',
              name: '阳原县'
            },
            {
              value: '130728',
              name: '怀安县'
            },
            {
              value: '130730',
              name: '怀来县'
            },
            {
              value: '130731',
              name: '涿鹿县'
            },
            {
              value: '130732',
              name: '赤城县'
            },
            {
              value: '130771',
              name: '张家口经济开发区'
            },
            {
              value: '130772',
              name: '张家口市察北管理区'
            },
            {
              value: '130773',
              name: '张家口市塞北管理区'
            }
          ]
        },
        {
          value: '130800',
          name: '承德市',
          children: [
            {
              value: '130802',
              name: '双桥区'
            },
            {
              value: '130803',
              name: '双滦区'
            },
            {
              value: '130804',
              name: '鹰手营子矿区'
            },
            {
              value: '130821',
              name: '承德县'
            },
            {
              value: '130822',
              name: '兴隆县'
            },
            {
              value: '130824',
              name: '滦平县'
            },
            {
              value: '130825',
              name: '隆化县'
            },
            {
              value: '130826',
              name: '丰宁满族自治县'
            },
            {
              value: '130827',
              name: '宽城满族自治县'
            },
            {
              value: '130828',
              name: '围场满族蒙古族自治县'
            },
            {
              value: '130871',
              name: '承德高新技术产业开发区'
            },
            {
              value: '130881',
              name: '平泉市'
            }
          ]
        },
        {
          value: '130900',
          name: '沧州市',
          children: [
            {
              value: '130902',
              name: '新华区'
            },
            {
              value: '130903',
              name: '运河区'
            },
            {
              value: '130921',
              name: '沧县'
            },
            {
              value: '130922',
              name: '青县'
            },
            {
              value: '130923',
              name: '东光县'
            },
            {
              value: '130924',
              name: '海兴县'
            },
            {
              value: '130925',
              name: '盐山县'
            },
            {
              value: '130926',
              name: '肃宁县'
            },
            {
              value: '130927',
              name: '南皮县'
            },
            {
              value: '130928',
              name: '吴桥县'
            },
            {
              value: '130929',
              name: '献县'
            },
            {
              value: '130930',
              name: '孟村回族自治县'
            },
            {
              value: '130971',
              name: '河北沧州经济开发区'
            },
            {
              value: '130972',
              name: '沧州高新技术产业开发区'
            },
            {
              value: '130973',
              name: '沧州渤海新区'
            },
            {
              value: '130981',
              name: '泊头市'
            },
            {
              value: '130982',
              name: '任丘市'
            },
            {
              value: '130983',
              name: '黄骅市'
            },
            {
              value: '130984',
              name: '河间市'
            }
          ]
        },
        {
          value: '131000',
          name: '廊坊市',
          children: [
            {
              value: '131002',
              name: '安次区'
            },
            {
              value: '131003',
              name: '广阳区'
            },
            {
              value: '131022',
              name: '固安县'
            },
            {
              value: '131023',
              name: '永清县'
            },
            {
              value: '131024',
              name: '香河县'
            },
            {
              value: '131025',
              name: '大城县'
            },
            {
              value: '131026',
              name: '文安县'
            },
            {
              value: '131028',
              name: '大厂回族自治县'
            },
            {
              value: '131071',
              name: '廊坊经济技术开发区'
            },
            {
              value: '131081',
              name: '霸州市'
            },
            {
              value: '131082',
              name: '三河市'
            }
          ]
        },
        {
          value: '131100',
          name: '衡水市',
          children: [
            {
              value: '131102',
              name: '桃城区'
            },
            {
              value: '131103',
              name: '冀州区'
            },
            {
              value: '131121',
              name: '枣强县'
            },
            {
              value: '131122',
              name: '武邑县'
            },
            {
              value: '131123',
              name: '武强县'
            },
            {
              value: '131124',
              name: '饶阳县'
            },
            {
              value: '131125',
              name: '安平县'
            },
            {
              value: '131126',
              name: '故城县'
            },
            {
              value: '131127',
              name: '景县'
            },
            {
              value: '131128',
              name: '阜城县'
            },
            {
              value: '131171',
              name: '河北衡水高新技术产业开发区'
            },
            {
              value: '131172',
              name: '衡水滨湖新区'
            },
            {
              value: '131182',
              name: '深州市'
            }
          ]
        }
      ]
    },
    {
      value: '140000',
      name: '山西省',
      children: [
        {
          value: '140100',
          name: '太原市',
          children: [
            {
              value: '140105',
              name: '小店区'
            },
            {
              value: '140106',
              name: '迎泽区'
            },
            {
              value: '140107',
              name: '杏花岭区'
            },
            {
              value: '140108',
              name: '尖草坪区'
            },
            {
              value: '140109',
              name: '万柏林区'
            },
            {
              value: '140110',
              name: '晋源区'
            },
            {
              value: '140121',
              name: '清徐县'
            },
            {
              value: '140122',
              name: '阳曲县'
            },
            {
              value: '140123',
              name: '娄烦县'
            },
            {
              value: '140171',
              name: '山西转型综合改革示范区'
            },
            {
              value: '140181',
              name: '古交市'
            }
          ]
        },
        {
          value: '140200',
          name: '大同市',
          children: [
            {
              value: '140212',
              name: '新荣区'
            },
            {
              value: '140213',
              name: '平城区'
            },
            {
              value: '140214',
              name: '云冈区'
            },
            {
              value: '140215',
              name: '云州区'
            },
            {
              value: '140221',
              name: '阳高县'
            },
            {
              value: '140222',
              name: '天镇县'
            },
            {
              value: '140223',
              name: '广灵县'
            },
            {
              value: '140224',
              name: '灵丘县'
            },
            {
              value: '140225',
              name: '浑源县'
            },
            {
              value: '140226',
              name: '左云县'
            },
            {
              value: '140271',
              name: '山西大同经济开发区'
            }
          ]
        },
        {
          value: '140300',
          name: '阳泉市',
          children: [
            {
              value: '140302',
              name: '城区'
            },
            {
              value: '140303',
              name: '矿区'
            },
            {
              value: '140311',
              name: '郊区'
            },
            {
              value: '140321',
              name: '平定县'
            },
            {
              value: '140322',
              name: '盂县'
            }
          ]
        },
        {
          value: '140400',
          name: '长治市',
          children: [
            {
              value: '140403',
              name: '潞州区'
            },
            {
              value: '140404',
              name: '上党区'
            },
            {
              value: '140405',
              name: '屯留区'
            },
            {
              value: '140406',
              name: '潞城区'
            },
            {
              value: '140423',
              name: '襄垣县'
            },
            {
              value: '140425',
              name: '平顺县'
            },
            {
              value: '140426',
              name: '黎城县'
            },
            {
              value: '140427',
              name: '壶关县'
            },
            {
              value: '140428',
              name: '长子县'
            },
            {
              value: '140429',
              name: '武乡县'
            },
            {
              value: '140430',
              name: '沁县'
            },
            {
              value: '140431',
              name: '沁源县'
            },
            {
              value: '140471',
              name: '山西长治高新技术产业园区'
            }
          ]
        },
        {
          value: '140500',
          name: '晋城市',
          children: [
            {
              value: '140502',
              name: '城区'
            },
            {
              value: '140521',
              name: '沁水县'
            },
            {
              value: '140522',
              name: '阳城县'
            },
            {
              value: '140524',
              name: '陵川县'
            },
            {
              value: '140525',
              name: '泽州县'
            },
            {
              value: '140581',
              name: '高平市'
            }
          ]
        },
        {
          value: '140600',
          name: '朔州市',
          children: [
            {
              value: '140602',
              name: '朔城区'
            },
            {
              value: '140603',
              name: '平鲁区'
            },
            {
              value: '140621',
              name: '山阴县'
            },
            {
              value: '140622',
              name: '应县'
            },
            {
              value: '140623',
              name: '右玉县'
            },
            {
              value: '140671',
              name: '山西朔州经济开发区'
            },
            {
              value: '140681',
              name: '怀仁市'
            }
          ]
        },
        {
          value: '140700',
          name: '晋中市',
          children: [
            {
              value: '140702',
              name: '榆次区'
            },
            {
              value: '140721',
              name: '榆社县'
            },
            {
              value: '140722',
              name: '左权县'
            },
            {
              value: '140723',
              name: '和顺县'
            },
            {
              value: '140724',
              name: '昔阳县'
            },
            {
              value: '140725',
              name: '寿阳县'
            },
            {
              value: '140726',
              name: '太谷县'
            },
            {
              value: '140727',
              name: '祁县'
            },
            {
              value: '140728',
              name: '平遥县'
            },
            {
              value: '140729',
              name: '灵石县'
            },
            {
              value: '140781',
              name: '介休市'
            }
          ]
        },
        {
          value: '140800',
          name: '运城市',
          children: [
            {
              value: '140802',
              name: '盐湖区'
            },
            {
              value: '140821',
              name: '临猗县'
            },
            {
              value: '140822',
              name: '万荣县'
            },
            {
              value: '140823',
              name: '闻喜县'
            },
            {
              value: '140824',
              name: '稷山县'
            },
            {
              value: '140825',
              name: '新绛县'
            },
            {
              value: '140826',
              name: '绛县'
            },
            {
              value: '140827',
              name: '垣曲县'
            },
            {
              value: '140828',
              name: '夏县'
            },
            {
              value: '140829',
              name: '平陆县'
            },
            {
              value: '140830',
              name: '芮城县'
            },
            {
              value: '140881',
              name: '永济市'
            },
            {
              value: '140882',
              name: '河津市'
            }
          ]
        },
        {
          value: '140900',
          name: '忻州市',
          children: [
            {
              value: '140902',
              name: '忻府区'
            },
            {
              value: '140921',
              name: '定襄县'
            },
            {
              value: '140922',
              name: '五台县'
            },
            {
              value: '140923',
              name: '代县'
            },
            {
              value: '140924',
              name: '繁峙县'
            },
            {
              value: '140925',
              name: '宁武县'
            },
            {
              value: '140926',
              name: '静乐县'
            },
            {
              value: '140927',
              name: '神池县'
            },
            {
              value: '140928',
              name: '五寨县'
            },
            {
              value: '140929',
              name: '岢岚县'
            },
            {
              value: '140930',
              name: '河曲县'
            },
            {
              value: '140931',
              name: '保德县'
            },
            {
              value: '140932',
              name: '偏关县'
            },
            {
              value: '140971',
              name: '五台山风景名胜区'
            },
            {
              value: '140981',
              name: '原平市'
            }
          ]
        },
        {
          value: '141000',
          name: '临汾市',
          children: [
            {
              value: '141002',
              name: '尧都区'
            },
            {
              value: '141021',
              name: '曲沃县'
            },
            {
              value: '141022',
              name: '翼城县'
            },
            {
              value: '141023',
              name: '襄汾县'
            },
            {
              value: '141024',
              name: '洪洞县'
            },
            {
              value: '141025',
              name: '古县'
            },
            {
              value: '141026',
              name: '安泽县'
            },
            {
              value: '141027',
              name: '浮山县'
            },
            {
              value: '141028',
              name: '吉县'
            },
            {
              value: '141029',
              name: '乡宁县'
            },
            {
              value: '141030',
              name: '大宁县'
            },
            {
              value: '141031',
              name: '隰县'
            },
            {
              value: '141032',
              name: '永和县'
            },
            {
              value: '141033',
              name: '蒲县'
            },
            {
              value: '141034',
              name: '汾西县'
            },
            {
              value: '141081',
              name: '侯马市'
            },
            {
              value: '141082',
              name: '霍州市'
            }
          ]
        },
        {
          value: '141100',
          name: '吕梁市',
          children: [
            {
              value: '141102',
              name: '离石区'
            },
            {
              value: '141121',
              name: '文水县'
            },
            {
              value: '141122',
              name: '交城县'
            },
            {
              value: '141123',
              name: '兴县'
            },
            {
              value: '141124',
              name: '临县'
            },
            {
              value: '141125',
              name: '柳林县'
            },
            {
              value: '141126',
              name: '石楼县'
            },
            {
              value: '141127',
              name: '岚县'
            },
            {
              value: '141128',
              name: '方山县'
            },
            {
              value: '141129',
              name: '中阳县'
            },
            {
              value: '141130',
              name: '交口县'
            },
            {
              value: '141181',
              name: '孝义市'
            },
            {
              value: '141182',
              name: '汾阳市'
            }
          ]
        }
      ]
    },
    {
      value: '150000',
      name: '内蒙古自治区',
      children: [
        {
          value: '150100',
          name: '呼和浩特市',
          children: [
            {
              value: '150102',
              name: '新城区'
            },
            {
              value: '150103',
              name: '回民区'
            },
            {
              value: '150104',
              name: '玉泉区'
            },
            {
              value: '150105',
              name: '赛罕区'
            },
            {
              value: '150121',
              name: '土默特左旗'
            },
            {
              value: '150122',
              name: '托克托县'
            },
            {
              value: '150123',
              name: '和林格尔县'
            },
            {
              value: '150124',
              name: '清水河县'
            },
            {
              value: '150125',
              name: '武川县'
            },
            {
              value: '150171',
              name: '呼和浩特金海工业园区'
            },
            {
              value: '150172',
              name: '呼和浩特经济技术开发区'
            }
          ]
        },
        {
          value: '150200',
          name: '包头市',
          children: [
            {
              value: '150202',
              name: '东河区'
            },
            {
              value: '150203',
              name: '昆都仑区'
            },
            {
              value: '150204',
              name: '青山区'
            },
            {
              value: '150205',
              name: '石拐区'
            },
            {
              value: '150206',
              name: '白云鄂博矿区'
            },
            {
              value: '150207',
              name: '九原区'
            },
            {
              value: '150221',
              name: '土默特右旗'
            },
            {
              value: '150222',
              name: '固阳县'
            },
            {
              value: '150223',
              name: '达尔罕茂明安联合旗'
            },
            {
              value: '150271',
              name: '包头稀土高新技术产业开发区'
            }
          ]
        },
        {
          value: '150300',
          name: '乌海市',
          children: [
            {
              value: '150302',
              name: '海勃湾区'
            },
            {
              value: '150303',
              name: '海南区'
            },
            {
              value: '150304',
              name: '乌达区'
            }
          ]
        },
        {
          value: '150400',
          name: '赤峰市',
          children: [
            {
              value: '150402',
              name: '红山区'
            },
            {
              value: '150403',
              name: '元宝山区'
            },
            {
              value: '150404',
              name: '松山区'
            },
            {
              value: '150421',
              name: '阿鲁科尔沁旗'
            },
            {
              value: '150422',
              name: '巴林左旗'
            },
            {
              value: '150423',
              name: '巴林右旗'
            },
            {
              value: '150424',
              name: '林西县'
            },
            {
              value: '150425',
              name: '克什克腾旗'
            },
            {
              value: '150426',
              name: '翁牛特旗'
            },
            {
              value: '150428',
              name: '喀喇沁旗'
            },
            {
              value: '150429',
              name: '宁城县'
            },
            {
              value: '150430',
              name: '敖汉旗'
            }
          ]
        },
        {
          value: '150500',
          name: '通辽市',
          children: [
            {
              value: '150502',
              name: '科尔沁区'
            },
            {
              value: '150521',
              name: '科尔沁左翼中旗'
            },
            {
              value: '150522',
              name: '科尔沁左翼后旗'
            },
            {
              value: '150523',
              name: '开鲁县'
            },
            {
              value: '150524',
              name: '库伦旗'
            },
            {
              value: '150525',
              name: '奈曼旗'
            },
            {
              value: '150526',
              name: '扎鲁特旗'
            },
            {
              value: '150571',
              name: '通辽经济技术开发区'
            },
            {
              value: '150581',
              name: '霍林郭勒市'
            }
          ]
        },
        {
          value: '150600',
          name: '鄂尔多斯市',
          children: [
            {
              value: '150602',
              name: '东胜区'
            },
            {
              value: '150603',
              name: '康巴什区'
            },
            {
              value: '150621',
              name: '达拉特旗'
            },
            {
              value: '150622',
              name: '准格尔旗'
            },
            {
              value: '150623',
              name: '鄂托克前旗'
            },
            {
              value: '150624',
              name: '鄂托克旗'
            },
            {
              value: '150625',
              name: '杭锦旗'
            },
            {
              value: '150626',
              name: '乌审旗'
            },
            {
              value: '150627',
              name: '伊金霍洛旗'
            }
          ]
        },
        {
          value: '150700',
          name: '呼伦贝尔市',
          children: [
            {
              value: '150702',
              name: '海拉尔区'
            },
            {
              value: '150703',
              name: '扎赉诺尔区'
            },
            {
              value: '150721',
              name: '阿荣旗'
            },
            {
              value: '150722',
              name: '莫力达瓦达斡尔族自治旗'
            },
            {
              value: '150723',
              name: '鄂伦春自治旗'
            },
            {
              value: '150724',
              name: '鄂温克族自治旗'
            },
            {
              value: '150725',
              name: '陈巴尔虎旗'
            },
            {
              value: '150726',
              name: '新巴尔虎左旗'
            },
            {
              value: '150727',
              name: '新巴尔虎右旗'
            },
            {
              value: '150781',
              name: '满洲里市'
            },
            {
              value: '150782',
              name: '牙克石市'
            },
            {
              value: '150783',
              name: '扎兰屯市'
            },
            {
              value: '150784',
              name: '额尔古纳市'
            },
            {
              value: '150785',
              name: '根河市'
            }
          ]
        },
        {
          value: '150800',
          name: '巴彦淖尔市',
          children: [
            {
              value: '150802',
              name: '临河区'
            },
            {
              value: '150821',
              name: '五原县'
            },
            {
              value: '150822',
              name: '磴口县'
            },
            {
              value: '150823',
              name: '乌拉特前旗'
            },
            {
              value: '150824',
              name: '乌拉特中旗'
            },
            {
              value: '150825',
              name: '乌拉特后旗'
            },
            {
              value: '150826',
              name: '杭锦后旗'
            }
          ]
        },
        {
          value: '150900',
          name: '乌兰察布市',
          children: [
            {
              value: '150902',
              name: '集宁区'
            },
            {
              value: '150921',
              name: '卓资县'
            },
            {
              value: '150922',
              name: '化德县'
            },
            {
              value: '150923',
              name: '商都县'
            },
            {
              value: '150924',
              name: '兴和县'
            },
            {
              value: '150925',
              name: '凉城县'
            },
            {
              value: '150926',
              name: '察哈尔右翼前旗'
            },
            {
              value: '150927',
              name: '察哈尔右翼中旗'
            },
            {
              value: '150928',
              name: '察哈尔右翼后旗'
            },
            {
              value: '150929',
              name: '四子王旗'
            },
            {
              value: '150981',
              name: '丰镇市'
            }
          ]
        },
        {
          value: '152200',
          name: '兴安盟',
          children: [
            {
              value: '152201',
              name: '乌兰浩特市'
            },
            {
              value: '152202',
              name: '阿尔山市'
            },
            {
              value: '152221',
              name: '科尔沁右翼前旗'
            },
            {
              value: '152222',
              name: '科尔沁右翼中旗'
            },
            {
              value: '152223',
              name: '扎赉特旗'
            },
            {
              value: '152224',
              name: '突泉县'
            }
          ]
        },
        {
          value: '152500',
          name: '锡林郭勒盟',
          children: [
            {
              value: '152501',
              name: '二连浩特市'
            },
            {
              value: '152502',
              name: '锡林浩特市'
            },
            {
              value: '152522',
              name: '阿巴嘎旗'
            },
            {
              value: '152523',
              name: '苏尼特左旗'
            },
            {
              value: '152524',
              name: '苏尼特右旗'
            },
            {
              value: '152525',
              name: '东乌珠穆沁旗'
            },
            {
              value: '152526',
              name: '西乌珠穆沁旗'
            },
            {
              value: '152527',
              name: '太仆寺旗'
            },
            {
              value: '152528',
              name: '镶黄旗'
            },
            {
              value: '152529',
              name: '正镶白旗'
            },
            {
              value: '152530',
              name: '正蓝旗'
            },
            {
              value: '152531',
              name: '多伦县'
            },
            {
              value: '152571',
              name: '乌拉盖管委会'
            }
          ]
        },
        {
          value: '152900',
          name: '阿拉善盟',
          children: [
            {
              value: '152921',
              name: '阿拉善左旗'
            },
            {
              value: '152922',
              name: '阿拉善右旗'
            },
            {
              value: '152923',
              name: '额济纳旗'
            },
            {
              value: '152971',
              name: '内蒙古阿拉善经济开发区'
            }
          ]
        }
      ]
    },
    {
      value: '210000',
      name: '辽宁省',
      children: [
        {
          value: '210100',
          name: '沈阳市',
          children: [
            {
              value: '210102',
              name: '和平区'
            },
            {
              value: '210103',
              name: '沈河区'
            },
            {
              value: '210104',
              name: '大东区'
            },
            {
              value: '210105',
              name: '皇姑区'
            },
            {
              value: '210106',
              name: '铁西区'
            },
            {
              value: '210111',
              name: '苏家屯区'
            },
            {
              value: '210112',
              name: '浑南区'
            },
            {
              value: '210113',
              name: '沈北新区'
            },
            {
              value: '210114',
              name: '于洪区'
            },
            {
              value: '210115',
              name: '辽中区'
            },
            {
              value: '210123',
              name: '康平县'
            },
            {
              value: '210124',
              name: '法库县'
            },
            {
              value: '210181',
              name: '新民市'
            }
          ]
        },
        {
          value: '210200',
          name: '大连市',
          children: [
            {
              value: '210202',
              name: '中山区'
            },
            {
              value: '210203',
              name: '西岗区'
            },
            {
              value: '210204',
              name: '沙河口区'
            },
            {
              value: '210211',
              name: '甘井子区'
            },
            {
              value: '210212',
              name: '旅顺口区'
            },
            {
              value: '210213',
              name: '金州区'
            },
            {
              value: '210214',
              name: '普兰店区'
            },
            {
              value: '210224',
              name: '长海县'
            },
            {
              value: '210281',
              name: '瓦房店市'
            },
            {
              value: '210283',
              name: '庄河市'
            }
          ]
        },
        {
          value: '210300',
          name: '鞍山市',
          children: [
            {
              value: '210302',
              name: '铁东区'
            },
            {
              value: '210303',
              name: '铁西区'
            },
            {
              value: '210304',
              name: '立山区'
            },
            {
              value: '210311',
              name: '千山区'
            },
            {
              value: '210321',
              name: '台安县'
            },
            {
              value: '210323',
              name: '岫岩满族自治县'
            },
            {
              value: '210381',
              name: '海城市'
            }
          ]
        },
        {
          value: '210400',
          name: '抚顺市',
          children: [
            {
              value: '210402',
              name: '新抚区'
            },
            {
              value: '210403',
              name: '东洲区'
            },
            {
              value: '210404',
              name: '望花区'
            },
            {
              value: '210411',
              name: '顺城区'
            },
            {
              value: '210421',
              name: '抚顺县'
            },
            {
              value: '210422',
              name: '新宾满族自治县'
            },
            {
              value: '210423',
              name: '清原满族自治县'
            }
          ]
        },
        {
          value: '210500',
          name: '本溪市',
          children: [
            {
              value: '210502',
              name: '平山区'
            },
            {
              value: '210503',
              name: '溪湖区'
            },
            {
              value: '210504',
              name: '明山区'
            },
            {
              value: '210505',
              name: '南芬区'
            },
            {
              value: '210521',
              name: '本溪满族自治县'
            },
            {
              value: '210522',
              name: '桓仁满族自治县'
            }
          ]
        },
        {
          value: '210600',
          name: '丹东市',
          children: [
            {
              value: '210602',
              name: '元宝区'
            },
            {
              value: '210603',
              name: '振兴区'
            },
            {
              value: '210604',
              name: '振安区'
            },
            {
              value: '210624',
              name: '宽甸满族自治县'
            },
            {
              value: '210681',
              name: '东港市'
            },
            {
              value: '210682',
              name: '凤城市'
            }
          ]
        },
        {
          value: '210700',
          name: '锦州市',
          children: [
            {
              value: '210702',
              name: '古塔区'
            },
            {
              value: '210703',
              name: '凌河区'
            },
            {
              value: '210711',
              name: '太和区'
            },
            {
              value: '210726',
              name: '黑山县'
            },
            {
              value: '210727',
              name: '义县'
            },
            {
              value: '210781',
              name: '凌海市'
            },
            {
              value: '210782',
              name: '北镇市'
            }
          ]
        },
        {
          value: '210800',
          name: '营口市',
          children: [
            {
              value: '210802',
              name: '站前区'
            },
            {
              value: '210803',
              name: '西市区'
            },
            {
              value: '210804',
              name: '鲅鱼圈区'
            },
            {
              value: '210811',
              name: '老边区'
            },
            {
              value: '210881',
              name: '盖州市'
            },
            {
              value: '210882',
              name: '大石桥市'
            }
          ]
        },
        {
          value: '210900',
          name: '阜新市',
          children: [
            {
              value: '210902',
              name: '海州区'
            },
            {
              value: '210903',
              name: '新邱区'
            },
            {
              value: '210904',
              name: '太平区'
            },
            {
              value: '210905',
              name: '清河门区'
            },
            {
              value: '210911',
              name: '细河区'
            },
            {
              value: '210921',
              name: '阜新蒙古族自治县'
            },
            {
              value: '210922',
              name: '彰武县'
            }
          ]
        },
        {
          value: '211000',
          name: '辽阳市',
          children: [
            {
              value: '211002',
              name: '白塔区'
            },
            {
              value: '211003',
              name: '文圣区'
            },
            {
              value: '211004',
              name: '宏伟区'
            },
            {
              value: '211005',
              name: '弓长岭区'
            },
            {
              value: '211011',
              name: '太子河区'
            },
            {
              value: '211021',
              name: '辽阳县'
            },
            {
              value: '211081',
              name: '灯塔市'
            }
          ]
        },
        {
          value: '211100',
          name: '盘锦市',
          children: [
            {
              value: '211102',
              name: '双台子区'
            },
            {
              value: '211103',
              name: '兴隆台区'
            },
            {
              value: '211104',
              name: '大洼区'
            },
            {
              value: '211122',
              name: '盘山县'
            }
          ]
        },
        {
          value: '211200',
          name: '铁岭市',
          children: [
            {
              value: '211202',
              name: '银州区'
            },
            {
              value: '211204',
              name: '清河区'
            },
            {
              value: '211221',
              name: '铁岭县'
            },
            {
              value: '211223',
              name: '西丰县'
            },
            {
              value: '211224',
              name: '昌图县'
            },
            {
              value: '211281',
              name: '调兵山市'
            },
            {
              value: '211282',
              name: '开原市'
            }
          ]
        },
        {
          value: '211300',
          name: '朝阳市',
          children: [
            {
              value: '211302',
              name: '双塔区'
            },
            {
              value: '211303',
              name: '龙城区'
            },
            {
              value: '211321',
              name: '朝阳县'
            },
            {
              value: '211322',
              name: '建平县'
            },
            {
              value: '211324',
              name: '喀喇沁左翼蒙古族自治县'
            },
            {
              value: '211381',
              name: '北票市'
            },
            {
              value: '211382',
              name: '凌源市'
            }
          ]
        },
        {
          value: '211400',
          name: '葫芦岛市',
          children: [
            {
              value: '211402',
              name: '连山区'
            },
            {
              value: '211403',
              name: '龙港区'
            },
            {
              value: '211404',
              name: '南票区'
            },
            {
              value: '211421',
              name: '绥中县'
            },
            {
              value: '211422',
              name: '建昌县'
            },
            {
              value: '211481',
              name: '兴城市'
            }
          ]
        }
      ]
    },
    {
      value: '220000',
      name: '吉林省',
      children: [
        {
          value: '220100',
          name: '长春市',
          children: [
            {
              value: '220102',
              name: '南关区'
            },
            {
              value: '220103',
              name: '宽城区'
            },
            {
              value: '220104',
              name: '朝阳区'
            },
            {
              value: '220105',
              name: '二道区'
            },
            {
              value: '220106',
              name: '绿园区'
            },
            {
              value: '220112',
              name: '双阳区'
            },
            {
              value: '220113',
              name: '九台区'
            },
            {
              value: '220122',
              name: '农安县'
            },
            {
              value: '220171',
              name: '长春经济技术开发区'
            },
            {
              value: '220172',
              name: '长春净月高新技术产业开发区'
            },
            {
              value: '220173',
              name: '长春高新技术产业开发区'
            },
            {
              value: '220174',
              name: '长春汽车经济技术开发区'
            },
            {
              value: '220182',
              name: '榆树市'
            },
            {
              value: '220183',
              name: '德惠市'
            }
          ]
        },
        {
          value: '220200',
          name: '吉林市',
          children: [
            {
              value: '220202',
              name: '昌邑区'
            },
            {
              value: '220203',
              name: '龙潭区'
            },
            {
              value: '220204',
              name: '船营区'
            },
            {
              value: '220211',
              name: '丰满区'
            },
            {
              value: '220221',
              name: '永吉县'
            },
            {
              value: '220271',
              name: '吉林经济开发区'
            },
            {
              value: '220272',
              name: '吉林高新技术产业开发区'
            },
            {
              value: '220273',
              name: '吉林中国新加坡食品区'
            },
            {
              value: '220281',
              name: '蛟河市'
            },
            {
              value: '220282',
              name: '桦甸市'
            },
            {
              value: '220283',
              name: '舒兰市'
            },
            {
              value: '220284',
              name: '磐石市'
            }
          ]
        },
        {
          value: '220300',
          name: '四平市',
          children: [
            {
              value: '220302',
              name: '铁西区'
            },
            {
              value: '220303',
              name: '铁东区'
            },
            {
              value: '220322',
              name: '梨树县'
            },
            {
              value: '220323',
              name: '伊通满族自治县'
            },
            {
              value: '220381',
              name: '公主岭市'
            },
            {
              value: '220382',
              name: '双辽市'
            }
          ]
        },
        {
          value: '220400',
          name: '辽源市',
          children: [
            {
              value: '220402',
              name: '龙山区'
            },
            {
              value: '220403',
              name: '西安区'
            },
            {
              value: '220421',
              name: '东丰县'
            },
            {
              value: '220422',
              name: '东辽县'
            }
          ]
        },
        {
          value: '220500',
          name: '通化市',
          children: [
            {
              value: '220502',
              name: '东昌区'
            },
            {
              value: '220503',
              name: '二道江区'
            },
            {
              value: '220521',
              name: '通化县'
            },
            {
              value: '220523',
              name: '辉南县'
            },
            {
              value: '220524',
              name: '柳河县'
            },
            {
              value: '220581',
              name: '梅河口市'
            },
            {
              value: '220582',
              name: '集安市'
            }
          ]
        },
        {
          value: '220600',
          name: '白山市',
          children: [
            {
              value: '220602',
              name: '浑江区'
            },
            {
              value: '220605',
              name: '江源区'
            },
            {
              value: '220621',
              name: '抚松县'
            },
            {
              value: '220622',
              name: '靖宇县'
            },
            {
              value: '220623',
              name: '长白朝鲜族自治县'
            },
            {
              value: '220681',
              name: '临江市'
            }
          ]
        },
        {
          value: '220700',
          name: '松原市',
          children: [
            {
              value: '220702',
              name: '宁江区'
            },
            {
              value: '220721',
              name: '前郭尔罗斯蒙古族自治县'
            },
            {
              value: '220722',
              name: '长岭县'
            },
            {
              value: '220723',
              name: '乾安县'
            },
            {
              value: '220771',
              name: '吉林松原经济开发区'
            },
            {
              value: '220781',
              name: '扶余市'
            }
          ]
        },
        {
          value: '220800',
          name: '白城市',
          children: [
            {
              value: '220802',
              name: '洮北区'
            },
            {
              value: '220821',
              name: '镇赉县'
            },
            {
              value: '220822',
              name: '通榆县'
            },
            {
              value: '220871',
              name: '吉林白城经济开发区'
            },
            {
              value: '220881',
              name: '洮南市'
            },
            {
              value: '220882',
              name: '大安市'
            }
          ]
        },
        {
          value: '222400',
          name: '延边朝鲜族自治州',
          children: [
            {
              value: '222401',
              name: '延吉市'
            },
            {
              value: '222402',
              name: '图们市'
            },
            {
              value: '222403',
              name: '敦化市'
            },
            {
              value: '222404',
              name: '珲春市'
            },
            {
              value: '222405',
              name: '龙井市'
            },
            {
              value: '222406',
              name: '和龙市'
            },
            {
              value: '222424',
              name: '汪清县'
            },
            {
              value: '222426',
              name: '安图县'
            }
          ]
        }
      ]
    },
    {
      value: '230000',
      name: '黑龙江省',
      children: [
        {
          value: '230100',
          name: '哈尔滨市',
          children: [
            {
              value: '230102',
              name: '道里区'
            },
            {
              value: '230103',
              name: '南岗区'
            },
            {
              value: '230104',
              name: '道外区'
            },
            {
              value: '230108',
              name: '平房区'
            },
            {
              value: '230109',
              name: '松北区'
            },
            {
              value: '230110',
              name: '香坊区'
            },
            {
              value: '230111',
              name: '呼兰区'
            },
            {
              value: '230112',
              name: '阿城区'
            },
            {
              value: '230113',
              name: '双城区'
            },
            {
              value: '230123',
              name: '依兰县'
            },
            {
              value: '230124',
              name: '方正县'
            },
            {
              value: '230125',
              name: '宾县'
            },
            {
              value: '230126',
              name: '巴彦县'
            },
            {
              value: '230127',
              name: '木兰县'
            },
            {
              value: '230128',
              name: '通河县'
            },
            {
              value: '230129',
              name: '延寿县'
            },
            {
              value: '230183',
              name: '尚志市'
            },
            {
              value: '230184',
              name: '五常市'
            }
          ]
        },
        {
          value: '230200',
          name: '齐齐哈尔市',
          children: [
            {
              value: '230202',
              name: '龙沙区'
            },
            {
              value: '230203',
              name: '建华区'
            },
            {
              value: '230204',
              name: '铁锋区'
            },
            {
              value: '230205',
              name: '昂昂溪区'
            },
            {
              value: '230206',
              name: '富拉尔基区'
            },
            {
              value: '230207',
              name: '碾子山区'
            },
            {
              value: '230208',
              name: '梅里斯达斡尔族区'
            },
            {
              value: '230221',
              name: '龙江县'
            },
            {
              value: '230223',
              name: '依安县'
            },
            {
              value: '230224',
              name: '泰来县'
            },
            {
              value: '230225',
              name: '甘南县'
            },
            {
              value: '230227',
              name: '富裕县'
            },
            {
              value: '230229',
              name: '克山县'
            },
            {
              value: '230230',
              name: '克东县'
            },
            {
              value: '230231',
              name: '拜泉县'
            },
            {
              value: '230281',
              name: '讷河市'
            }
          ]
        },
        {
          value: '230300',
          name: '鸡西市',
          children: [
            {
              value: '230302',
              name: '鸡冠区'
            },
            {
              value: '230303',
              name: '恒山区'
            },
            {
              value: '230304',
              name: '滴道区'
            },
            {
              value: '230305',
              name: '梨树区'
            },
            {
              value: '230306',
              name: '城子河区'
            },
            {
              value: '230307',
              name: '麻山区'
            },
            {
              value: '230321',
              name: '鸡东县'
            },
            {
              value: '230381',
              name: '虎林市'
            },
            {
              value: '230382',
              name: '密山市'
            }
          ]
        },
        {
          value: '230400',
          name: '鹤岗市',
          children: [
            {
              value: '230402',
              name: '向阳区'
            },
            {
              value: '230403',
              name: '工农区'
            },
            {
              value: '230404',
              name: '南山区'
            },
            {
              value: '230405',
              name: '兴安区'
            },
            {
              value: '230406',
              name: '东山区'
            },
            {
              value: '230407',
              name: '兴山区'
            },
            {
              value: '230421',
              name: '萝北县'
            },
            {
              value: '230422',
              name: '绥滨县'
            }
          ]
        },
        {
          value: '230500',
          name: '双鸭山市',
          children: [
            {
              value: '230502',
              name: '尖山区'
            },
            {
              value: '230503',
              name: '岭东区'
            },
            {
              value: '230505',
              name: '四方台区'
            },
            {
              value: '230506',
              name: '宝山区'
            },
            {
              value: '230521',
              name: '集贤县'
            },
            {
              value: '230522',
              name: '友谊县'
            },
            {
              value: '230523',
              name: '宝清县'
            },
            {
              value: '230524',
              name: '饶河县'
            }
          ]
        },
        {
          value: '230600',
          name: '大庆市',
          children: [
            {
              value: '230602',
              name: '萨尔图区'
            },
            {
              value: '230603',
              name: '龙凤区'
            },
            {
              value: '230604',
              name: '让胡路区'
            },
            {
              value: '230605',
              name: '红岗区'
            },
            {
              value: '230606',
              name: '大同区'
            },
            {
              value: '230621',
              name: '肇州县'
            },
            {
              value: '230622',
              name: '肇源县'
            },
            {
              value: '230623',
              name: '林甸县'
            },
            {
              value: '230624',
              name: '杜尔伯特蒙古族自治县'
            },
            {
              value: '230671',
              name: '大庆高新技术产业开发区'
            }
          ]
        },
        {
          value: '230700',
          name: '伊春市',
          children: [
            {
              value: '230717',
              name: '伊美区'
            },
            {
              value: '230718',
              name: '乌翠区'
            },
            {
              value: '230719',
              name: '友好区'
            },
            {
              value: '230722',
              name: '嘉荫县'
            },
            {
              value: '230723',
              name: '汤旺县'
            },
            {
              value: '230724',
              name: '丰林县'
            },
            {
              value: '230725',
              name: '大箐山县'
            },
            {
              value: '230726',
              name: '南岔县'
            },
            {
              value: '230751',
              name: '金林区'
            },
            {
              value: '230781',
              name: '铁力市'
            }
          ]
        },
        {
          value: '230800',
          name: '佳木斯市',
          children: [
            {
              value: '230803',
              name: '向阳区'
            },
            {
              value: '230804',
              name: '前进区'
            },
            {
              value: '230805',
              name: '东风区'
            },
            {
              value: '230811',
              name: '郊区'
            },
            {
              value: '230822',
              name: '桦南县'
            },
            {
              value: '230826',
              name: '桦川县'
            },
            {
              value: '230828',
              name: '汤原县'
            },
            {
              value: '230881',
              name: '同江市'
            },
            {
              value: '230882',
              name: '富锦市'
            },
            {
              value: '230883',
              name: '抚远市'
            }
          ]
        },
        {
          value: '230900',
          name: '七台河市',
          children: [
            {
              value: '230902',
              name: '新兴区'
            },
            {
              value: '230903',
              name: '桃山区'
            },
            {
              value: '230904',
              name: '茄子河区'
            },
            {
              value: '230921',
              name: '勃利县'
            }
          ]
        },
        {
          value: '231000',
          name: '牡丹江市',
          children: [
            {
              value: '231002',
              name: '东安区'
            },
            {
              value: '231003',
              name: '阳明区'
            },
            {
              value: '231004',
              name: '爱民区'
            },
            {
              value: '231005',
              name: '西安区'
            },
            {
              value: '231025',
              name: '林口县'
            },
            {
              value: '231071',
              name: '牡丹江经济技术开发区'
            },
            {
              value: '231081',
              name: '绥芬河市'
            },
            {
              value: '231083',
              name: '海林市'
            },
            {
              value: '231084',
              name: '宁安市'
            },
            {
              value: '231085',
              name: '穆棱市'
            },
            {
              value: '231086',
              name: '东宁市'
            }
          ]
        },
        {
          value: '231100',
          name: '黑河市',
          children: [
            {
              value: '231102',
              name: '爱辉区'
            },
            {
              value: '231123',
              name: '逊克县'
            },
            {
              value: '231124',
              name: '孙吴县'
            },
            {
              value: '231181',
              name: '北安市'
            },
            {
              value: '231182',
              name: '五大连池市'
            },
            {
              value: '231183',
              name: '嫩江市'
            }
          ]
        },
        {
          value: '231200',
          name: '绥化市',
          children: [
            {
              value: '231202',
              name: '北林区'
            },
            {
              value: '231221',
              name: '望奎县'
            },
            {
              value: '231222',
              name: '兰西县'
            },
            {
              value: '231223',
              name: '青冈县'
            },
            {
              value: '231224',
              name: '庆安县'
            },
            {
              value: '231225',
              name: '明水县'
            },
            {
              value: '231226',
              name: '绥棱县'
            },
            {
              value: '231281',
              name: '安达市'
            },
            {
              value: '231282',
              name: '肇东市'
            },
            {
              value: '231283',
              name: '海伦市'
            }
          ]
        },
        {
          value: '232700',
          name: '大兴安岭地区',
          children: [
            {
              value: '232701',
              name: '漠河市'
            },
            {
              value: '232721',
              name: '呼玛县'
            },
            {
              value: '232722',
              name: '塔河县'
            },
            {
              value: '232761',
              name: '加格达奇区'
            },
            {
              value: '232762',
              name: '松岭区'
            },
            {
              value: '232763',
              name: '新林区'
            },
            {
              value: '232764',
              name: '呼中区'
            }
          ]
        }
      ]
    },
    {
      value: '310000',
      name: '上海市',
      children: [
        {
          value: '310100',
          name: '市辖区',
          children: [
            {
              value: '310101',
              name: '黄浦区'
            },
            {
              value: '310104',
              name: '徐汇区'
            },
            {
              value: '310105',
              name: '长宁区'
            },
            {
              value: '310106',
              name: '静安区'
            },
            {
              value: '310107',
              name: '普陀区'
            },
            {
              value: '310109',
              name: '虹口区'
            },
            {
              value: '310110',
              name: '杨浦区'
            },
            {
              value: '310112',
              name: '闵行区'
            },
            {
              value: '310113',
              name: '宝山区'
            },
            {
              value: '310114',
              name: '嘉定区'
            },
            {
              value: '310115',
              name: '浦东新区'
            },
            {
              value: '310116',
              name: '金山区'
            },
            {
              value: '310117',
              name: '松江区'
            },
            {
              value: '310118',
              name: '青浦区'
            },
            {
              value: '310120',
              name: '奉贤区'
            },
            {
              value: '310151',
              name: '崇明区'
            }
          ]
        }
      ]
    },
    {
      value: '320000',
      name: '江苏省',
      children: [
        {
          value: '320100',
          name: '南京市',
          children: [
            {
              value: '320102',
              name: '玄武区'
            },
            {
              value: '320104',
              name: '秦淮区'
            },
            {
              value: '320105',
              name: '建邺区'
            },
            {
              value: '320106',
              name: '鼓楼区'
            },
            {
              value: '320111',
              name: '浦口区'
            },
            {
              value: '320113',
              name: '栖霞区'
            },
            {
              value: '320114',
              name: '雨花台区'
            },
            {
              value: '320115',
              name: '江宁区'
            },
            {
              value: '320116',
              name: '六合区'
            },
            {
              value: '320117',
              name: '溧水区'
            },
            {
              value: '320118',
              name: '高淳区'
            }
          ]
        },
        {
          value: '320200',
          name: '无锡市',
          children: [
            {
              value: '320205',
              name: '锡山区'
            },
            {
              value: '320206',
              name: '惠山区'
            },
            {
              value: '320211',
              name: '滨湖区'
            },
            {
              value: '320213',
              name: '梁溪区'
            },
            {
              value: '320214',
              name: '新吴区'
            },
            {
              value: '320281',
              name: '江阴市'
            },
            {
              value: '320282',
              name: '宜兴市'
            }
          ]
        },
        {
          value: '320300',
          name: '徐州市',
          children: [
            {
              value: '320302',
              name: '鼓楼区'
            },
            {
              value: '320303',
              name: '云龙区'
            },
            {
              value: '320305',
              name: '贾汪区'
            },
            {
              value: '320311',
              name: '泉山区'
            },
            {
              value: '320312',
              name: '铜山区'
            },
            {
              value: '320321',
              name: '丰县'
            },
            {
              value: '320322',
              name: '沛县'
            },
            {
              value: '320324',
              name: '睢宁县'
            },
            {
              value: '320371',
              name: '徐州经济技术开发区'
            },
            {
              value: '320381',
              name: '新沂市'
            },
            {
              value: '320382',
              name: '邳州市'
            }
          ]
        },
        {
          value: '320400',
          name: '常州市',
          children: [
            {
              value: '320402',
              name: '天宁区'
            },
            {
              value: '320404',
              name: '钟楼区'
            },
            {
              value: '320411',
              name: '新北区'
            },
            {
              value: '320412',
              name: '武进区'
            },
            {
              value: '320413',
              name: '金坛区'
            },
            {
              value: '320481',
              name: '溧阳市'
            }
          ]
        },
        {
          value: '320500',
          name: '苏州市',
          children: [
            {
              value: '320505',
              name: '虎丘区'
            },
            {
              value: '320506',
              name: '吴中区'
            },
            {
              value: '320507',
              name: '相城区'
            },
            {
              value: '320508',
              name: '姑苏区'
            },
            {
              value: '320509',
              name: '吴江区'
            },
            {
              value: '320571',
              name: '苏州工业园区'
            },
            {
              value: '320581',
              name: '常熟市'
            },
            {
              value: '320582',
              name: '张家港市'
            },
            {
              value: '320583',
              name: '昆山市'
            },
            {
              value: '320585',
              name: '太仓市'
            }
          ]
        },
        {
          value: '320600',
          name: '南通市',
          children: [
            {
              value: '320602',
              name: '崇川区'
            },
            {
              value: '320611',
              name: '港闸区'
            },
            {
              value: '320612',
              name: '通州区'
            },
            {
              value: '320623',
              name: '如东县'
            },
            {
              value: '320671',
              name: '南通经济技术开发区'
            },
            {
              value: '320681',
              name: '启东市'
            },
            {
              value: '320682',
              name: '如皋市'
            },
            {
              value: '320684',
              name: '海门市'
            },
            {
              value: '320685',
              name: '海安市'
            }
          ]
        },
        {
          value: '320700',
          name: '连云港市',
          children: [
            {
              value: '320703',
              name: '连云区'
            },
            {
              value: '320706',
              name: '海州区'
            },
            {
              value: '320707',
              name: '赣榆区'
            },
            {
              value: '320722',
              name: '东海县'
            },
            {
              value: '320723',
              name: '灌云县'
            },
            {
              value: '320724',
              name: '灌南县'
            },
            {
              value: '320771',
              name: '连云港经济技术开发区'
            },
            {
              value: '320772',
              name: '连云港高新技术产业开发区'
            }
          ]
        },
        {
          value: '320800',
          name: '淮安市',
          children: [
            {
              value: '320803',
              name: '淮安区'
            },
            {
              value: '320804',
              name: '淮阴区'
            },
            {
              value: '320812',
              name: '清江浦区'
            },
            {
              value: '320813',
              name: '洪泽区'
            },
            {
              value: '320826',
              name: '涟水县'
            },
            {
              value: '320830',
              name: '盱眙县'
            },
            {
              value: '320831',
              name: '金湖县'
            },
            {
              value: '320871',
              name: '淮安经济技术开发区'
            }
          ]
        },
        {
          value: '320900',
          name: '盐城市',
          children: [
            {
              value: '320902',
              name: '亭湖区'
            },
            {
              value: '320903',
              name: '盐都区'
            },
            {
              value: '320904',
              name: '大丰区'
            },
            {
              value: '320921',
              name: '响水县'
            },
            {
              value: '320922',
              name: '滨海县'
            },
            {
              value: '320923',
              name: '阜宁县'
            },
            {
              value: '320924',
              name: '射阳县'
            },
            {
              value: '320925',
              name: '建湖县'
            },
            {
              value: '320971',
              name: '盐城经济技术开发区'
            },
            {
              value: '320981',
              name: '东台市'
            }
          ]
        },
        {
          value: '321000',
          name: '扬州市',
          children: [
            {
              value: '321002',
              name: '广陵区'
            },
            {
              value: '321003',
              name: '邗江区'
            },
            {
              value: '321012',
              name: '江都区'
            },
            {
              value: '321023',
              name: '宝应县'
            },
            {
              value: '321071',
              name: '扬州经济技术开发区'
            },
            {
              value: '321081',
              name: '仪征市'
            },
            {
              value: '321084',
              name: '高邮市'
            }
          ]
        },
        {
          value: '321100',
          name: '镇江市',
          children: [
            {
              value: '321102',
              name: '京口区'
            },
            {
              value: '321111',
              name: '润州区'
            },
            {
              value: '321112',
              name: '丹徒区'
            },
            {
              value: '321171',
              name: '镇江新区'
            },
            {
              value: '321181',
              name: '丹阳市'
            },
            {
              value: '321182',
              name: '扬中市'
            },
            {
              value: '321183',
              name: '句容市'
            }
          ]
        },
        {
          value: '321200',
          name: '泰州市',
          children: [
            {
              value: '321202',
              name: '海陵区'
            },
            {
              value: '321203',
              name: '高港区'
            },
            {
              value: '321204',
              name: '姜堰区'
            },
            {
              value: '321271',
              name: '泰州医药高新技术产业开发区'
            },
            {
              value: '321281',
              name: '兴化市'
            },
            {
              value: '321282',
              name: '靖江市'
            },
            {
              value: '321283',
              name: '泰兴市'
            }
          ]
        },
        {
          value: '321300',
          name: '宿迁市',
          children: [
            {
              value: '321302',
              name: '宿城区'
            },
            {
              value: '321311',
              name: '宿豫区'
            },
            {
              value: '321322',
              name: '沭阳县'
            },
            {
              value: '321323',
              name: '泗阳县'
            },
            {
              value: '321324',
              name: '泗洪县'
            },
            {
              value: '321371',
              name: '宿迁经济技术开发区'
            }
          ]
        }
      ]
    },
    {
      value: '330000',
      name: '浙江省',
      children: [
        {
          value: '330100',
          name: '杭州市',
          children: [
            {
              value: '330102',
              name: '上城区'
            },
            {
              value: '330103',
              name: '下城区'
            },
            {
              value: '330104',
              name: '江干区'
            },
            {
              value: '330105',
              name: '拱墅区'
            },
            {
              value: '330106',
              name: '西湖区'
            },
            {
              value: '330108',
              name: '滨江区'
            },
            {
              value: '330109',
              name: '萧山区'
            },
            {
              value: '330110',
              name: '余杭区'
            },
            {
              value: '330111',
              name: '富阳区'
            },
            {
              value: '330112',
              name: '临安区'
            },
            {
              value: '330122',
              name: '桐庐县'
            },
            {
              value: '330127',
              name: '淳安县'
            },
            {
              value: '330182',
              name: '建德市'
            }
          ]
        },
        {
          value: '330200',
          name: '宁波市',
          children: [
            {
              value: '330203',
              name: '海曙区'
            },
            {
              value: '330205',
              name: '江北区'
            },
            {
              value: '330206',
              name: '北仑区'
            },
            {
              value: '330211',
              name: '镇海区'
            },
            {
              value: '330212',
              name: '鄞州区'
            },
            {
              value: '330213',
              name: '奉化区'
            },
            {
              value: '330225',
              name: '象山县'
            },
            {
              value: '330226',
              name: '宁海县'
            },
            {
              value: '330281',
              name: '余姚市'
            },
            {
              value: '330282',
              name: '慈溪市'
            }
          ]
        },
        {
          value: '330300',
          name: '温州市',
          children: [
            {
              value: '330302',
              name: '鹿城区'
            },
            {
              value: '330303',
              name: '龙湾区'
            },
            {
              value: '330304',
              name: '瓯海区'
            },
            {
              value: '330305',
              name: '洞头区'
            },
            {
              value: '330324',
              name: '永嘉县'
            },
            {
              value: '330326',
              name: '平阳县'
            },
            {
              value: '330327',
              name: '苍南县'
            },
            {
              value: '330328',
              name: '文成县'
            },
            {
              value: '330329',
              name: '泰顺县'
            },
            {
              value: '330371',
              name: '温州经济技术开发区'
            },
            {
              value: '330381',
              name: '瑞安市'
            },
            {
              value: '330382',
              name: '乐清市'
            },
            {
              value: '330383',
              name: '龙港市'
            }
          ]
        },
        {
          value: '330400',
          name: '嘉兴市',
          children: [
            {
              value: '330402',
              name: '南湖区'
            },
            {
              value: '330411',
              name: '秀洲区'
            },
            {
              value: '330421',
              name: '嘉善县'
            },
            {
              value: '330424',
              name: '海盐县'
            },
            {
              value: '330481',
              name: '海宁市'
            },
            {
              value: '330482',
              name: '平湖市'
            },
            {
              value: '330483',
              name: '桐乡市'
            }
          ]
        },
        {
          value: '330500',
          name: '湖州市',
          children: [
            {
              value: '330502',
              name: '吴兴区'
            },
            {
              value: '330503',
              name: '南浔区'
            },
            {
              value: '330521',
              name: '德清县'
            },
            {
              value: '330522',
              name: '长兴县'
            },
            {
              value: '330523',
              name: '安吉县'
            }
          ]
        },
        {
          value: '330600',
          name: '绍兴市',
          children: [
            {
              value: '330602',
              name: '越城区'
            },
            {
              value: '330603',
              name: '柯桥区'
            },
            {
              value: '330604',
              name: '上虞区'
            },
            {
              value: '330624',
              name: '新昌县'
            },
            {
              value: '330681',
              name: '诸暨市'
            },
            {
              value: '330683',
              name: '嵊州市'
            }
          ]
        },
        {
          value: '330700',
          name: '金华市',
          children: [
            {
              value: '330702',
              name: '婺城区'
            },
            {
              value: '330703',
              name: '金东区'
            },
            {
              value: '330723',
              name: '武义县'
            },
            {
              value: '330726',
              name: '浦江县'
            },
            {
              value: '330727',
              name: '磐安县'
            },
            {
              value: '330781',
              name: '兰溪市'
            },
            {
              value: '330782',
              name: '义乌市'
            },
            {
              value: '330783',
              name: '东阳市'
            },
            {
              value: '330784',
              name: '永康市'
            }
          ]
        },
        {
          value: '330800',
          name: '衢州市',
          children: [
            {
              value: '330802',
              name: '柯城区'
            },
            {
              value: '330803',
              name: '衢江区'
            },
            {
              value: '330822',
              name: '常山县'
            },
            {
              value: '330824',
              name: '开化县'
            },
            {
              value: '330825',
              name: '龙游县'
            },
            {
              value: '330881',
              name: '江山市'
            }
          ]
        },
        {
          value: '330900',
          name: '舟山市',
          children: [
            {
              value: '330902',
              name: '定海区'
            },
            {
              value: '330903',
              name: '普陀区'
            },
            {
              value: '330921',
              name: '岱山县'
            },
            {
              value: '330922',
              name: '嵊泗县'
            }
          ]
        },
        {
          value: '331000',
          name: '台州市',
          children: [
            {
              value: '331002',
              name: '椒江区'
            },
            {
              value: '331003',
              name: '黄岩区'
            },
            {
              value: '331004',
              name: '路桥区'
            },
            {
              value: '331022',
              name: '三门县'
            },
            {
              value: '331023',
              name: '天台县'
            },
            {
              value: '331024',
              name: '仙居县'
            },
            {
              value: '331081',
              name: '温岭市'
            },
            {
              value: '331082',
              name: '临海市'
            },
            {
              value: '331083',
              name: '玉环市'
            }
          ]
        },
        {
          value: '331100',
          name: '丽水市',
          children: [
            {
              value: '331102',
              name: '莲都区'
            },
            {
              value: '331121',
              name: '青田县'
            },
            {
              value: '331122',
              name: '缙云县'
            },
            {
              value: '331123',
              name: '遂昌县'
            },
            {
              value: '331124',
              name: '松阳县'
            },
            {
              value: '331125',
              name: '云和县'
            },
            {
              value: '331126',
              name: '庆元县'
            },
            {
              value: '331127',
              name: '景宁畲族自治县'
            },
            {
              value: '331181',
              name: '龙泉市'
            }
          ]
        }
      ]
    },
    {
      value: '340000',
      name: '安徽省',
      children: [
        {
          value: '340100',
          name: '合肥市',
          children: [
            {
              value: '340102',
              name: '瑶海区'
            },
            {
              value: '340103',
              name: '庐阳区'
            },
            {
              value: '340104',
              name: '蜀山区'
            },
            {
              value: '340111',
              name: '包河区'
            },
            {
              value: '340121',
              name: '长丰县'
            },
            {
              value: '340122',
              name: '肥东县'
            },
            {
              value: '340123',
              name: '肥西县'
            },
            {
              value: '340124',
              name: '庐江县'
            },
            {
              value: '340171',
              name: '合肥高新技术产业开发区'
            },
            {
              value: '340172',
              name: '合肥经济技术开发区'
            },
            {
              value: '340173',
              name: '合肥新站高新技术产业开发区'
            },
            {
              value: '340181',
              name: '巢湖市'
            }
          ]
        },
        {
          value: '340200',
          name: '芜湖市',
          children: [
            {
              value: '340202',
              name: '镜湖区'
            },
            {
              value: '340203',
              name: '弋江区'
            },
            {
              value: '340207',
              name: '鸠江区'
            },
            {
              value: '340208',
              name: '三山区'
            },
            {
              value: '340221',
              name: '芜湖县'
            },
            {
              value: '340222',
              name: '繁昌县'
            },
            {
              value: '340223',
              name: '南陵县'
            },
            {
              value: '340225',
              name: '无为县'
            },
            {
              value: '340271',
              name: '芜湖经济技术开发区'
            },
            {
              value: '340272',
              name: '安徽芜湖长江大桥经济开发区'
            }
          ]
        },
        {
          value: '340300',
          name: '蚌埠市',
          children: [
            {
              value: '340302',
              name: '龙子湖区'
            },
            {
              value: '340303',
              name: '蚌山区'
            },
            {
              value: '340304',
              name: '禹会区'
            },
            {
              value: '340311',
              name: '淮上区'
            },
            {
              value: '340321',
              name: '怀远县'
            },
            {
              value: '340322',
              name: '五河县'
            },
            {
              value: '340323',
              name: '固镇县'
            },
            {
              value: '340371',
              name: '蚌埠市高新技术开发区'
            },
            {
              value: '340372',
              name: '蚌埠市经济开发区'
            }
          ]
        },
        {
          value: '340400',
          name: '淮南市',
          children: [
            {
              value: '340402',
              name: '大通区'
            },
            {
              value: '340403',
              name: '田家庵区'
            },
            {
              value: '340404',
              name: '谢家集区'
            },
            {
              value: '340405',
              name: '八公山区'
            },
            {
              value: '340406',
              name: '潘集区'
            },
            {
              value: '340421',
              name: '凤台县'
            },
            {
              value: '340422',
              name: '寿县'
            }
          ]
        },
        {
          value: '340500',
          name: '马鞍山市',
          children: [
            {
              value: '340503',
              name: '花山区'
            },
            {
              value: '340504',
              name: '雨山区'
            },
            {
              value: '340506',
              name: '博望区'
            },
            {
              value: '340521',
              name: '当涂县'
            },
            {
              value: '340522',
              name: '含山县'
            },
            {
              value: '340523',
              name: '和县'
            }
          ]
        },
        {
          value: '340600',
          name: '淮北市',
          children: [
            {
              value: '340602',
              name: '杜集区'
            },
            {
              value: '340603',
              name: '相山区'
            },
            {
              value: '340604',
              name: '烈山区'
            },
            {
              value: '340621',
              name: '濉溪县'
            }
          ]
        },
        {
          value: '340700',
          name: '铜陵市',
          children: [
            {
              value: '340705',
              name: '铜官区'
            },
            {
              value: '340706',
              name: '义安区'
            },
            {
              value: '340711',
              name: '郊区'
            },
            {
              value: '340722',
              name: '枞阳县'
            }
          ]
        },
        {
          value: '340800',
          name: '安庆市',
          children: [
            {
              value: '340802',
              name: '迎江区'
            },
            {
              value: '340803',
              name: '大观区'
            },
            {
              value: '340811',
              name: '宜秀区'
            },
            {
              value: '340822',
              name: '怀宁县'
            },
            {
              value: '340825',
              name: '太湖县'
            },
            {
              value: '340826',
              name: '宿松县'
            },
            {
              value: '340827',
              name: '望江县'
            },
            {
              value: '340828',
              name: '岳西县'
            },
            {
              value: '340871',
              name: '安徽安庆经济开发区'
            },
            {
              value: '340881',
              name: '桐城市'
            },
            {
              value: '340882',
              name: '潜山市'
            }
          ]
        },
        {
          value: '341000',
          name: '黄山市',
          children: [
            {
              value: '341002',
              name: '屯溪区'
            },
            {
              value: '341003',
              name: '黄山区'
            },
            {
              value: '341004',
              name: '徽州区'
            },
            {
              value: '341021',
              name: '歙县'
            },
            {
              value: '341022',
              name: '休宁县'
            },
            {
              value: '341023',
              name: '黟县'
            },
            {
              value: '341024',
              name: '祁门县'
            }
          ]
        },
        {
          value: '341100',
          name: '滁州市',
          children: [
            {
              value: '341102',
              name: '琅琊区'
            },
            {
              value: '341103',
              name: '南谯区'
            },
            {
              value: '341122',
              name: '来安县'
            },
            {
              value: '341124',
              name: '全椒县'
            },
            {
              value: '341125',
              name: '定远县'
            },
            {
              value: '341126',
              name: '凤阳县'
            },
            {
              value: '341171',
              name: '苏滁现代产业园'
            },
            {
              value: '341172',
              name: '滁州经济技术开发区'
            },
            {
              value: '341181',
              name: '天长市'
            },
            {
              value: '341182',
              name: '明光市'
            }
          ]
        },
        {
          value: '341200',
          name: '阜阳市',
          children: [
            {
              value: '341202',
              name: '颍州区'
            },
            {
              value: '341203',
              name: '颍东区'
            },
            {
              value: '341204',
              name: '颍泉区'
            },
            {
              value: '341221',
              name: '临泉县'
            },
            {
              value: '341222',
              name: '太和县'
            },
            {
              value: '341225',
              name: '阜南县'
            },
            {
              value: '341226',
              name: '颍上县'
            },
            {
              value: '341271',
              name: '阜阳合肥现代产业园区'
            },
            {
              value: '341272',
              name: '阜阳经济技术开发区'
            },
            {
              value: '341282',
              name: '界首市'
            }
          ]
        },
        {
          value: '341300',
          name: '宿州市',
          children: [
            {
              value: '341302',
              name: '埇桥区'
            },
            {
              value: '341321',
              name: '砀山县'
            },
            {
              value: '341322',
              name: '萧县'
            },
            {
              value: '341323',
              name: '灵璧县'
            },
            {
              value: '341324',
              name: '泗县'
            },
            {
              value: '341371',
              name: '宿州马鞍山现代产业园区'
            },
            {
              value: '341372',
              name: '宿州经济技术开发区'
            }
          ]
        },
        {
          value: '341500',
          name: '六安市',
          children: [
            {
              value: '341502',
              name: '金安区'
            },
            {
              value: '341503',
              name: '裕安区'
            },
            {
              value: '341504',
              name: '叶集区'
            },
            {
              value: '341522',
              name: '霍邱县'
            },
            {
              value: '341523',
              name: '舒城县'
            },
            {
              value: '341524',
              name: '金寨县'
            },
            {
              value: '341525',
              name: '霍山县'
            }
          ]
        },
        {
          value: '341600',
          name: '亳州市',
          children: [
            {
              value: '341602',
              name: '谯城区'
            },
            {
              value: '341621',
              name: '涡阳县'
            },
            {
              value: '341622',
              name: '蒙城县'
            },
            {
              value: '341623',
              name: '利辛县'
            }
          ]
        },
        {
          value: '341700',
          name: '池州市',
          children: [
            {
              value: '341702',
              name: '贵池区'
            },
            {
              value: '341721',
              name: '东至县'
            },
            {
              value: '341722',
              name: '石台县'
            },
            {
              value: '341723',
              name: '青阳县'
            }
          ]
        },
        {
          value: '341800',
          name: '宣城市',
          children: [
            {
              value: '341802',
              name: '宣州区'
            },
            {
              value: '341821',
              name: '郎溪县'
            },
            {
              value: '341823',
              name: '泾县'
            },
            {
              value: '341824',
              name: '绩溪县'
            },
            {
              value: '341825',
              name: '旌德县'
            },
            {
              value: '341871',
              name: '宣城市经济开发区'
            },
            {
              value: '341881',
              name: '宁国市'
            },
            {
              value: '341882',
              name: '广德市'
            }
          ]
        }
      ]
    },
    {
      value: '350000',
      name: '福建省',
      children: [
        {
          value: '350100',
          name: '福州市',
          children: [
            {
              value: '350102',
              name: '鼓楼区'
            },
            {
              value: '350103',
              name: '台江区'
            },
            {
              value: '350104',
              name: '仓山区'
            },
            {
              value: '350105',
              name: '马尾区'
            },
            {
              value: '350111',
              name: '晋安区'
            },
            {
              value: '350112',
              name: '长乐区'
            },
            {
              value: '350121',
              name: '闽侯县'
            },
            {
              value: '350122',
              name: '连江县'
            },
            {
              value: '350123',
              name: '罗源县'
            },
            {
              value: '350124',
              name: '闽清县'
            },
            {
              value: '350125',
              name: '永泰县'
            },
            {
              value: '350128',
              name: '平潭县'
            },
            {
              value: '350181',
              name: '福清市'
            }
          ]
        },
        {
          value: '350200',
          name: '厦门市',
          children: [
            {
              value: '350203',
              name: '思明区'
            },
            {
              value: '350205',
              name: '海沧区'
            },
            {
              value: '350206',
              name: '湖里区'
            },
            {
              value: '350211',
              name: '集美区'
            },
            {
              value: '350212',
              name: '同安区'
            },
            {
              value: '350213',
              name: '翔安区'
            }
          ]
        },
        {
          value: '350300',
          name: '莆田市',
          children: [
            {
              value: '350302',
              name: '城厢区'
            },
            {
              value: '350303',
              name: '涵江区'
            },
            {
              value: '350304',
              name: '荔城区'
            },
            {
              value: '350305',
              name: '秀屿区'
            },
            {
              value: '350322',
              name: '仙游县'
            }
          ]
        },
        {
          value: '350400',
          name: '三明市',
          children: [
            {
              value: '350402',
              name: '梅列区'
            },
            {
              value: '350403',
              name: '三元区'
            },
            {
              value: '350421',
              name: '明溪县'
            },
            {
              value: '350423',
              name: '清流县'
            },
            {
              value: '350424',
              name: '宁化县'
            },
            {
              value: '350425',
              name: '大田县'
            },
            {
              value: '350426',
              name: '尤溪县'
            },
            {
              value: '350427',
              name: '沙县'
            },
            {
              value: '350428',
              name: '将乐县'
            },
            {
              value: '350429',
              name: '泰宁县'
            },
            {
              value: '350430',
              name: '建宁县'
            },
            {
              value: '350481',
              name: '永安市'
            }
          ]
        },
        {
          value: '350500',
          name: '泉州市',
          children: [
            {
              value: '350502',
              name: '鲤城区'
            },
            {
              value: '350503',
              name: '丰泽区'
            },
            {
              value: '350504',
              name: '洛江区'
            },
            {
              value: '350505',
              name: '泉港区'
            },
            {
              value: '350521',
              name: '惠安县'
            },
            {
              value: '350524',
              name: '安溪县'
            },
            {
              value: '350525',
              name: '永春县'
            },
            {
              value: '350526',
              name: '德化县'
            },
            {
              value: '350527',
              name: '金门县'
            },
            {
              value: '350581',
              name: '石狮市'
            },
            {
              value: '350582',
              name: '晋江市'
            },
            {
              value: '350583',
              name: '南安市'
            }
          ]
        },
        {
          value: '350600',
          name: '漳州市',
          children: [
            {
              value: '350602',
              name: '芗城区'
            },
            {
              value: '350603',
              name: '龙文区'
            },
            {
              value: '350622',
              name: '云霄县'
            },
            {
              value: '350623',
              name: '漳浦县'
            },
            {
              value: '350624',
              name: '诏安县'
            },
            {
              value: '350625',
              name: '长泰县'
            },
            {
              value: '350626',
              name: '东山县'
            },
            {
              value: '350627',
              name: '南靖县'
            },
            {
              value: '350628',
              name: '平和县'
            },
            {
              value: '350629',
              name: '华安县'
            },
            {
              value: '350681',
              name: '龙海市'
            }
          ]
        },
        {
          value: '350700',
          name: '南平市',
          children: [
            {
              value: '350702',
              name: '延平区'
            },
            {
              value: '350703',
              name: '建阳区'
            },
            {
              value: '350721',
              name: '顺昌县'
            },
            {
              value: '350722',
              name: '浦城县'
            },
            {
              value: '350723',
              name: '光泽县'
            },
            {
              value: '350724',
              name: '松溪县'
            },
            {
              value: '350725',
              name: '政和县'
            },
            {
              value: '350781',
              name: '邵武市'
            },
            {
              value: '350782',
              name: '武夷山市'
            },
            {
              value: '350783',
              name: '建瓯市'
            }
          ]
        },
        {
          value: '350800',
          name: '龙岩市',
          children: [
            {
              value: '350802',
              name: '新罗区'
            },
            {
              value: '350803',
              name: '永定区'
            },
            {
              value: '350821',
              name: '长汀县'
            },
            {
              value: '350823',
              name: '上杭县'
            },
            {
              value: '350824',
              name: '武平县'
            },
            {
              value: '350825',
              name: '连城县'
            },
            {
              value: '350881',
              name: '漳平市'
            }
          ]
        },
        {
          value: '350900',
          name: '宁德市',
          children: [
            {
              value: '350902',
              name: '蕉城区'
            },
            {
              value: '350921',
              name: '霞浦县'
            },
            {
              value: '350922',
              name: '古田县'
            },
            {
              value: '350923',
              name: '屏南县'
            },
            {
              value: '350924',
              name: '寿宁县'
            },
            {
              value: '350925',
              name: '周宁县'
            },
            {
              value: '350926',
              name: '柘荣县'
            },
            {
              value: '350981',
              name: '福安市'
            },
            {
              value: '350982',
              name: '福鼎市'
            }
          ]
        }
      ]
    },
    {
      value: '360000',
      name: '江西省',
      children: [
        {
          value: '360100',
          name: '南昌市',
          children: [
            {
              value: '360102',
              name: '东湖区'
            },
            {
              value: '360103',
              name: '西湖区'
            },
            {
              value: '360104',
              name: '青云谱区'
            },
            {
              value: '360105',
              name: '湾里区'
            },
            {
              value: '360111',
              name: '青山湖区'
            },
            {
              value: '360112',
              name: '新建区'
            },
            {
              value: '360121',
              name: '南昌县'
            },
            {
              value: '360123',
              name: '安义县'
            },
            {
              value: '360124',
              name: '进贤县'
            }
          ]
        },
        {
          value: '360200',
          name: '景德镇市',
          children: [
            {
              value: '360202',
              name: '昌江区'
            },
            {
              value: '360203',
              name: '珠山区'
            },
            {
              value: '360222',
              name: '浮梁县'
            },
            {
              value: '360281',
              name: '乐平市'
            }
          ]
        },
        {
          value: '360300',
          name: '萍乡市',
          children: [
            {
              value: '360302',
              name: '安源区'
            },
            {
              value: '360313',
              name: '湘东区'
            },
            {
              value: '360321',
              name: '莲花县'
            },
            {
              value: '360322',
              name: '上栗县'
            },
            {
              value: '360323',
              name: '芦溪县'
            }
          ]
        },
        {
          value: '360400',
          name: '九江市',
          children: [
            {
              value: '360402',
              name: '濂溪区'
            },
            {
              value: '360403',
              name: '浔阳区'
            },
            {
              value: '360404',
              name: '柴桑区'
            },
            {
              value: '360423',
              name: '武宁县'
            },
            {
              value: '360424',
              name: '修水县'
            },
            {
              value: '360425',
              name: '永修县'
            },
            {
              value: '360426',
              name: '德安县'
            },
            {
              value: '360428',
              name: '都昌县'
            },
            {
              value: '360429',
              name: '湖口县'
            },
            {
              value: '360430',
              name: '彭泽县'
            },
            {
              value: '360481',
              name: '瑞昌市'
            },
            {
              value: '360482',
              name: '共青城市'
            },
            {
              value: '360483',
              name: '庐山市'
            }
          ]
        },
        {
          value: '360500',
          name: '新余市',
          children: [
            {
              value: '360502',
              name: '渝水区'
            },
            {
              value: '360521',
              name: '分宜县'
            }
          ]
        },
        {
          value: '360600',
          name: '鹰潭市',
          children: [
            {
              value: '360602',
              name: '月湖区'
            },
            {
              value: '360603',
              name: '余江区'
            },
            {
              value: '360681',
              name: '贵溪市'
            }
          ]
        },
        {
          value: '360700',
          name: '赣州市',
          children: [
            {
              value: '360702',
              name: '章贡区'
            },
            {
              value: '360703',
              name: '南康区'
            },
            {
              value: '360704',
              name: '赣县区'
            },
            {
              value: '360722',
              name: '信丰县'
            },
            {
              value: '360723',
              name: '大余县'
            },
            {
              value: '360724',
              name: '上犹县'
            },
            {
              value: '360725',
              name: '崇义县'
            },
            {
              value: '360726',
              name: '安远县'
            },
            {
              value: '360727',
              name: '龙南县'
            },
            {
              value: '360728',
              name: '定南县'
            },
            {
              value: '360729',
              name: '全南县'
            },
            {
              value: '360730',
              name: '宁都县'
            },
            {
              value: '360731',
              name: '于都县'
            },
            {
              value: '360732',
              name: '兴国县'
            },
            {
              value: '360733',
              name: '会昌县'
            },
            {
              value: '360734',
              name: '寻乌县'
            },
            {
              value: '360735',
              name: '石城县'
            },
            {
              value: '360781',
              name: '瑞金市'
            }
          ]
        },
        {
          value: '360800',
          name: '吉安市',
          children: [
            {
              value: '360802',
              name: '吉州区'
            },
            {
              value: '360803',
              name: '青原区'
            },
            {
              value: '360821',
              name: '吉安县'
            },
            {
              value: '360822',
              name: '吉水县'
            },
            {
              value: '360823',
              name: '峡江县'
            },
            {
              value: '360824',
              name: '新干县'
            },
            {
              value: '360825',
              name: '永丰县'
            },
            {
              value: '360826',
              name: '泰和县'
            },
            {
              value: '360827',
              name: '遂川县'
            },
            {
              value: '360828',
              name: '万安县'
            },
            {
              value: '360829',
              name: '安福县'
            },
            {
              value: '360830',
              name: '永新县'
            },
            {
              value: '360881',
              name: '井冈山市'
            }
          ]
        },
        {
          value: '360900',
          name: '宜春市',
          children: [
            {
              value: '360902',
              name: '袁州区'
            },
            {
              value: '360921',
              name: '奉新县'
            },
            {
              value: '360922',
              name: '万载县'
            },
            {
              value: '360923',
              name: '上高县'
            },
            {
              value: '360924',
              name: '宜丰县'
            },
            {
              value: '360925',
              name: '靖安县'
            },
            {
              value: '360926',
              name: '铜鼓县'
            },
            {
              value: '360981',
              name: '丰城市'
            },
            {
              value: '360982',
              name: '樟树市'
            },
            {
              value: '360983',
              name: '高安市'
            }
          ]
        },
        {
          value: '361000',
          name: '抚州市',
          children: [
            {
              value: '361002',
              name: '临川区'
            },
            {
              value: '361003',
              name: '东乡区'
            },
            {
              value: '361021',
              name: '南城县'
            },
            {
              value: '361022',
              name: '黎川县'
            },
            {
              value: '361023',
              name: '南丰县'
            },
            {
              value: '361024',
              name: '崇仁县'
            },
            {
              value: '361025',
              name: '乐安县'
            },
            {
              value: '361026',
              name: '宜黄县'
            },
            {
              value: '361027',
              name: '金溪县'
            },
            {
              value: '361028',
              name: '资溪县'
            },
            {
              value: '361030',
              name: '广昌县'
            }
          ]
        },
        {
          value: '361100',
          name: '上饶市',
          children: [
            {
              value: '361102',
              name: '信州区'
            },
            {
              value: '361103',
              name: '广丰区'
            },
            {
              value: '361104',
              name: '广信区'
            },
            {
              value: '361123',
              name: '玉山县'
            },
            {
              value: '361124',
              name: '铅山县'
            },
            {
              value: '361125',
              name: '横峰县'
            },
            {
              value: '361126',
              name: '弋阳县'
            },
            {
              value: '361127',
              name: '余干县'
            },
            {
              value: '361128',
              name: '鄱阳县'
            },
            {
              value: '361129',
              name: '万年县'
            },
            {
              value: '361130',
              name: '婺源县'
            },
            {
              value: '361181',
              name: '德兴市'
            }
          ]
        }
      ]
    },
    {
      value: '370000',
      name: '山东省',
      children: [
        {
          value: '370100',
          name: '济南市',
          children: [
            {
              value: '370102',
              name: '历下区'
            },
            {
              value: '370103',
              name: '市中区'
            },
            {
              value: '370104',
              name: '槐荫区'
            },
            {
              value: '370105',
              name: '天桥区'
            },
            {
              value: '370112',
              name: '历城区'
            },
            {
              value: '370113',
              name: '长清区'
            },
            {
              value: '370114',
              name: '章丘区'
            },
            {
              value: '370115',
              name: '济阳区'
            },
            {
              value: '370116',
              name: '莱芜区'
            },
            {
              value: '370117',
              name: '钢城区'
            },
            {
              value: '370124',
              name: '平阴县'
            },
            {
              value: '370126',
              name: '商河县'
            },
            {
              value: '370171',
              name: '济南高新技术产业开发区'
            }
          ]
        },
        {
          value: '370200',
          name: '青岛市',
          children: [
            {
              value: '370202',
              name: '市南区'
            },
            {
              value: '370203',
              name: '市北区'
            },
            {
              value: '370211',
              name: '黄岛区'
            },
            {
              value: '370212',
              name: '崂山区'
            },
            {
              value: '370213',
              name: '李沧区'
            },
            {
              value: '370214',
              name: '城阳区'
            },
            {
              value: '370215',
              name: '即墨区'
            },
            {
              value: '370271',
              name: '青岛高新技术产业开发区'
            },
            {
              value: '370281',
              name: '胶州市'
            },
            {
              value: '370283',
              name: '平度市'
            },
            {
              value: '370285',
              name: '莱西市'
            }
          ]
        },
        {
          value: '370300',
          name: '淄博市',
          children: [
            {
              value: '370302',
              name: '淄川区'
            },
            {
              value: '370303',
              name: '张店区'
            },
            {
              value: '370304',
              name: '博山区'
            },
            {
              value: '370305',
              name: '临淄区'
            },
            {
              value: '370306',
              name: '周村区'
            },
            {
              value: '370321',
              name: '桓台县'
            },
            {
              value: '370322',
              name: '高青县'
            },
            {
              value: '370323',
              name: '沂源县'
            }
          ]
        },
        {
          value: '370400',
          name: '枣庄市',
          children: [
            {
              value: '370402',
              name: '市中区'
            },
            {
              value: '370403',
              name: '薛城区'
            },
            {
              value: '370404',
              name: '峄城区'
            },
            {
              value: '370405',
              name: '台儿庄区'
            },
            {
              value: '370406',
              name: '山亭区'
            },
            {
              value: '370481',
              name: '滕州市'
            }
          ]
        },
        {
          value: '370500',
          name: '东营市',
          children: [
            {
              value: '370502',
              name: '东营区'
            },
            {
              value: '370503',
              name: '河口区'
            },
            {
              value: '370505',
              name: '垦利区'
            },
            {
              value: '370522',
              name: '利津县'
            },
            {
              value: '370523',
              name: '广饶县'
            },
            {
              value: '370571',
              name: '东营经济技术开发区'
            },
            {
              value: '370572',
              name: '东营港经济开发区'
            }
          ]
        },
        {
          value: '370600',
          name: '烟台市',
          children: [
            {
              value: '370602',
              name: '芝罘区'
            },
            {
              value: '370611',
              name: '福山区'
            },
            {
              value: '370612',
              name: '牟平区'
            },
            {
              value: '370613',
              name: '莱山区'
            },
            {
              value: '370634',
              name: '长岛县'
            },
            {
              value: '370671',
              name: '烟台高新技术产业开发区'
            },
            {
              value: '370672',
              name: '烟台经济技术开发区'
            },
            {
              value: '370681',
              name: '龙口市'
            },
            {
              value: '370682',
              name: '莱阳市'
            },
            {
              value: '370683',
              name: '莱州市'
            },
            {
              value: '370684',
              name: '蓬莱市'
            },
            {
              value: '370685',
              name: '招远市'
            },
            {
              value: '370686',
              name: '栖霞市'
            },
            {
              value: '370687',
              name: '海阳市'
            }
          ]
        },
        {
          value: '370700',
          name: '潍坊市',
          children: [
            {
              value: '370702',
              name: '潍城区'
            },
            {
              value: '370703',
              name: '寒亭区'
            },
            {
              value: '370704',
              name: '坊子区'
            },
            {
              value: '370705',
              name: '奎文区'
            },
            {
              value: '370724',
              name: '临朐县'
            },
            {
              value: '370725',
              name: '昌乐县'
            },
            {
              value: '370772',
              name: '潍坊滨海经济技术开发区'
            },
            {
              value: '370781',
              name: '青州市'
            },
            {
              value: '370782',
              name: '诸城市'
            },
            {
              value: '370783',
              name: '寿光市'
            },
            {
              value: '370784',
              name: '安丘市'
            },
            {
              value: '370785',
              name: '高密市'
            },
            {
              value: '370786',
              name: '昌邑市'
            }
          ]
        },
        {
          value: '370800',
          name: '济宁市',
          children: [
            {
              value: '370811',
              name: '任城区'
            },
            {
              value: '370812',
              name: '兖州区'
            },
            {
              value: '370826',
              name: '微山县'
            },
            {
              value: '370827',
              name: '鱼台县'
            },
            {
              value: '370828',
              name: '金乡县'
            },
            {
              value: '370829',
              name: '嘉祥县'
            },
            {
              value: '370830',
              name: '汶上县'
            },
            {
              value: '370831',
              name: '泗水县'
            },
            {
              value: '370832',
              name: '梁山县'
            },
            {
              value: '370871',
              name: '济宁高新技术产业开发区'
            },
            {
              value: '370881',
              name: '曲阜市'
            },
            {
              value: '370883',
              name: '邹城市'
            }
          ]
        },
        {
          value: '370900',
          name: '泰安市',
          children: [
            {
              value: '370902',
              name: '泰山区'
            },
            {
              value: '370911',
              name: '岱岳区'
            },
            {
              value: '370921',
              name: '宁阳县'
            },
            {
              value: '370923',
              name: '东平县'
            },
            {
              value: '370982',
              name: '新泰市'
            },
            {
              value: '370983',
              name: '肥城市'
            }
          ]
        },
        {
          value: '371000',
          name: '威海市',
          children: [
            {
              value: '371002',
              name: '环翠区'
            },
            {
              value: '371003',
              name: '文登区'
            },
            {
              value: '371071',
              name: '威海火炬高技术产业开发区'
            },
            {
              value: '371072',
              name: '威海经济技术开发区'
            },
            {
              value: '371073',
              name: '威海临港经济技术开发区'
            },
            {
              value: '371082',
              name: '荣成市'
            },
            {
              value: '371083',
              name: '乳山市'
            }
          ]
        },
        {
          value: '371100',
          name: '日照市',
          children: [
            {
              value: '371102',
              name: '东港区'
            },
            {
              value: '371103',
              name: '岚山区'
            },
            {
              value: '371121',
              name: '五莲县'
            },
            {
              value: '371122',
              name: '莒县'
            },
            {
              value: '371171',
              name: '日照经济技术开发区'
            }
          ]
        },
        {
          value: '371300',
          name: '临沂市',
          children: [
            {
              value: '371302',
              name: '兰山区'
            },
            {
              value: '371311',
              name: '罗庄区'
            },
            {
              value: '371312',
              name: '河东区'
            },
            {
              value: '371321',
              name: '沂南县'
            },
            {
              value: '371322',
              name: '郯城县'
            },
            {
              value: '371323',
              name: '沂水县'
            },
            {
              value: '371324',
              name: '兰陵县'
            },
            {
              value: '371325',
              name: '费县'
            },
            {
              value: '371326',
              name: '平邑县'
            },
            {
              value: '371327',
              name: '莒南县'
            },
            {
              value: '371328',
              name: '蒙阴县'
            },
            {
              value: '371329',
              name: '临沭县'
            },
            {
              value: '371371',
              name: '临沂高新技术产业开发区'
            },
            {
              value: '371372',
              name: '临沂经济技术开发区'
            },
            {
              value: '371373',
              name: '临沂临港经济开发区'
            }
          ]
        },
        {
          value: '371400',
          name: '德州市',
          children: [
            {
              value: '371402',
              name: '德城区'
            },
            {
              value: '371403',
              name: '陵城区'
            },
            {
              value: '371422',
              name: '宁津县'
            },
            {
              value: '371423',
              name: '庆云县'
            },
            {
              value: '371424',
              name: '临邑县'
            },
            {
              value: '371425',
              name: '齐河县'
            },
            {
              value: '371426',
              name: '平原县'
            },
            {
              value: '371427',
              name: '夏津县'
            },
            {
              value: '371428',
              name: '武城县'
            },
            {
              value: '371471',
              name: '德州经济技术开发区'
            },
            {
              value: '371472',
              name: '德州运河经济开发区'
            },
            {
              value: '371481',
              name: '乐陵市'
            },
            {
              value: '371482',
              name: '禹城市'
            }
          ]
        },
        {
          value: '371500',
          name: '聊城市',
          children: [
            {
              value: '371502',
              name: '东昌府区'
            },
            {
              value: '371503',
              name: '茌平区'
            },
            {
              value: '371521',
              name: '阳谷县'
            },
            {
              value: '371522',
              name: '莘县'
            },
            {
              value: '371524',
              name: '东阿县'
            },
            {
              value: '371525',
              name: '冠县'
            },
            {
              value: '371526',
              name: '高唐县'
            },
            {
              value: '371581',
              name: '临清市'
            }
          ]
        },
        {
          value: '371600',
          name: '滨州市',
          children: [
            {
              value: '371602',
              name: '滨城区'
            },
            {
              value: '371603',
              name: '沾化区'
            },
            {
              value: '371621',
              name: '惠民县'
            },
            {
              value: '371622',
              name: '阳信县'
            },
            {
              value: '371623',
              name: '无棣县'
            },
            {
              value: '371625',
              name: '博兴县'
            },
            {
              value: '371681',
              name: '邹平市'
            }
          ]
        },
        {
          value: '371700',
          name: '菏泽市',
          children: [
            {
              value: '371702',
              name: '牡丹区'
            },
            {
              value: '371703',
              name: '定陶区'
            },
            {
              value: '371721',
              name: '曹县'
            },
            {
              value: '371722',
              name: '单县'
            },
            {
              value: '371723',
              name: '成武县'
            },
            {
              value: '371724',
              name: '巨野县'
            },
            {
              value: '371725',
              name: '郓城县'
            },
            {
              value: '371726',
              name: '鄄城县'
            },
            {
              value: '371728',
              name: '东明县'
            },
            {
              value: '371771',
              name: '菏泽经济技术开发区'
            },
            {
              value: '371772',
              name: '菏泽高新技术开发区'
            }
          ]
        }
      ]
    },
    {
      value: '410000',
      name: '河南省',
      children: [
        {
          value: '410100',
          name: '郑州市',
          children: [
            {
              value: '410102',
              name: '中原区'
            },
            {
              value: '410103',
              name: '二七区'
            },
            {
              value: '410104',
              name: '管城回族区'
            },
            {
              value: '410105',
              name: '金水区'
            },
            {
              value: '410106',
              name: '上街区'
            },
            {
              value: '410108',
              name: '惠济区'
            },
            {
              value: '410122',
              name: '中牟县'
            },
            {
              value: '410171',
              name: '郑州经济技术开发区'
            },
            {
              value: '410172',
              name: '郑州高新技术产业开发区'
            },
            {
              value: '410173',
              name: '郑州航空港经济综合实验区'
            },
            {
              value: '410181',
              name: '巩义市'
            },
            {
              value: '410182',
              name: '荥阳市'
            },
            {
              value: '410183',
              name: '新密市'
            },
            {
              value: '410184',
              name: '新郑市'
            },
            {
              value: '410185',
              name: '登封市'
            }
          ]
        },
        {
          value: '410200',
          name: '开封市',
          children: [
            {
              value: '410202',
              name: '龙亭区'
            },
            {
              value: '410203',
              name: '顺河回族区'
            },
            {
              value: '410204',
              name: '鼓楼区'
            },
            {
              value: '410205',
              name: '禹王台区'
            },
            {
              value: '410212',
              name: '祥符区'
            },
            {
              value: '410221',
              name: '杞县'
            },
            {
              value: '410222',
              name: '通许县'
            },
            {
              value: '410223',
              name: '尉氏县'
            },
            {
              value: '410225',
              name: '兰考县'
            }
          ]
        },
        {
          value: '410300',
          name: '洛阳市',
          children: [
            {
              value: '410302',
              name: '老城区'
            },
            {
              value: '410303',
              name: '西工区'
            },
            {
              value: '410304',
              name: '瀍河回族区'
            },
            {
              value: '410305',
              name: '涧西区'
            },
            {
              value: '410306',
              name: '吉利区'
            },
            {
              value: '410311',
              name: '洛龙区'
            },
            {
              value: '410322',
              name: '孟津县'
            },
            {
              value: '410323',
              name: '新安县'
            },
            {
              value: '410324',
              name: '栾川县'
            },
            {
              value: '410325',
              name: '嵩县'
            },
            {
              value: '410326',
              name: '汝阳县'
            },
            {
              value: '410327',
              name: '宜阳县'
            },
            {
              value: '410328',
              name: '洛宁县'
            },
            {
              value: '410329',
              name: '伊川县'
            },
            {
              value: '410371',
              name: '洛阳高新技术产业开发区'
            },
            {
              value: '410381',
              name: '偃师市'
            }
          ]
        },
        {
          value: '410400',
          name: '平顶山市',
          children: [
            {
              value: '410402',
              name: '新华区'
            },
            {
              value: '410403',
              name: '卫东区'
            },
            {
              value: '410404',
              name: '石龙区'
            },
            {
              value: '410411',
              name: '湛河区'
            },
            {
              value: '410421',
              name: '宝丰县'
            },
            {
              value: '410422',
              name: '叶县'
            },
            {
              value: '410423',
              name: '鲁山县'
            },
            {
              value: '410425',
              name: '郏县'
            },
            {
              value: '410471',
              name: '平顶山高新技术产业开发区'
            },
            {
              value: '410472',
              name: '平顶山市城乡一体化示范区'
            },
            {
              value: '410481',
              name: '舞钢市'
            },
            {
              value: '410482',
              name: '汝州市'
            }
          ]
        },
        {
          value: '410500',
          name: '安阳市',
          children: [
            {
              value: '410502',
              name: '文峰区'
            },
            {
              value: '410503',
              name: '北关区'
            },
            {
              value: '410505',
              name: '殷都区'
            },
            {
              value: '410506',
              name: '龙安区'
            },
            {
              value: '410522',
              name: '安阳县'
            },
            {
              value: '410523',
              name: '汤阴县'
            },
            {
              value: '410526',
              name: '滑县'
            },
            {
              value: '410527',
              name: '内黄县'
            },
            {
              value: '410571',
              name: '安阳高新技术产业开发区'
            },
            {
              value: '410581',
              name: '林州市'
            }
          ]
        },
        {
          value: '410600',
          name: '鹤壁市',
          children: [
            {
              value: '410602',
              name: '鹤山区'
            },
            {
              value: '410603',
              name: '山城区'
            },
            {
              value: '410611',
              name: '淇滨区'
            },
            {
              value: '410621',
              name: '浚县'
            },
            {
              value: '410622',
              name: '淇县'
            },
            {
              value: '410671',
              name: '鹤壁经济技术开发区'
            }
          ]
        },
        {
          value: '410700',
          name: '新乡市',
          children: [
            {
              value: '410702',
              name: '红旗区'
            },
            {
              value: '410703',
              name: '卫滨区'
            },
            {
              value: '410704',
              name: '凤泉区'
            },
            {
              value: '410711',
              name: '牧野区'
            },
            {
              value: '410721',
              name: '新乡县'
            },
            {
              value: '410724',
              name: '获嘉县'
            },
            {
              value: '410725',
              name: '原阳县'
            },
            {
              value: '410726',
              name: '延津县'
            },
            {
              value: '410727',
              name: '封丘县'
            },
            {
              value: '410771',
              name: '新乡高新技术产业开发区'
            },
            {
              value: '410772',
              name: '新乡经济技术开发区'
            },
            {
              value: '410773',
              name: '新乡市平原城乡一体化示范区'
            },
            {
              value: '410781',
              name: '卫辉市'
            },
            {
              value: '410782',
              name: '辉县市'
            },
            {
              value: '410783',
              name: '长垣市'
            }
          ]
        },
        {
          value: '410800',
          name: '焦作市',
          children: [
            {
              value: '410802',
              name: '解放区'
            },
            {
              value: '410803',
              name: '中站区'
            },
            {
              value: '410804',
              name: '马村区'
            },
            {
              value: '410811',
              name: '山阳区'
            },
            {
              value: '410821',
              name: '修武县'
            },
            {
              value: '410822',
              name: '博爱县'
            },
            {
              value: '410823',
              name: '武陟县'
            },
            {
              value: '410825',
              name: '温县'
            },
            {
              value: '410871',
              name: '焦作城乡一体化示范区'
            },
            {
              value: '410882',
              name: '沁阳市'
            },
            {
              value: '410883',
              name: '孟州市'
            }
          ]
        },
        {
          value: '410900',
          name: '濮阳市',
          children: [
            {
              value: '410902',
              name: '华龙区'
            },
            {
              value: '410922',
              name: '清丰县'
            },
            {
              value: '410923',
              name: '南乐县'
            },
            {
              value: '410926',
              name: '范县'
            },
            {
              value: '410927',
              name: '台前县'
            },
            {
              value: '410928',
              name: '濮阳县'
            },
            {
              value: '410971',
              name: '河南濮阳工业园区'
            },
            {
              value: '410972',
              name: '濮阳经济技术开发区'
            }
          ]
        },
        {
          value: '411000',
          name: '许昌市',
          children: [
            {
              value: '411002',
              name: '魏都区'
            },
            {
              value: '411003',
              name: '建安区'
            },
            {
              value: '411024',
              name: '鄢陵县'
            },
            {
              value: '411025',
              name: '襄城县'
            },
            {
              value: '411071',
              name: '许昌经济技术开发区'
            },
            {
              value: '411081',
              name: '禹州市'
            },
            {
              value: '411082',
              name: '长葛市'
            }
          ]
        },
        {
          value: '411100',
          name: '漯河市',
          children: [
            {
              value: '411102',
              name: '源汇区'
            },
            {
              value: '411103',
              name: '郾城区'
            },
            {
              value: '411104',
              name: '召陵区'
            },
            {
              value: '411121',
              name: '舞阳县'
            },
            {
              value: '411122',
              name: '临颍县'
            },
            {
              value: '411171',
              name: '漯河经济技术开发区'
            }
          ]
        },
        {
          value: '411200',
          name: '三门峡市',
          children: [
            {
              value: '411202',
              name: '湖滨区'
            },
            {
              value: '411203',
              name: '陕州区'
            },
            {
              value: '411221',
              name: '渑池县'
            },
            {
              value: '411224',
              name: '卢氏县'
            },
            {
              value: '411271',
              name: '河南三门峡经济开发区'
            },
            {
              value: '411281',
              name: '义马市'
            },
            {
              value: '411282',
              name: '灵宝市'
            }
          ]
        },
        {
          value: '411300',
          name: '南阳市',
          children: [
            {
              value: '411302',
              name: '宛城区'
            },
            {
              value: '411303',
              name: '卧龙区'
            },
            {
              value: '411321',
              name: '南召县'
            },
            {
              value: '411322',
              name: '方城县'
            },
            {
              value: '411323',
              name: '西峡县'
            },
            {
              value: '411324',
              name: '镇平县'
            },
            {
              value: '411325',
              name: '内乡县'
            },
            {
              value: '411326',
              name: '淅川县'
            },
            {
              value: '411327',
              name: '社旗县'
            },
            {
              value: '411328',
              name: '唐河县'
            },
            {
              value: '411329',
              name: '新野县'
            },
            {
              value: '411330',
              name: '桐柏县'
            },
            {
              value: '411371',
              name: '南阳高新技术产业开发区'
            },
            {
              value: '411372',
              name: '南阳市城乡一体化示范区'
            },
            {
              value: '411381',
              name: '邓州市'
            }
          ]
        },
        {
          value: '411400',
          name: '商丘市',
          children: [
            {
              value: '411402',
              name: '梁园区'
            },
            {
              value: '411403',
              name: '睢阳区'
            },
            {
              value: '411421',
              name: '民权县'
            },
            {
              value: '411422',
              name: '睢县'
            },
            {
              value: '411423',
              name: '宁陵县'
            },
            {
              value: '411424',
              name: '柘城县'
            },
            {
              value: '411425',
              name: '虞城县'
            },
            {
              value: '411426',
              name: '夏邑县'
            },
            {
              value: '411471',
              name: '豫东综合物流产业聚集区'
            },
            {
              value: '411472',
              name: '河南商丘经济开发区'
            },
            {
              value: '411481',
              name: '永城市'
            }
          ]
        },
        {
          value: '411500',
          name: '信阳市',
          children: [
            {
              value: '411502',
              name: '浉河区'
            },
            {
              value: '411503',
              name: '平桥区'
            },
            {
              value: '411521',
              name: '罗山县'
            },
            {
              value: '411522',
              name: '光山县'
            },
            {
              value: '411523',
              name: '新县'
            },
            {
              value: '411524',
              name: '商城县'
            },
            {
              value: '411525',
              name: '固始县'
            },
            {
              value: '411526',
              name: '潢川县'
            },
            {
              value: '411527',
              name: '淮滨县'
            },
            {
              value: '411528',
              name: '息县'
            },
            {
              value: '411571',
              name: '信阳高新技术产业开发区'
            }
          ]
        },
        {
          value: '411600',
          name: '周口市',
          children: [
            {
              value: '411602',
              name: '川汇区'
            },
            {
              value: '411603',
              name: '淮阳区'
            },
            {
              value: '411621',
              name: '扶沟县'
            },
            {
              value: '411622',
              name: '西华县'
            },
            {
              value: '411623',
              name: '商水县'
            },
            {
              value: '411624',
              name: '沈丘县'
            },
            {
              value: '411625',
              name: '郸城县'
            },
            {
              value: '411627',
              name: '太康县'
            },
            {
              value: '411628',
              name: '鹿邑县'
            },
            {
              value: '411671',
              name: '河南周口经济开发区'
            },
            {
              value: '411681',
              name: '项城市'
            }
          ]
        },
        {
          value: '411700',
          name: '驻马店市',
          children: [
            {
              value: '411702',
              name: '驿城区'
            },
            {
              value: '411721',
              name: '西平县'
            },
            {
              value: '411722',
              name: '上蔡县'
            },
            {
              value: '411723',
              name: '平舆县'
            },
            {
              value: '411724',
              name: '正阳县'
            },
            {
              value: '411725',
              name: '确山县'
            },
            {
              value: '411726',
              name: '泌阳县'
            },
            {
              value: '411727',
              name: '汝南县'
            },
            {
              value: '411728',
              name: '遂平县'
            },
            {
              value: '411729',
              name: '新蔡县'
            },
            {
              value: '411771',
              name: '河南驻马店经济开发区'
            }
          ]
        },
        {
          value: '419000',
          name: '省直辖县级行政区划',
          children: [
            {
              value: '419001',
              name: '济源市'
            }
          ]
        }
      ]
    },
    {
      value: '420000',
      name: '湖北省',
      children: [
        {
          value: '420100',
          name: '武汉市',
          children: [
            {
              value: '420102',
              name: '江岸区'
            },
            {
              value: '420103',
              name: '江汉区'
            },
            {
              value: '420104',
              name: '硚口区'
            },
            {
              value: '420105',
              name: '汉阳区'
            },
            {
              value: '420106',
              name: '武昌区'
            },
            {
              value: '420107',
              name: '青山区'
            },
            {
              value: '420111',
              name: '洪山区'
            },
            {
              value: '420112',
              name: '东西湖区'
            },
            {
              value: '420113',
              name: '汉南区'
            },
            {
              value: '420114',
              name: '蔡甸区'
            },
            {
              value: '420115',
              name: '江夏区'
            },
            {
              value: '420116',
              name: '黄陂区'
            },
            {
              value: '420117',
              name: '新洲区'
            }
          ]
        },
        {
          value: '420200',
          name: '黄石市',
          children: [
            {
              value: '420202',
              name: '黄石港区'
            },
            {
              value: '420203',
              name: '西塞山区'
            },
            {
              value: '420204',
              name: '下陆区'
            },
            {
              value: '420205',
              name: '铁山区'
            },
            {
              value: '420222',
              name: '阳新县'
            },
            {
              value: '420281',
              name: '大冶市'
            }
          ]
        },
        {
          value: '420300',
          name: '十堰市',
          children: [
            {
              value: '420302',
              name: '茅箭区'
            },
            {
              value: '420303',
              name: '张湾区'
            },
            {
              value: '420304',
              name: '郧阳区'
            },
            {
              value: '420322',
              name: '郧西县'
            },
            {
              value: '420323',
              name: '竹山县'
            },
            {
              value: '420324',
              name: '竹溪县'
            },
            {
              value: '420325',
              name: '房县'
            },
            {
              value: '420381',
              name: '丹江口市'
            }
          ]
        },
        {
          value: '420500',
          name: '宜昌市',
          children: [
            {
              value: '420502',
              name: '西陵区'
            },
            {
              value: '420503',
              name: '伍家岗区'
            },
            {
              value: '420504',
              name: '点军区'
            },
            {
              value: '420505',
              name: '猇亭区'
            },
            {
              value: '420506',
              name: '夷陵区'
            },
            {
              value: '420525',
              name: '远安县'
            },
            {
              value: '420526',
              name: '兴山县'
            },
            {
              value: '420527',
              name: '秭归县'
            },
            {
              value: '420528',
              name: '长阳土家族自治县'
            },
            {
              value: '420529',
              name: '五峰土家族自治县'
            },
            {
              value: '420581',
              name: '宜都市'
            },
            {
              value: '420582',
              name: '当阳市'
            },
            {
              value: '420583',
              name: '枝江市'
            }
          ]
        },
        {
          value: '420600',
          name: '襄阳市',
          children: [
            {
              value: '420602',
              name: '襄城区'
            },
            {
              value: '420606',
              name: '樊城区'
            },
            {
              value: '420607',
              name: '襄州区'
            },
            {
              value: '420624',
              name: '南漳县'
            },
            {
              value: '420625',
              name: '谷城县'
            },
            {
              value: '420626',
              name: '保康县'
            },
            {
              value: '420682',
              name: '老河口市'
            },
            {
              value: '420683',
              name: '枣阳市'
            },
            {
              value: '420684',
              name: '宜城市'
            }
          ]
        },
        {
          value: '420700',
          name: '鄂州市',
          children: [
            {
              value: '420702',
              name: '梁子湖区'
            },
            {
              value: '420703',
              name: '华容区'
            },
            {
              value: '420704',
              name: '鄂城区'
            }
          ]
        },
        {
          value: '420800',
          name: '荆门市',
          children: [
            {
              value: '420802',
              name: '东宝区'
            },
            {
              value: '420804',
              name: '掇刀区'
            },
            {
              value: '420822',
              name: '沙洋县'
            },
            {
              value: '420881',
              name: '钟祥市'
            },
            {
              value: '420882',
              name: '京山市'
            }
          ]
        },
        {
          value: '420900',
          name: '孝感市',
          children: [
            {
              value: '420902',
              name: '孝南区'
            },
            {
              value: '420921',
              name: '孝昌县'
            },
            {
              value: '420922',
              name: '大悟县'
            },
            {
              value: '420923',
              name: '云梦县'
            },
            {
              value: '420981',
              name: '应城市'
            },
            {
              value: '420982',
              name: '安陆市'
            },
            {
              value: '420984',
              name: '汉川市'
            }
          ]
        },
        {
          value: '421000',
          name: '荆州市',
          children: [
            {
              value: '421002',
              name: '沙市区'
            },
            {
              value: '421003',
              name: '荆州区'
            },
            {
              value: '421022',
              name: '公安县'
            },
            {
              value: '421023',
              name: '监利县'
            },
            {
              value: '421024',
              name: '江陵县'
            },
            {
              value: '421071',
              name: '荆州经济技术开发区'
            },
            {
              value: '421081',
              name: '石首市'
            },
            {
              value: '421083',
              name: '洪湖市'
            },
            {
              value: '421087',
              name: '松滋市'
            }
          ]
        },
        {
          value: '421100',
          name: '黄冈市',
          children: [
            {
              value: '421102',
              name: '黄州区'
            },
            {
              value: '421121',
              name: '团风县'
            },
            {
              value: '421122',
              name: '红安县'
            },
            {
              value: '421123',
              name: '罗田县'
            },
            {
              value: '421124',
              name: '英山县'
            },
            {
              value: '421125',
              name: '浠水县'
            },
            {
              value: '421126',
              name: '蕲春县'
            },
            {
              value: '421127',
              name: '黄梅县'
            },
            {
              value: '421171',
              name: '龙感湖管理区'
            },
            {
              value: '421181',
              name: '麻城市'
            },
            {
              value: '421182',
              name: '武穴市'
            }
          ]
        },
        {
          value: '421200',
          name: '咸宁市',
          children: [
            {
              value: '421202',
              name: '咸安区'
            },
            {
              value: '421221',
              name: '嘉鱼县'
            },
            {
              value: '421222',
              name: '通城县'
            },
            {
              value: '421223',
              name: '崇阳县'
            },
            {
              value: '421224',
              name: '通山县'
            },
            {
              value: '421281',
              name: '赤壁市'
            }
          ]
        },
        {
          value: '421300',
          name: '随州市',
          children: [
            {
              value: '421303',
              name: '曾都区'
            },
            {
              value: '421321',
              name: '随县'
            },
            {
              value: '421381',
              name: '广水市'
            }
          ]
        },
        {
          value: '422800',
          name: '恩施土家族苗族自治州',
          children: [
            {
              value: '422801',
              name: '恩施市'
            },
            {
              value: '422802',
              name: '利川市'
            },
            {
              value: '422822',
              name: '建始县'
            },
            {
              value: '422823',
              name: '巴东县'
            },
            {
              value: '422825',
              name: '宣恩县'
            },
            {
              value: '422826',
              name: '咸丰县'
            },
            {
              value: '422827',
              name: '来凤县'
            },
            {
              value: '422828',
              name: '鹤峰县'
            }
          ]
        },
        {
          value: '429000',
          name: '省直辖县级行政区划',
          children: [
            {
              value: '429004',
              name: '仙桃市'
            },
            {
              value: '429005',
              name: '潜江市'
            },
            {
              value: '429006',
              name: '天门市'
            },
            {
              value: '429021',
              name: '神农架林区'
            }
          ]
        }
      ]
    },
    {
      value: '430000',
      name: '湖南省',
      children: [
        {
          value: '430100',
          name: '长沙市',
          children: [
            {
              value: '430102',
              name: '芙蓉区'
            },
            {
              value: '430103',
              name: '天心区'
            },
            {
              value: '430104',
              name: '岳麓区'
            },
            {
              value: '430105',
              name: '开福区'
            },
            {
              value: '430111',
              name: '雨花区'
            },
            {
              value: '430112',
              name: '望城区'
            },
            {
              value: '430121',
              name: '长沙县'
            },
            {
              value: '430181',
              name: '浏阳市'
            },
            {
              value: '430182',
              name: '宁乡市'
            }
          ]
        },
        {
          value: '430200',
          name: '株洲市',
          children: [
            {
              value: '430202',
              name: '荷塘区'
            },
            {
              value: '430203',
              name: '芦淞区'
            },
            {
              value: '430204',
              name: '石峰区'
            },
            {
              value: '430211',
              name: '天元区'
            },
            {
              value: '430212',
              name: '渌口区'
            },
            {
              value: '430223',
              name: '攸县'
            },
            {
              value: '430224',
              name: '茶陵县'
            },
            {
              value: '430225',
              name: '炎陵县'
            },
            {
              value: '430271',
              name: '云龙示范区'
            },
            {
              value: '430281',
              name: '醴陵市'
            }
          ]
        },
        {
          value: '430300',
          name: '湘潭市',
          children: [
            {
              value: '430302',
              name: '雨湖区'
            },
            {
              value: '430304',
              name: '岳塘区'
            },
            {
              value: '430321',
              name: '湘潭县'
            },
            {
              value: '430371',
              name: '湖南湘潭高新技术产业园区'
            },
            {
              value: '430372',
              name: '湘潭昭山示范区'
            },
            {
              value: '430373',
              name: '湘潭九华示范区'
            },
            {
              value: '430381',
              name: '湘乡市'
            },
            {
              value: '430382',
              name: '韶山市'
            }
          ]
        },
        {
          value: '430400',
          name: '衡阳市',
          children: [
            {
              value: '430405',
              name: '珠晖区'
            },
            {
              value: '430406',
              name: '雁峰区'
            },
            {
              value: '430407',
              name: '石鼓区'
            },
            {
              value: '430408',
              name: '蒸湘区'
            },
            {
              value: '430412',
              name: '南岳区'
            },
            {
              value: '430421',
              name: '衡阳县'
            },
            {
              value: '430422',
              name: '衡南县'
            },
            {
              value: '430423',
              name: '衡山县'
            },
            {
              value: '430424',
              name: '衡东县'
            },
            {
              value: '430426',
              name: '祁东县'
            },
            {
              value: '430471',
              name: '衡阳综合保税区'
            },
            {
              value: '430472',
              name: '湖南衡阳高新技术产业园区'
            },
            {
              value: '430473',
              name: '湖南衡阳松木经济开发区'
            },
            {
              value: '430481',
              name: '耒阳市'
            },
            {
              value: '430482',
              name: '常宁市'
            }
          ]
        },
        {
          value: '430500',
          name: '邵阳市',
          children: [
            {
              value: '430502',
              name: '双清区'
            },
            {
              value: '430503',
              name: '大祥区'
            },
            {
              value: '430511',
              name: '北塔区'
            },
            {
              value: '430522',
              name: '新邵县'
            },
            {
              value: '430523',
              name: '邵阳县'
            },
            {
              value: '430524',
              name: '隆回县'
            },
            {
              value: '430525',
              name: '洞口县'
            },
            {
              value: '430527',
              name: '绥宁县'
            },
            {
              value: '430528',
              name: '新宁县'
            },
            {
              value: '430529',
              name: '城步苗族自治县'
            },
            {
              value: '430581',
              name: '武冈市'
            },
            {
              value: '430582',
              name: '邵东市'
            }
          ]
        },
        {
          value: '430600',
          name: '岳阳市',
          children: [
            {
              value: '430602',
              name: '岳阳楼区'
            },
            {
              value: '430603',
              name: '云溪区'
            },
            {
              value: '430611',
              name: '君山区'
            },
            {
              value: '430621',
              name: '岳阳县'
            },
            {
              value: '430623',
              name: '华容县'
            },
            {
              value: '430624',
              name: '湘阴县'
            },
            {
              value: '430626',
              name: '平江县'
            },
            {
              value: '430671',
              name: '岳阳市屈原管理区'
            },
            {
              value: '430681',
              name: '汨罗市'
            },
            {
              value: '430682',
              name: '临湘市'
            }
          ]
        },
        {
          value: '430700',
          name: '常德市',
          children: [
            {
              value: '430702',
              name: '武陵区'
            },
            {
              value: '430703',
              name: '鼎城区'
            },
            {
              value: '430721',
              name: '安乡县'
            },
            {
              value: '430722',
              name: '汉寿县'
            },
            {
              value: '430723',
              name: '澧县'
            },
            {
              value: '430724',
              name: '临澧县'
            },
            {
              value: '430725',
              name: '桃源县'
            },
            {
              value: '430726',
              name: '石门县'
            },
            {
              value: '430771',
              name: '常德市西洞庭管理区'
            },
            {
              value: '430781',
              name: '津市市'
            }
          ]
        },
        {
          value: '430800',
          name: '张家界市',
          children: [
            {
              value: '430802',
              name: '永定区'
            },
            {
              value: '430811',
              name: '武陵源区'
            },
            {
              value: '430821',
              name: '慈利县'
            },
            {
              value: '430822',
              name: '桑植县'
            }
          ]
        },
        {
          value: '430900',
          name: '益阳市',
          children: [
            {
              value: '430902',
              name: '资阳区'
            },
            {
              value: '430903',
              name: '赫山区'
            },
            {
              value: '430921',
              name: '南县'
            },
            {
              value: '430922',
              name: '桃江县'
            },
            {
              value: '430923',
              name: '安化县'
            },
            {
              value: '430971',
              name: '益阳市大通湖管理区'
            },
            {
              value: '430972',
              name: '湖南益阳高新技术产业园区'
            },
            {
              value: '430981',
              name: '沅江市'
            }
          ]
        },
        {
          value: '431000',
          name: '郴州市',
          children: [
            {
              value: '431002',
              name: '北湖区'
            },
            {
              value: '431003',
              name: '苏仙区'
            },
            {
              value: '431021',
              name: '桂阳县'
            },
            {
              value: '431022',
              name: '宜章县'
            },
            {
              value: '431023',
              name: '永兴县'
            },
            {
              value: '431024',
              name: '嘉禾县'
            },
            {
              value: '431025',
              name: '临武县'
            },
            {
              value: '431026',
              name: '汝城县'
            },
            {
              value: '431027',
              name: '桂东县'
            },
            {
              value: '431028',
              name: '安仁县'
            },
            {
              value: '431081',
              name: '资兴市'
            }
          ]
        },
        {
          value: '431100',
          name: '永州市',
          children: [
            {
              value: '431102',
              name: '零陵区'
            },
            {
              value: '431103',
              name: '冷水滩区'
            },
            {
              value: '431121',
              name: '祁阳县'
            },
            {
              value: '431122',
              name: '东安县'
            },
            {
              value: '431123',
              name: '双牌县'
            },
            {
              value: '431124',
              name: '道县'
            },
            {
              value: '431125',
              name: '江永县'
            },
            {
              value: '431126',
              name: '宁远县'
            },
            {
              value: '431127',
              name: '蓝山县'
            },
            {
              value: '431128',
              name: '新田县'
            },
            {
              value: '431129',
              name: '江华瑶族自治县'
            },
            {
              value: '431171',
              name: '永州经济技术开发区'
            },
            {
              value: '431172',
              name: '永州市金洞管理区'
            },
            {
              value: '431173',
              name: '永州市回龙圩管理区'
            }
          ]
        },
        {
          value: '431200',
          name: '怀化市',
          children: [
            {
              value: '431202',
              name: '鹤城区'
            },
            {
              value: '431221',
              name: '中方县'
            },
            {
              value: '431222',
              name: '沅陵县'
            },
            {
              value: '431223',
              name: '辰溪县'
            },
            {
              value: '431224',
              name: '溆浦县'
            },
            {
              value: '431225',
              name: '会同县'
            },
            {
              value: '431226',
              name: '麻阳苗族自治县'
            },
            {
              value: '431227',
              name: '新晃侗族自治县'
            },
            {
              value: '431228',
              name: '芷江侗族自治县'
            },
            {
              value: '431229',
              name: '靖州苗族侗族自治县'
            },
            {
              value: '431230',
              name: '通道侗族自治县'
            },
            {
              value: '431271',
              name: '怀化市洪江管理区'
            },
            {
              value: '431281',
              name: '洪江市'
            }
          ]
        },
        {
          value: '431300',
          name: '娄底市',
          children: [
            {
              value: '431302',
              name: '娄星区'
            },
            {
              value: '431321',
              name: '双峰县'
            },
            {
              value: '431322',
              name: '新化县'
            },
            {
              value: '431381',
              name: '冷水江市'
            },
            {
              value: '431382',
              name: '涟源市'
            }
          ]
        },
        {
          value: '433100',
          name: '湘西土家族苗族自治州',
          children: [
            {
              value: '433101',
              name: '吉首市'
            },
            {
              value: '433122',
              name: '泸溪县'
            },
            {
              value: '433123',
              name: '凤凰县'
            },
            {
              value: '433124',
              name: '花垣县'
            },
            {
              value: '433125',
              name: '保靖县'
            },
            {
              value: '433126',
              name: '古丈县'
            },
            {
              value: '433127',
              name: '永顺县'
            },
            {
              value: '433130',
              name: '龙山县'
            },
            {
              value: '433173',
              name: '湖南永顺经济开发区'
            }
          ]
        }
      ]
    },
    {
      value: '440000',
      name: '广东省',
      children: [
        {
          value: '440100',
          name: '广州市',
          children: [
            {
              value: '440103',
              name: '荔湾区'
            },
            {
              value: '440104',
              name: '越秀区'
            },
            {
              value: '440105',
              name: '海珠区'
            },
            {
              value: '440106',
              name: '天河区'
            },
            {
              value: '440111',
              name: '白云区'
            },
            {
              value: '440112',
              name: '黄埔区'
            },
            {
              value: '440113',
              name: '番禺区'
            },
            {
              value: '440114',
              name: '花都区'
            },
            {
              value: '440115',
              name: '南沙区'
            },
            {
              value: '440117',
              name: '从化区'
            },
            {
              value: '440118',
              name: '增城区'
            }
          ]
        },
        {
          value: '440200',
          name: '韶关市',
          children: [
            {
              value: '440203',
              name: '武江区'
            },
            {
              value: '440204',
              name: '浈江区'
            },
            {
              value: '440205',
              name: '曲江区'
            },
            {
              value: '440222',
              name: '始兴县'
            },
            {
              value: '440224',
              name: '仁化县'
            },
            {
              value: '440229',
              name: '翁源县'
            },
            {
              value: '440232',
              name: '乳源瑶族自治县'
            },
            {
              value: '440233',
              name: '新丰县'
            },
            {
              value: '440281',
              name: '乐昌市'
            },
            {
              value: '440282',
              name: '南雄市'
            }
          ]
        },
        {
          value: '440300',
          name: '深圳市',
          children: [
            {
              value: '440303',
              name: '罗湖区'
            },
            {
              value: '440304',
              name: '福田区'
            },
            {
              value: '440305',
              name: '南山区'
            },
            {
              value: '440306',
              name: '宝安区'
            },
            {
              value: '440307',
              name: '龙岗区'
            },
            {
              value: '440308',
              name: '盐田区'
            },
            {
              value: '440309',
              name: '龙华区'
            },
            {
              value: '440310',
              name: '坪山区'
            },
            {
              value: '440311',
              name: '光明区'
            }
          ]
        },
        {
          value: '440400',
          name: '珠海市',
          children: [
            {
              value: '440402',
              name: '香洲区'
            },
            {
              value: '440403',
              name: '斗门区'
            },
            {
              value: '440404',
              name: '金湾区'
            }
          ]
        },
        {
          value: '440500',
          name: '汕头市',
          children: [
            {
              value: '440507',
              name: '龙湖区'
            },
            {
              value: '440511',
              name: '金平区'
            },
            {
              value: '440512',
              name: '濠江区'
            },
            {
              value: '440513',
              name: '潮阳区'
            },
            {
              value: '440514',
              name: '潮南区'
            },
            {
              value: '440515',
              name: '澄海区'
            },
            {
              value: '440523',
              name: '南澳县'
            }
          ]
        },
        {
          value: '440600',
          name: '佛山市',
          children: [
            {
              value: '440604',
              name: '禅城区'
            },
            {
              value: '440605',
              name: '南海区'
            },
            {
              value: '440606',
              name: '顺德区'
            },
            {
              value: '440607',
              name: '三水区'
            },
            {
              value: '440608',
              name: '高明区'
            }
          ]
        },
        {
          value: '440700',
          name: '江门市',
          children: [
            {
              value: '440703',
              name: '蓬江区'
            },
            {
              value: '440704',
              name: '江海区'
            },
            {
              value: '440705',
              name: '新会区'
            },
            {
              value: '440781',
              name: '台山市'
            },
            {
              value: '440783',
              name: '开平市'
            },
            {
              value: '440784',
              name: '鹤山市'
            },
            {
              value: '440785',
              name: '恩平市'
            }
          ]
        },
        {
          value: '440800',
          name: '湛江市',
          children: [
            {
              value: '440802',
              name: '赤坎区'
            },
            {
              value: '440803',
              name: '霞山区'
            },
            {
              value: '440804',
              name: '坡头区'
            },
            {
              value: '440811',
              name: '麻章区'
            },
            {
              value: '440823',
              name: '遂溪县'
            },
            {
              value: '440825',
              name: '徐闻县'
            },
            {
              value: '440881',
              name: '廉江市'
            },
            {
              value: '440882',
              name: '雷州市'
            },
            {
              value: '440883',
              name: '吴川市'
            }
          ]
        },
        {
          value: '440900',
          name: '茂名市',
          children: [
            {
              value: '440902',
              name: '茂南区'
            },
            {
              value: '440904',
              name: '电白区'
            },
            {
              value: '440981',
              name: '高州市'
            },
            {
              value: '440982',
              name: '化州市'
            },
            {
              value: '440983',
              name: '信宜市'
            }
          ]
        },
        {
          value: '441200',
          name: '肇庆市',
          children: [
            {
              value: '441202',
              name: '端州区'
            },
            {
              value: '441203',
              name: '鼎湖区'
            },
            {
              value: '441204',
              name: '高要区'
            },
            {
              value: '441223',
              name: '广宁县'
            },
            {
              value: '441224',
              name: '怀集县'
            },
            {
              value: '441225',
              name: '封开县'
            },
            {
              value: '441226',
              name: '德庆县'
            },
            {
              value: '441284',
              name: '四会市'
            }
          ]
        },
        {
          value: '441300',
          name: '惠州市',
          children: [
            {
              value: '441302',
              name: '惠城区'
            },
            {
              value: '441303',
              name: '惠阳区'
            },
            {
              value: '441322',
              name: '博罗县'
            },
            {
              value: '441323',
              name: '惠东县'
            },
            {
              value: '441324',
              name: '龙门县'
            }
          ]
        },
        {
          value: '441400',
          name: '梅州市',
          children: [
            {
              value: '441402',
              name: '梅江区'
            },
            {
              value: '441403',
              name: '梅县区'
            },
            {
              value: '441422',
              name: '大埔县'
            },
            {
              value: '441423',
              name: '丰顺县'
            },
            {
              value: '441424',
              name: '五华县'
            },
            {
              value: '441426',
              name: '平远县'
            },
            {
              value: '441427',
              name: '蕉岭县'
            },
            {
              value: '441481',
              name: '兴宁市'
            }
          ]
        },
        {
          value: '441500',
          name: '汕尾市',
          children: [
            {
              value: '441502',
              name: '城区'
            },
            {
              value: '441521',
              name: '海丰县'
            },
            {
              value: '441523',
              name: '陆河县'
            },
            {
              value: '441581',
              name: '陆丰市'
            }
          ]
        },
        {
          value: '441600',
          name: '河源市',
          children: [
            {
              value: '441602',
              name: '源城区'
            },
            {
              value: '441621',
              name: '紫金县'
            },
            {
              value: '441622',
              name: '龙川县'
            },
            {
              value: '441623',
              name: '连平县'
            },
            {
              value: '441624',
              name: '和平县'
            },
            {
              value: '441625',
              name: '东源县'
            }
          ]
        },
        {
          value: '441700',
          name: '阳江市',
          children: [
            {
              value: '441702',
              name: '江城区'
            },
            {
              value: '441704',
              name: '阳东区'
            },
            {
              value: '441721',
              name: '阳西县'
            },
            {
              value: '441781',
              name: '阳春市'
            }
          ]
        },
        {
          value: '441800',
          name: '清远市',
          children: [
            {
              value: '441802',
              name: '清城区'
            },
            {
              value: '441803',
              name: '清新区'
            },
            {
              value: '441821',
              name: '佛冈县'
            },
            {
              value: '441823',
              name: '阳山县'
            },
            {
              value: '441825',
              name: '连山壮族瑶族自治县'
            },
            {
              value: '441826',
              name: '连南瑶族自治县'
            },
            {
              value: '441881',
              name: '英德市'
            },
            {
              value: '441882',
              name: '连州市'
            }
          ]
        },
        {
          value: '441900',
          name: '东莞市',
          children: [
            {
              value: '441900',
              name: '东莞市'
            }
          ]
        },
        {
          value: '442000',
          name: '中山市',
          children: [
            {
              value: '442000',
              name: '中山市'
            }
          ]
        },
        {
          value: '445100',
          name: '潮州市',
          children: [
            {
              value: '445102',
              name: '湘桥区'
            },
            {
              value: '445103',
              name: '潮安区'
            },
            {
              value: '445122',
              name: '饶平县'
            }
          ]
        },
        {
          value: '445200',
          name: '揭阳市',
          children: [
            {
              value: '445202',
              name: '榕城区'
            },
            {
              value: '445203',
              name: '揭东区'
            },
            {
              value: '445222',
              name: '揭西县'
            },
            {
              value: '445224',
              name: '惠来县'
            },
            {
              value: '445281',
              name: '普宁市'
            }
          ]
        },
        {
          value: '445300',
          name: '云浮市',
          children: [
            {
              value: '445302',
              name: '云城区'
            },
            {
              value: '445303',
              name: '云安区'
            },
            {
              value: '445321',
              name: '新兴县'
            },
            {
              value: '445322',
              name: '郁南县'
            },
            {
              value: '445381',
              name: '罗定市'
            }
          ]
        }
      ]
    },
    {
      value: '450000',
      name: '广西壮族自治区',
      children: [
        {
          value: '450100',
          name: '南宁市',
          children: [
            {
              value: '450102',
              name: '兴宁区'
            },
            {
              value: '450103',
              name: '青秀区'
            },
            {
              value: '450105',
              name: '江南区'
            },
            {
              value: '450107',
              name: '西乡塘区'
            },
            {
              value: '450108',
              name: '良庆区'
            },
            {
              value: '450109',
              name: '邕宁区'
            },
            {
              value: '450110',
              name: '武鸣区'
            },
            {
              value: '450123',
              name: '隆安县'
            },
            {
              value: '450124',
              name: '马山县'
            },
            {
              value: '450125',
              name: '上林县'
            },
            {
              value: '450126',
              name: '宾阳县'
            },
            {
              value: '450127',
              name: '横县'
            }
          ]
        },
        {
          value: '450200',
          name: '柳州市',
          children: [
            {
              value: '450202',
              name: '城中区'
            },
            {
              value: '450203',
              name: '鱼峰区'
            },
            {
              value: '450204',
              name: '柳南区'
            },
            {
              value: '450205',
              name: '柳北区'
            },
            {
              value: '450206',
              name: '柳江区'
            },
            {
              value: '450222',
              name: '柳城县'
            },
            {
              value: '450223',
              name: '鹿寨县'
            },
            {
              value: '450224',
              name: '融安县'
            },
            {
              value: '450225',
              name: '融水苗族自治县'
            },
            {
              value: '450226',
              name: '三江侗族自治县'
            }
          ]
        },
        {
          value: '450300',
          name: '桂林市',
          children: [
            {
              value: '450302',
              name: '秀峰区'
            },
            {
              value: '450303',
              name: '叠彩区'
            },
            {
              value: '450304',
              name: '象山区'
            },
            {
              value: '450305',
              name: '七星区'
            },
            {
              value: '450311',
              name: '雁山区'
            },
            {
              value: '450312',
              name: '临桂区'
            },
            {
              value: '450321',
              name: '阳朔县'
            },
            {
              value: '450323',
              name: '灵川县'
            },
            {
              value: '450324',
              name: '全州县'
            },
            {
              value: '450325',
              name: '兴安县'
            },
            {
              value: '450326',
              name: '永福县'
            },
            {
              value: '450327',
              name: '灌阳县'
            },
            {
              value: '450328',
              name: '龙胜各族自治县'
            },
            {
              value: '450329',
              name: '资源县'
            },
            {
              value: '450330',
              name: '平乐县'
            },
            {
              value: '450332',
              name: '恭城瑶族自治县'
            },
            {
              value: '450381',
              name: '荔浦市'
            }
          ]
        },
        {
          value: '450400',
          name: '梧州市',
          children: [
            {
              value: '450403',
              name: '万秀区'
            },
            {
              value: '450405',
              name: '长洲区'
            },
            {
              value: '450406',
              name: '龙圩区'
            },
            {
              value: '450421',
              name: '苍梧县'
            },
            {
              value: '450422',
              name: '藤县'
            },
            {
              value: '450423',
              name: '蒙山县'
            },
            {
              value: '450481',
              name: '岑溪市'
            }
          ]
        },
        {
          value: '450500',
          name: '北海市',
          children: [
            {
              value: '450502',
              name: '海城区'
            },
            {
              value: '450503',
              name: '银海区'
            },
            {
              value: '450512',
              name: '铁山港区'
            },
            {
              value: '450521',
              name: '合浦县'
            }
          ]
        },
        {
          value: '450600',
          name: '防城港市',
          children: [
            {
              value: '450602',
              name: '港口区'
            },
            {
              value: '450603',
              name: '防城区'
            },
            {
              value: '450621',
              name: '上思县'
            },
            {
              value: '450681',
              name: '东兴市'
            }
          ]
        },
        {
          value: '450700',
          name: '钦州市',
          children: [
            {
              value: '450702',
              name: '钦南区'
            },
            {
              value: '450703',
              name: '钦北区'
            },
            {
              value: '450721',
              name: '灵山县'
            },
            {
              value: '450722',
              name: '浦北县'
            }
          ]
        },
        {
          value: '450800',
          name: '贵港市',
          children: [
            {
              value: '450802',
              name: '港北区'
            },
            {
              value: '450803',
              name: '港南区'
            },
            {
              value: '450804',
              name: '覃塘区'
            },
            {
              value: '450821',
              name: '平南县'
            },
            {
              value: '450881',
              name: '桂平市'
            }
          ]
        },
        {
          value: '450900',
          name: '玉林市',
          children: [
            {
              value: '450902',
              name: '玉州区'
            },
            {
              value: '450903',
              name: '福绵区'
            },
            {
              value: '450921',
              name: '容县'
            },
            {
              value: '450922',
              name: '陆川县'
            },
            {
              value: '450923',
              name: '博白县'
            },
            {
              value: '450924',
              name: '兴业县'
            },
            {
              value: '450981',
              name: '北流市'
            }
          ]
        },
        {
          value: '451000',
          name: '百色市',
          children: [
            {
              value: '451002',
              name: '右江区'
            },
            {
              value: '451003',
              name: '田阳区'
            },
            {
              value: '451022',
              name: '田东县'
            },
            {
              value: '451023',
              name: '平果县'
            },
            {
              value: '451024',
              name: '德保县'
            },
            {
              value: '451026',
              name: '那坡县'
            },
            {
              value: '451027',
              name: '凌云县'
            },
            {
              value: '451028',
              name: '乐业县'
            },
            {
              value: '451029',
              name: '田林县'
            },
            {
              value: '451030',
              name: '西林县'
            },
            {
              value: '451031',
              name: '隆林各族自治县'
            },
            {
              value: '451081',
              name: '靖西市'
            }
          ]
        },
        {
          value: '451100',
          name: '贺州市',
          children: [
            {
              value: '451102',
              name: '八步区'
            },
            {
              value: '451103',
              name: '平桂区'
            },
            {
              value: '451121',
              name: '昭平县'
            },
            {
              value: '451122',
              name: '钟山县'
            },
            {
              value: '451123',
              name: '富川瑶族自治县'
            }
          ]
        },
        {
          value: '451200',
          name: '河池市',
          children: [
            {
              value: '451202',
              name: '金城江区'
            },
            {
              value: '451203',
              name: '宜州区'
            },
            {
              value: '451221',
              name: '南丹县'
            },
            {
              value: '451222',
              name: '天峨县'
            },
            {
              value: '451223',
              name: '凤山县'
            },
            {
              value: '451224',
              name: '东兰县'
            },
            {
              value: '451225',
              name: '罗城仫佬族自治县'
            },
            {
              value: '451226',
              name: '环江毛南族自治县'
            },
            {
              value: '451227',
              name: '巴马瑶族自治县'
            },
            {
              value: '451228',
              name: '都安瑶族自治县'
            },
            {
              value: '451229',
              name: '大化瑶族自治县'
            }
          ]
        },
        {
          value: '451300',
          name: '来宾市',
          children: [
            {
              value: '451302',
              name: '兴宾区'
            },
            {
              value: '451321',
              name: '忻城县'
            },
            {
              value: '451322',
              name: '象州县'
            },
            {
              value: '451323',
              name: '武宣县'
            },
            {
              value: '451324',
              name: '金秀瑶族自治县'
            },
            {
              value: '451381',
              name: '合山市'
            }
          ]
        },
        {
          value: '451400',
          name: '崇左市',
          children: [
            {
              value: '451402',
              name: '江州区'
            },
            {
              value: '451421',
              name: '扶绥县'
            },
            {
              value: '451422',
              name: '宁明县'
            },
            {
              value: '451423',
              name: '龙州县'
            },
            {
              value: '451424',
              name: '大新县'
            },
            {
              value: '451425',
              name: '天等县'
            },
            {
              value: '451481',
              name: '凭祥市'
            }
          ]
        }
      ]
    },
    {
      value: '460000',
      name: '海南省',
      children: [
        {
          value: '460100',
          name: '海口市',
          children: [
            {
              value: '460105',
              name: '秀英区'
            },
            {
              value: '460106',
              name: '龙华区'
            },
            {
              value: '460107',
              name: '琼山区'
            },
            {
              value: '460108',
              name: '美兰区'
            }
          ]
        },
        {
          value: '460200',
          name: '三亚市',
          children: [
            {
              value: '460202',
              name: '海棠区'
            },
            {
              value: '460203',
              name: '吉阳区'
            },
            {
              value: '460204',
              name: '天涯区'
            },
            {
              value: '460205',
              name: '崖州区'
            }
          ]
        },
        {
          value: '460300',
          name: '三沙市',
          children: [
            {
              value: '460321',
              name: '西沙群岛'
            },
            {
              value: '460322',
              name: '南沙群岛'
            },
            {
              value: '460323',
              name: '中沙群岛的岛礁及其海域'
            }
          ]
        },
        {
          value: '460400',
          name: '儋州市',
          children: [
            {
              value: '460400',
              name: '儋州市'
            }
          ]
        },
        {
          value: '469000',
          name: '省直辖县级行政区划',
          children: [
            {
              value: '469001',
              name: '五指山市'
            },
            {
              value: '469002',
              name: '琼海市'
            },
            {
              value: '469005',
              name: '文昌市'
            },
            {
              value: '469006',
              name: '万宁市'
            },
            {
              value: '469007',
              name: '东方市'
            },
            {
              value: '469021',
              name: '定安县'
            },
            {
              value: '469022',
              name: '屯昌县'
            },
            {
              value: '469023',
              name: '澄迈县'
            },
            {
              value: '469024',
              name: '临高县'
            },
            {
              value: '469025',
              name: '白沙黎族自治县'
            },
            {
              value: '469026',
              name: '昌江黎族自治县'
            },
            {
              value: '469027',
              name: '乐东黎族自治县'
            },
            {
              value: '469028',
              name: '陵水黎族自治县'
            },
            {
              value: '469029',
              name: '保亭黎族苗族自治县'
            },
            {
              value: '469030',
              name: '琼中黎族苗族自治县'
            }
          ]
        }
      ]
    },
    {
      value: '500000',
      name: '重庆市',
      children: [
        {
          value: '500100',
          name: '市辖区',
          children: [
            {
              value: '500101',
              name: '万州区'
            },
            {
              value: '500102',
              name: '涪陵区'
            },
            {
              value: '500103',
              name: '渝中区'
            },
            {
              value: '500104',
              name: '大渡口区'
            },
            {
              value: '500105',
              name: '江北区'
            },
            {
              value: '500106',
              name: '沙坪坝区'
            },
            {
              value: '500107',
              name: '九龙坡区'
            },
            {
              value: '500108',
              name: '南岸区'
            },
            {
              value: '500109',
              name: '北碚区'
            },
            {
              value: '500110',
              name: '綦江区'
            },
            {
              value: '500111',
              name: '大足区'
            },
            {
              value: '500112',
              name: '渝北区'
            },
            {
              value: '500113',
              name: '巴南区'
            },
            {
              value: '500114',
              name: '黔江区'
            },
            {
              value: '500115',
              name: '长寿区'
            },
            {
              value: '500116',
              name: '江津区'
            },
            {
              value: '500117',
              name: '合川区'
            },
            {
              value: '500118',
              name: '永川区'
            },
            {
              value: '500119',
              name: '南川区'
            },
            {
              value: '500120',
              name: '璧山区'
            },
            {
              value: '500151',
              name: '铜梁区'
            },
            {
              value: '500152',
              name: '潼南区'
            },
            {
              value: '500153',
              name: '荣昌区'
            },
            {
              value: '500154',
              name: '开州区'
            },
            {
              value: '500155',
              name: '梁平区'
            },
            {
              value: '500156',
              name: '武隆区'
            }
          ]
        },
        {
          value: '500200',
          name: '县',
          children: [
            {
              value: '500229',
              name: '城口县'
            },
            {
              value: '500230',
              name: '丰都县'
            },
            {
              value: '500231',
              name: '垫江县'
            },
            {
              value: '500233',
              name: '忠县'
            },
            {
              value: '500235',
              name: '云阳县'
            },
            {
              value: '500236',
              name: '奉节县'
            },
            {
              value: '500237',
              name: '巫山县'
            },
            {
              value: '500238',
              name: '巫溪县'
            },
            {
              value: '500240',
              name: '石柱土家族自治县'
            },
            {
              value: '500241',
              name: '秀山土家族苗族自治县'
            },
            {
              value: '500242',
              name: '酉阳土家族苗族自治县'
            },
            {
              value: '500243',
              name: '彭水苗族土家族自治县'
            }
          ]
        }
      ]
    },
    {
      value: '510000',
      name: '四川省',
      children: [
        {
          value: '510100',
          name: '成都市',
          children: [
            {
              value: '510104',
              name: '锦江区'
            },
            {
              value: '510105',
              name: '青羊区'
            },
            {
              value: '510106',
              name: '金牛区'
            },
            {
              value: '510107',
              name: '武侯区'
            },
            {
              value: '510108',
              name: '成华区'
            },
            {
              value: '510112',
              name: '龙泉驿区'
            },
            {
              value: '510113',
              name: '青白江区'
            },
            {
              value: '510114',
              name: '新都区'
            },
            {
              value: '510115',
              name: '温江区'
            },
            {
              value: '510116',
              name: '双流区'
            },
            {
              value: '510117',
              name: '郫都区'
            },
            {
              value: '510121',
              name: '金堂县'
            },
            {
              value: '510129',
              name: '大邑县'
            },
            {
              value: '510131',
              name: '蒲江县'
            },
            {
              value: '510132',
              name: '新津县'
            },
            {
              value: '510181',
              name: '都江堰市'
            },
            {
              value: '510182',
              name: '彭州市'
            },
            {
              value: '510183',
              name: '邛崃市'
            },
            {
              value: '510184',
              name: '崇州市'
            },
            {
              value: '510185',
              name: '简阳市'
            }
          ]
        },
        {
          value: '510300',
          name: '自贡市',
          children: [
            {
              value: '510302',
              name: '自流井区'
            },
            {
              value: '510303',
              name: '贡井区'
            },
            {
              value: '510304',
              name: '大安区'
            },
            {
              value: '510311',
              name: '沿滩区'
            },
            {
              value: '510321',
              name: '荣县'
            },
            {
              value: '510322',
              name: '富顺县'
            }
          ]
        },
        {
          value: '510400',
          name: '攀枝花市',
          children: [
            {
              value: '510402',
              name: '东区'
            },
            {
              value: '510403',
              name: '西区'
            },
            {
              value: '510411',
              name: '仁和区'
            },
            {
              value: '510421',
              name: '米易县'
            },
            {
              value: '510422',
              name: '盐边县'
            }
          ]
        },
        {
          value: '510500',
          name: '泸州市',
          children: [
            {
              value: '510502',
              name: '江阳区'
            },
            {
              value: '510503',
              name: '纳溪区'
            },
            {
              value: '510504',
              name: '龙马潭区'
            },
            {
              value: '510521',
              name: '泸县'
            },
            {
              value: '510522',
              name: '合江县'
            },
            {
              value: '510524',
              name: '叙永县'
            },
            {
              value: '510525',
              name: '古蔺县'
            }
          ]
        },
        {
          value: '510600',
          name: '德阳市',
          children: [
            {
              value: '510603',
              name: '旌阳区'
            },
            {
              value: '510604',
              name: '罗江区'
            },
            {
              value: '510623',
              name: '中江县'
            },
            {
              value: '510681',
              name: '广汉市'
            },
            {
              value: '510682',
              name: '什邡市'
            },
            {
              value: '510683',
              name: '绵竹市'
            }
          ]
        },
        {
          value: '510700',
          name: '绵阳市',
          children: [
            {
              value: '510703',
              name: '涪城区'
            },
            {
              value: '510704',
              name: '游仙区'
            },
            {
              value: '510705',
              name: '安州区'
            },
            {
              value: '510722',
              name: '三台县'
            },
            {
              value: '510723',
              name: '盐亭县'
            },
            {
              value: '510725',
              name: '梓潼县'
            },
            {
              value: '510726',
              name: '北川羌族自治县'
            },
            {
              value: '510727',
              name: '平武县'
            },
            {
              value: '510781',
              name: '江油市'
            }
          ]
        },
        {
          value: '510800',
          name: '广元市',
          children: [
            {
              value: '510802',
              name: '利州区'
            },
            {
              value: '510811',
              name: '昭化区'
            },
            {
              value: '510812',
              name: '朝天区'
            },
            {
              value: '510821',
              name: '旺苍县'
            },
            {
              value: '510822',
              name: '青川县'
            },
            {
              value: '510823',
              name: '剑阁县'
            },
            {
              value: '510824',
              name: '苍溪县'
            }
          ]
        },
        {
          value: '510900',
          name: '遂宁市',
          children: [
            {
              value: '510903',
              name: '船山区'
            },
            {
              value: '510904',
              name: '安居区'
            },
            {
              value: '510921',
              name: '蓬溪县'
            },
            {
              value: '510923',
              name: '大英县'
            },
            {
              value: '510981',
              name: '射洪市'
            }
          ]
        },
        {
          value: '511000',
          name: '内江市',
          children: [
            {
              value: '511002',
              name: '市中区'
            },
            {
              value: '511011',
              name: '东兴区'
            },
            {
              value: '511024',
              name: '威远县'
            },
            {
              value: '511025',
              name: '资中县'
            },
            {
              value: '511071',
              name: '内江经济开发区'
            },
            {
              value: '511083',
              name: '隆昌市'
            }
          ]
        },
        {
          value: '511100',
          name: '乐山市',
          children: [
            {
              value: '511102',
              name: '市中区'
            },
            {
              value: '511111',
              name: '沙湾区'
            },
            {
              value: '511112',
              name: '五通桥区'
            },
            {
              value: '511113',
              name: '金口河区'
            },
            {
              value: '511123',
              name: '犍为县'
            },
            {
              value: '511124',
              name: '井研县'
            },
            {
              value: '511126',
              name: '夹江县'
            },
            {
              value: '511129',
              name: '沐川县'
            },
            {
              value: '511132',
              name: '峨边彝族自治县'
            },
            {
              value: '511133',
              name: '马边彝族自治县'
            },
            {
              value: '511181',
              name: '峨眉山市'
            }
          ]
        },
        {
          value: '511300',
          name: '南充市',
          children: [
            {
              value: '511302',
              name: '顺庆区'
            },
            {
              value: '511303',
              name: '高坪区'
            },
            {
              value: '511304',
              name: '嘉陵区'
            },
            {
              value: '511321',
              name: '南部县'
            },
            {
              value: '511322',
              name: '营山县'
            },
            {
              value: '511323',
              name: '蓬安县'
            },
            {
              value: '511324',
              name: '仪陇县'
            },
            {
              value: '511325',
              name: '西充县'
            },
            {
              value: '511381',
              name: '阆中市'
            }
          ]
        },
        {
          value: '511400',
          name: '眉山市',
          children: [
            {
              value: '511402',
              name: '东坡区'
            },
            {
              value: '511403',
              name: '彭山区'
            },
            {
              value: '511421',
              name: '仁寿县'
            },
            {
              value: '511423',
              name: '洪雅县'
            },
            {
              value: '511424',
              name: '丹棱县'
            },
            {
              value: '511425',
              name: '青神县'
            }
          ]
        },
        {
          value: '511500',
          name: '宜宾市',
          children: [
            {
              value: '511502',
              name: '翠屏区'
            },
            {
              value: '511503',
              name: '南溪区'
            },
            {
              value: '511504',
              name: '叙州区'
            },
            {
              value: '511523',
              name: '江安县'
            },
            {
              value: '511524',
              name: '长宁县'
            },
            {
              value: '511525',
              name: '高县'
            },
            {
              value: '511526',
              name: '珙县'
            },
            {
              value: '511527',
              name: '筠连县'
            },
            {
              value: '511528',
              name: '兴文县'
            },
            {
              value: '511529',
              name: '屏山县'
            }
          ]
        },
        {
          value: '511600',
          name: '广安市',
          children: [
            {
              value: '511602',
              name: '广安区'
            },
            {
              value: '511603',
              name: '前锋区'
            },
            {
              value: '511621',
              name: '岳池县'
            },
            {
              value: '511622',
              name: '武胜县'
            },
            {
              value: '511623',
              name: '邻水县'
            },
            {
              value: '511681',
              name: '华蓥市'
            }
          ]
        },
        {
          value: '511700',
          name: '达州市',
          children: [
            {
              value: '511702',
              name: '通川区'
            },
            {
              value: '511703',
              name: '达川区'
            },
            {
              value: '511722',
              name: '宣汉县'
            },
            {
              value: '511723',
              name: '开江县'
            },
            {
              value: '511724',
              name: '大竹县'
            },
            {
              value: '511725',
              name: '渠县'
            },
            {
              value: '511771',
              name: '达州经济开发区'
            },
            {
              value: '511781',
              name: '万源市'
            }
          ]
        },
        {
          value: '511800',
          name: '雅安市',
          children: [
            {
              value: '511802',
              name: '雨城区'
            },
            {
              value: '511803',
              name: '名山区'
            },
            {
              value: '511822',
              name: '荥经县'
            },
            {
              value: '511823',
              name: '汉源县'
            },
            {
              value: '511824',
              name: '石棉县'
            },
            {
              value: '511825',
              name: '天全县'
            },
            {
              value: '511826',
              name: '芦山县'
            },
            {
              value: '511827',
              name: '宝兴县'
            }
          ]
        },
        {
          value: '511900',
          name: '巴中市',
          children: [
            {
              value: '511902',
              name: '巴州区'
            },
            {
              value: '511903',
              name: '恩阳区'
            },
            {
              value: '511921',
              name: '通江县'
            },
            {
              value: '511922',
              name: '南江县'
            },
            {
              value: '511923',
              name: '平昌县'
            },
            {
              value: '511971',
              name: '巴中经济开发区'
            }
          ]
        },
        {
          value: '512000',
          name: '资阳市',
          children: [
            {
              value: '512002',
              name: '雁江区'
            },
            {
              value: '512021',
              name: '安岳县'
            },
            {
              value: '512022',
              name: '乐至县'
            }
          ]
        },
        {
          value: '513200',
          name: '阿坝藏族羌族自治州',
          children: [
            {
              value: '513201',
              name: '马尔康市'
            },
            {
              value: '513221',
              name: '汶川县'
            },
            {
              value: '513222',
              name: '理县'
            },
            {
              value: '513223',
              name: '茂县'
            },
            {
              value: '513224',
              name: '松潘县'
            },
            {
              value: '513225',
              name: '九寨沟县'
            },
            {
              value: '513226',
              name: '金川县'
            },
            {
              value: '513227',
              name: '小金县'
            },
            {
              value: '513228',
              name: '黑水县'
            },
            {
              value: '513230',
              name: '壤塘县'
            },
            {
              value: '513231',
              name: '阿坝县'
            },
            {
              value: '513232',
              name: '若尔盖县'
            },
            {
              value: '513233',
              name: '红原县'
            }
          ]
        },
        {
          value: '513300',
          name: '甘孜藏族自治州',
          children: [
            {
              value: '513301',
              name: '康定市'
            },
            {
              value: '513322',
              name: '泸定县'
            },
            {
              value: '513323',
              name: '丹巴县'
            },
            {
              value: '513324',
              name: '九龙县'
            },
            {
              value: '513325',
              name: '雅江县'
            },
            {
              value: '513326',
              name: '道孚县'
            },
            {
              value: '513327',
              name: '炉霍县'
            },
            {
              value: '513328',
              name: '甘孜县'
            },
            {
              value: '513329',
              name: '新龙县'
            },
            {
              value: '513330',
              name: '德格县'
            },
            {
              value: '513331',
              name: '白玉县'
            },
            {
              value: '513332',
              name: '石渠县'
            },
            {
              value: '513333',
              name: '色达县'
            },
            {
              value: '513334',
              name: '理塘县'
            },
            {
              value: '513335',
              name: '巴塘县'
            },
            {
              value: '513336',
              name: '乡城县'
            },
            {
              value: '513337',
              name: '稻城县'
            },
            {
              value: '513338',
              name: '得荣县'
            }
          ]
        },
        {
          value: '513400',
          name: '凉山彝族自治州',
          children: [
            {
              value: '513401',
              name: '西昌市'
            },
            {
              value: '513422',
              name: '木里藏族自治县'
            },
            {
              value: '513423',
              name: '盐源县'
            },
            {
              value: '513424',
              name: '德昌县'
            },
            {
              value: '513425',
              name: '会理县'
            },
            {
              value: '513426',
              name: '会东县'
            },
            {
              value: '513427',
              name: '宁南县'
            },
            {
              value: '513428',
              name: '普格县'
            },
            {
              value: '513429',
              name: '布拖县'
            },
            {
              value: '513430',
              name: '金阳县'
            },
            {
              value: '513431',
              name: '昭觉县'
            },
            {
              value: '513432',
              name: '喜德县'
            },
            {
              value: '513433',
              name: '冕宁县'
            },
            {
              value: '513434',
              name: '越西县'
            },
            {
              value: '513435',
              name: '甘洛县'
            },
            {
              value: '513436',
              name: '美姑县'
            },
            {
              value: '513437',
              name: '雷波县'
            }
          ]
        }
      ]
    },
    {
      value: '520000',
      name: '贵州省',
      children: [
        {
          value: '520100',
          name: '贵阳市',
          children: [
            {
              value: '520102',
              name: '南明区'
            },
            {
              value: '520103',
              name: '云岩区'
            },
            {
              value: '520111',
              name: '花溪区'
            },
            {
              value: '520112',
              name: '乌当区'
            },
            {
              value: '520113',
              name: '白云区'
            },
            {
              value: '520115',
              name: '观山湖区'
            },
            {
              value: '520121',
              name: '开阳县'
            },
            {
              value: '520122',
              name: '息烽县'
            },
            {
              value: '520123',
              name: '修文县'
            },
            {
              value: '520181',
              name: '清镇市'
            }
          ]
        },
        {
          value: '520200',
          name: '六盘水市',
          children: [
            {
              value: '520201',
              name: '钟山区'
            },
            {
              value: '520203',
              name: '六枝特区'
            },
            {
              value: '520221',
              name: '水城县'
            },
            {
              value: '520281',
              name: '盘州市'
            }
          ]
        },
        {
          value: '520300',
          name: '遵义市',
          children: [
            {
              value: '520302',
              name: '红花岗区'
            },
            {
              value: '520303',
              name: '汇川区'
            },
            {
              value: '520304',
              name: '播州区'
            },
            {
              value: '520322',
              name: '桐梓县'
            },
            {
              value: '520323',
              name: '绥阳县'
            },
            {
              value: '520324',
              name: '正安县'
            },
            {
              value: '520325',
              name: '道真仡佬族苗族自治县'
            },
            {
              value: '520326',
              name: '务川仡佬族苗族自治县'
            },
            {
              value: '520327',
              name: '凤冈县'
            },
            {
              value: '520328',
              name: '湄潭县'
            },
            {
              value: '520329',
              name: '余庆县'
            },
            {
              value: '520330',
              name: '习水县'
            },
            {
              value: '520381',
              name: '赤水市'
            },
            {
              value: '520382',
              name: '仁怀市'
            }
          ]
        },
        {
          value: '520400',
          name: '安顺市',
          children: [
            {
              value: '520402',
              name: '西秀区'
            },
            {
              value: '520403',
              name: '平坝区'
            },
            {
              value: '520422',
              name: '普定县'
            },
            {
              value: '520423',
              name: '镇宁布依族苗族自治县'
            },
            {
              value: '520424',
              name: '关岭布依族苗族自治县'
            },
            {
              value: '520425',
              name: '紫云苗族布依族自治县'
            }
          ]
        },
        {
          value: '520500',
          name: '毕节市',
          children: [
            {
              value: '520502',
              name: '七星关区'
            },
            {
              value: '520521',
              name: '大方县'
            },
            {
              value: '520522',
              name: '黔西县'
            },
            {
              value: '520523',
              name: '金沙县'
            },
            {
              value: '520524',
              name: '织金县'
            },
            {
              value: '520525',
              name: '纳雍县'
            },
            {
              value: '520526',
              name: '威宁彝族回族苗族自治县'
            },
            {
              value: '520527',
              name: '赫章县'
            }
          ]
        },
        {
          value: '520600',
          name: '铜仁市',
          children: [
            {
              value: '520602',
              name: '碧江区'
            },
            {
              value: '520603',
              name: '万山区'
            },
            {
              value: '520621',
              name: '江口县'
            },
            {
              value: '520622',
              name: '玉屏侗族自治县'
            },
            {
              value: '520623',
              name: '石阡县'
            },
            {
              value: '520624',
              name: '思南县'
            },
            {
              value: '520625',
              name: '印江土家族苗族自治县'
            },
            {
              value: '520626',
              name: '德江县'
            },
            {
              value: '520627',
              name: '沿河土家族自治县'
            },
            {
              value: '520628',
              name: '松桃苗族自治县'
            }
          ]
        },
        {
          value: '522300',
          name: '黔西南布依族苗族自治州',
          children: [
            {
              value: '522301',
              name: '兴义市'
            },
            {
              value: '522302',
              name: '兴仁市'
            },
            {
              value: '522323',
              name: '普安县'
            },
            {
              value: '522324',
              name: '晴隆县'
            },
            {
              value: '522325',
              name: '贞丰县'
            },
            {
              value: '522326',
              name: '望谟县'
            },
            {
              value: '522327',
              name: '册亨县'
            },
            {
              value: '522328',
              name: '安龙县'
            }
          ]
        },
        {
          value: '522600',
          name: '黔东南苗族侗族自治州',
          children: [
            {
              value: '522601',
              name: '凯里市'
            },
            {
              value: '522622',
              name: '黄平县'
            },
            {
              value: '522623',
              name: '施秉县'
            },
            {
              value: '522624',
              name: '三穗县'
            },
            {
              value: '522625',
              name: '镇远县'
            },
            {
              value: '522626',
              name: '岑巩县'
            },
            {
              value: '522627',
              name: '天柱县'
            },
            {
              value: '522628',
              name: '锦屏县'
            },
            {
              value: '522629',
              name: '剑河县'
            },
            {
              value: '522630',
              name: '台江县'
            },
            {
              value: '522631',
              name: '黎平县'
            },
            {
              value: '522632',
              name: '榕江县'
            },
            {
              value: '522633',
              name: '从江县'
            },
            {
              value: '522634',
              name: '雷山县'
            },
            {
              value: '522635',
              name: '麻江县'
            },
            {
              value: '522636',
              name: '丹寨县'
            }
          ]
        },
        {
          value: '522700',
          name: '黔南布依族苗族自治州',
          children: [
            {
              value: '522701',
              name: '都匀市'
            },
            {
              value: '522702',
              name: '福泉市'
            },
            {
              value: '522722',
              name: '荔波县'
            },
            {
              value: '522723',
              name: '贵定县'
            },
            {
              value: '522725',
              name: '瓮安县'
            },
            {
              value: '522726',
              name: '独山县'
            },
            {
              value: '522727',
              name: '平塘县'
            },
            {
              value: '522728',
              name: '罗甸县'
            },
            {
              value: '522729',
              name: '长顺县'
            },
            {
              value: '522730',
              name: '龙里县'
            },
            {
              value: '522731',
              name: '惠水县'
            },
            {
              value: '522732',
              name: '三都水族自治县'
            }
          ]
        }
      ]
    },
    {
      value: '530000',
      name: '云南省',
      children: [
        {
          value: '530100',
          name: '昆明市',
          children: [
            {
              value: '530102',
              name: '五华区'
            },
            {
              value: '530103',
              name: '盘龙区'
            },
            {
              value: '530111',
              name: '官渡区'
            },
            {
              value: '530112',
              name: '西山区'
            },
            {
              value: '530113',
              name: '东川区'
            },
            {
              value: '530114',
              name: '呈贡区'
            },
            {
              value: '530115',
              name: '晋宁区'
            },
            {
              value: '530124',
              name: '富民县'
            },
            {
              value: '530125',
              name: '宜良县'
            },
            {
              value: '530126',
              name: '石林彝族自治县'
            },
            {
              value: '530127',
              name: '嵩明县'
            },
            {
              value: '530128',
              name: '禄劝彝族苗族自治县'
            },
            {
              value: '530129',
              name: '寻甸回族彝族自治县'
            },
            {
              value: '530181',
              name: '安宁市'
            }
          ]
        },
        {
          value: '530300',
          name: '曲靖市',
          children: [
            {
              value: '530302',
              name: '麒麟区'
            },
            {
              value: '530303',
              name: '沾益区'
            },
            {
              value: '530304',
              name: '马龙区'
            },
            {
              value: '530322',
              name: '陆良县'
            },
            {
              value: '530323',
              name: '师宗县'
            },
            {
              value: '530324',
              name: '罗平县'
            },
            {
              value: '530325',
              name: '富源县'
            },
            {
              value: '530326',
              name: '会泽县'
            },
            {
              value: '530381',
              name: '宣威市'
            }
          ]
        },
        {
          value: '530400',
          name: '玉溪市',
          children: [
            {
              value: '530402',
              name: '红塔区'
            },
            {
              value: '530403',
              name: '江川区'
            },
            {
              value: '530422',
              name: '澄江县'
            },
            {
              value: '530423',
              name: '通海县'
            },
            {
              value: '530424',
              name: '华宁县'
            },
            {
              value: '530425',
              name: '易门县'
            },
            {
              value: '530426',
              name: '峨山彝族自治县'
            },
            {
              value: '530427',
              name: '新平彝族傣族自治县'
            },
            {
              value: '530428',
              name: '元江哈尼族彝族傣族自治县'
            }
          ]
        },
        {
          value: '530500',
          name: '保山市',
          children: [
            {
              value: '530502',
              name: '隆阳区'
            },
            {
              value: '530521',
              name: '施甸县'
            },
            {
              value: '530523',
              name: '龙陵县'
            },
            {
              value: '530524',
              name: '昌宁县'
            },
            {
              value: '530581',
              name: '腾冲市'
            }
          ]
        },
        {
          value: '530600',
          name: '昭通市',
          children: [
            {
              value: '530602',
              name: '昭阳区'
            },
            {
              value: '530621',
              name: '鲁甸县'
            },
            {
              value: '530622',
              name: '巧家县'
            },
            {
              value: '530623',
              name: '盐津县'
            },
            {
              value: '530624',
              name: '大关县'
            },
            {
              value: '530625',
              name: '永善县'
            },
            {
              value: '530626',
              name: '绥江县'
            },
            {
              value: '530627',
              name: '镇雄县'
            },
            {
              value: '530628',
              name: '彝良县'
            },
            {
              value: '530629',
              name: '威信县'
            },
            {
              value: '530681',
              name: '水富市'
            }
          ]
        },
        {
          value: '530700',
          name: '丽江市',
          children: [
            {
              value: '530702',
              name: '古城区'
            },
            {
              value: '530721',
              name: '玉龙纳西族自治县'
            },
            {
              value: '530722',
              name: '永胜县'
            },
            {
              value: '530723',
              name: '华坪县'
            },
            {
              value: '530724',
              name: '宁蒗彝族自治县'
            }
          ]
        },
        {
          value: '530800',
          name: '普洱市',
          children: [
            {
              value: '530802',
              name: '思茅区'
            },
            {
              value: '530821',
              name: '宁洱哈尼族彝族自治县'
            },
            {
              value: '530822',
              name: '墨江哈尼族自治县'
            },
            {
              value: '530823',
              name: '景东彝族自治县'
            },
            {
              value: '530824',
              name: '景谷傣族彝族自治县'
            },
            {
              value: '530825',
              name: '镇沅彝族哈尼族拉祜族自治县'
            },
            {
              value: '530826',
              name: '江城哈尼族彝族自治县'
            },
            {
              value: '530827',
              name: '孟连傣族拉祜族佤族自治县'
            },
            {
              value: '530828',
              name: '澜沧拉祜族自治县'
            },
            {
              value: '530829',
              name: '西盟佤族自治县'
            }
          ]
        },
        {
          value: '530900',
          name: '临沧市',
          children: [
            {
              value: '530902',
              name: '临翔区'
            },
            {
              value: '530921',
              name: '凤庆县'
            },
            {
              value: '530922',
              name: '云县'
            },
            {
              value: '530923',
              name: '永德县'
            },
            {
              value: '530924',
              name: '镇康县'
            },
            {
              value: '530925',
              name: '双江拉祜族佤族布朗族傣族自治县'
            },
            {
              value: '530926',
              name: '耿马傣族佤族自治县'
            },
            {
              value: '530927',
              name: '沧源佤族自治县'
            }
          ]
        },
        {
          value: '532300',
          name: '楚雄彝族自治州',
          children: [
            {
              value: '532301',
              name: '楚雄市'
            },
            {
              value: '532322',
              name: '双柏县'
            },
            {
              value: '532323',
              name: '牟定县'
            },
            {
              value: '532324',
              name: '南华县'
            },
            {
              value: '532325',
              name: '姚安县'
            },
            {
              value: '532326',
              name: '大姚县'
            },
            {
              value: '532327',
              name: '永仁县'
            },
            {
              value: '532328',
              name: '元谋县'
            },
            {
              value: '532329',
              name: '武定县'
            },
            {
              value: '532331',
              name: '禄丰县'
            }
          ]
        },
        {
          value: '532500',
          name: '红河哈尼族彝族自治州',
          children: [
            {
              value: '532501',
              name: '个旧市'
            },
            {
              value: '532502',
              name: '开远市'
            },
            {
              value: '532503',
              name: '蒙自市'
            },
            {
              value: '532504',
              name: '弥勒市'
            },
            {
              value: '532523',
              name: '屏边苗族自治县'
            },
            {
              value: '532524',
              name: '建水县'
            },
            {
              value: '532525',
              name: '石屏县'
            },
            {
              value: '532527',
              name: '泸西县'
            },
            {
              value: '532528',
              name: '元阳县'
            },
            {
              value: '532529',
              name: '红河县'
            },
            {
              value: '532530',
              name: '金平苗族瑶族傣族自治县'
            },
            {
              value: '532531',
              name: '绿春县'
            },
            {
              value: '532532',
              name: '河口瑶族自治县'
            }
          ]
        },
        {
          value: '532600',
          name: '文山壮族苗族自治州',
          children: [
            {
              value: '532601',
              name: '文山市'
            },
            {
              value: '532622',
              name: '砚山县'
            },
            {
              value: '532623',
              name: '西畴县'
            },
            {
              value: '532624',
              name: '麻栗坡县'
            },
            {
              value: '532625',
              name: '马关县'
            },
            {
              value: '532626',
              name: '丘北县'
            },
            {
              value: '532627',
              name: '广南县'
            },
            {
              value: '532628',
              name: '富宁县'
            }
          ]
        },
        {
          value: '532800',
          name: '西双版纳傣族自治州',
          children: [
            {
              value: '532801',
              name: '景洪市'
            },
            {
              value: '532822',
              name: '勐海县'
            },
            {
              value: '532823',
              name: '勐腊县'
            }
          ]
        },
        {
          value: '532900',
          name: '大理白族自治州',
          children: [
            {
              value: '532901',
              name: '大理市'
            },
            {
              value: '532922',
              name: '漾濞彝族自治县'
            },
            {
              value: '532923',
              name: '祥云县'
            },
            {
              value: '532924',
              name: '宾川县'
            },
            {
              value: '532925',
              name: '弥渡县'
            },
            {
              value: '532926',
              name: '南涧彝族自治县'
            },
            {
              value: '532927',
              name: '巍山彝族回族自治县'
            },
            {
              value: '532928',
              name: '永平县'
            },
            {
              value: '532929',
              name: '云龙县'
            },
            {
              value: '532930',
              name: '洱源县'
            },
            {
              value: '532931',
              name: '剑川县'
            },
            {
              value: '532932',
              name: '鹤庆县'
            }
          ]
        },
        {
          value: '533100',
          name: '德宏傣族景颇族自治州',
          children: [
            {
              value: '533102',
              name: '瑞丽市'
            },
            {
              value: '533103',
              name: '芒市'
            },
            {
              value: '533122',
              name: '梁河县'
            },
            {
              value: '533123',
              name: '盈江县'
            },
            {
              value: '533124',
              name: '陇川县'
            }
          ]
        },
        {
          value: '533300',
          name: '怒江傈僳族自治州',
          children: [
            {
              value: '533301',
              name: '泸水市'
            },
            {
              value: '533323',
              name: '福贡县'
            },
            {
              value: '533324',
              name: '贡山独龙族怒族自治县'
            },
            {
              value: '533325',
              name: '兰坪白族普米族自治县'
            }
          ]
        },
        {
          value: '533400',
          name: '迪庆藏族自治州',
          children: [
            {
              value: '533401',
              name: '香格里拉市'
            },
            {
              value: '533422',
              name: '德钦县'
            },
            {
              value: '533423',
              name: '维西傈僳族自治县'
            }
          ]
        }
      ]
    },
    {
      value: '540000',
      name: '西藏自治区',
      children: [
        {
          value: '540100',
          name: '拉萨市',
          children: [
            {
              value: '540102',
              name: '城关区'
            },
            {
              value: '540103',
              name: '堆龙德庆区'
            },
            {
              value: '540104',
              name: '达孜区'
            },
            {
              value: '540121',
              name: '林周县'
            },
            {
              value: '540122',
              name: '当雄县'
            },
            {
              value: '540123',
              name: '尼木县'
            },
            {
              value: '540124',
              name: '曲水县'
            },
            {
              value: '540127',
              name: '墨竹工卡县'
            },
            {
              value: '540171',
              name: '格尔木藏青工业园区'
            },
            {
              value: '540172',
              name: '拉萨经济技术开发区'
            },
            {
              value: '540173',
              name: '西藏文化旅游创意园区'
            },
            {
              value: '540174',
              name: '达孜工业园区'
            }
          ]
        },
        {
          value: '540200',
          name: '日喀则市',
          children: [
            {
              value: '540202',
              name: '桑珠孜区'
            },
            {
              value: '540221',
              name: '南木林县'
            },
            {
              value: '540222',
              name: '江孜县'
            },
            {
              value: '540223',
              name: '定日县'
            },
            {
              value: '540224',
              name: '萨迦县'
            },
            {
              value: '540225',
              name: '拉孜县'
            },
            {
              value: '540226',
              name: '昂仁县'
            },
            {
              value: '540227',
              name: '谢通门县'
            },
            {
              value: '540228',
              name: '白朗县'
            },
            {
              value: '540229',
              name: '仁布县'
            },
            {
              value: '540230',
              name: '康马县'
            },
            {
              value: '540231',
              name: '定结县'
            },
            {
              value: '540232',
              name: '仲巴县'
            },
            {
              value: '540233',
              name: '亚东县'
            },
            {
              value: '540234',
              name: '吉隆县'
            },
            {
              value: '540235',
              name: '聂拉木县'
            },
            {
              value: '540236',
              name: '萨嘎县'
            },
            {
              value: '540237',
              name: '岗巴县'
            }
          ]
        },
        {
          value: '540300',
          name: '昌都市',
          children: [
            {
              value: '540302',
              name: '卡若区'
            },
            {
              value: '540321',
              name: '江达县'
            },
            {
              value: '540322',
              name: '贡觉县'
            },
            {
              value: '540323',
              name: '类乌齐县'
            },
            {
              value: '540324',
              name: '丁青县'
            },
            {
              value: '540325',
              name: '察雅县'
            },
            {
              value: '540326',
              name: '八宿县'
            },
            {
              value: '540327',
              name: '左贡县'
            },
            {
              value: '540328',
              name: '芒康县'
            },
            {
              value: '540329',
              name: '洛隆县'
            },
            {
              value: '540330',
              name: '边坝县'
            }
          ]
        },
        {
          value: '540400',
          name: '林芝市',
          children: [
            {
              value: '540402',
              name: '巴宜区'
            },
            {
              value: '540421',
              name: '工布江达县'
            },
            {
              value: '540422',
              name: '米林县'
            },
            {
              value: '540423',
              name: '墨脱县'
            },
            {
              value: '540424',
              name: '波密县'
            },
            {
              value: '540425',
              name: '察隅县'
            },
            {
              value: '540426',
              name: '朗县'
            }
          ]
        },
        {
          value: '540500',
          name: '山南市',
          children: [
            {
              value: '540502',
              name: '乃东区'
            },
            {
              value: '540521',
              name: '扎囊县'
            },
            {
              value: '540522',
              name: '贡嘎县'
            },
            {
              value: '540523',
              name: '桑日县'
            },
            {
              value: '540524',
              name: '琼结县'
            },
            {
              value: '540525',
              name: '曲松县'
            },
            {
              value: '540526',
              name: '措美县'
            },
            {
              value: '540527',
              name: '洛扎县'
            },
            {
              value: '540528',
              name: '加查县'
            },
            {
              value: '540529',
              name: '隆子县'
            },
            {
              value: '540530',
              name: '错那县'
            },
            {
              value: '540531',
              name: '浪卡子县'
            }
          ]
        },
        {
          value: '540600',
          name: '那曲市',
          children: [
            {
              value: '540602',
              name: '色尼区'
            },
            {
              value: '540621',
              name: '嘉黎县'
            },
            {
              value: '540622',
              name: '比如县'
            },
            {
              value: '540623',
              name: '聂荣县'
            },
            {
              value: '540624',
              name: '安多县'
            },
            {
              value: '540625',
              name: '申扎县'
            },
            {
              value: '540626',
              name: '索县'
            },
            {
              value: '540627',
              name: '班戈县'
            },
            {
              value: '540628',
              name: '巴青县'
            },
            {
              value: '540629',
              name: '尼玛县'
            },
            {
              value: '540630',
              name: '双湖县'
            }
          ]
        },
        {
          value: '542500',
          name: '阿里地区',
          children: [
            {
              value: '542521',
              name: '普兰县'
            },
            {
              value: '542522',
              name: '札达县'
            },
            {
              value: '542523',
              name: '噶尔县'
            },
            {
              value: '542524',
              name: '日土县'
            },
            {
              value: '542525',
              name: '革吉县'
            },
            {
              value: '542526',
              name: '改则县'
            },
            {
              value: '542527',
              name: '措勤县'
            }
          ]
        }
      ]
    },
    {
      value: '610000',
      name: '陕西省',
      children: [
        {
          value: '610100',
          name: '西安市',
          children: [
            {
              value: '610102',
              name: '新城区'
            },
            {
              value: '610103',
              name: '碑林区'
            },
            {
              value: '610104',
              name: '莲湖区'
            },
            {
              value: '610111',
              name: '灞桥区'
            },
            {
              value: '610112',
              name: '未央区'
            },
            {
              value: '610113',
              name: '雁塔区'
            },
            {
              value: '610114',
              name: '阎良区'
            },
            {
              value: '610115',
              name: '临潼区'
            },
            {
              value: '610116',
              name: '长安区'
            },
            {
              value: '610117',
              name: '高陵区'
            },
            {
              value: '610118',
              name: '鄠邑区'
            },
            {
              value: '610122',
              name: '蓝田县'
            },
            {
              value: '610124',
              name: '周至县'
            }
          ]
        },
        {
          value: '610200',
          name: '铜川市',
          children: [
            {
              value: '610202',
              name: '王益区'
            },
            {
              value: '610203',
              name: '印台区'
            },
            {
              value: '610204',
              name: '耀州区'
            },
            {
              value: '610222',
              name: '宜君县'
            }
          ]
        },
        {
          value: '610300',
          name: '宝鸡市',
          children: [
            {
              value: '610302',
              name: '渭滨区'
            },
            {
              value: '610303',
              name: '金台区'
            },
            {
              value: '610304',
              name: '陈仓区'
            },
            {
              value: '610322',
              name: '凤翔县'
            },
            {
              value: '610323',
              name: '岐山县'
            },
            {
              value: '610324',
              name: '扶风县'
            },
            {
              value: '610326',
              name: '眉县'
            },
            {
              value: '610327',
              name: '陇县'
            },
            {
              value: '610328',
              name: '千阳县'
            },
            {
              value: '610329',
              name: '麟游县'
            },
            {
              value: '610330',
              name: '凤县'
            },
            {
              value: '610331',
              name: '太白县'
            }
          ]
        },
        {
          value: '610400',
          name: '咸阳市',
          children: [
            {
              value: '610402',
              name: '秦都区'
            },
            {
              value: '610403',
              name: '杨陵区'
            },
            {
              value: '610404',
              name: '渭城区'
            },
            {
              value: '610422',
              name: '三原县'
            },
            {
              value: '610423',
              name: '泾阳县'
            },
            {
              value: '610424',
              name: '乾县'
            },
            {
              value: '610425',
              name: '礼泉县'
            },
            {
              value: '610426',
              name: '永寿县'
            },
            {
              value: '610428',
              name: '长武县'
            },
            {
              value: '610429',
              name: '旬邑县'
            },
            {
              value: '610430',
              name: '淳化县'
            },
            {
              value: '610431',
              name: '武功县'
            },
            {
              value: '610481',
              name: '兴平市'
            },
            {
              value: '610482',
              name: '彬州市'
            }
          ]
        },
        {
          value: '610500',
          name: '渭南市',
          children: [
            {
              value: '610502',
              name: '临渭区'
            },
            {
              value: '610503',
              name: '华州区'
            },
            {
              value: '610522',
              name: '潼关县'
            },
            {
              value: '610523',
              name: '大荔县'
            },
            {
              value: '610524',
              name: '合阳县'
            },
            {
              value: '610525',
              name: '澄城县'
            },
            {
              value: '610526',
              name: '蒲城县'
            },
            {
              value: '610527',
              name: '白水县'
            },
            {
              value: '610528',
              name: '富平县'
            },
            {
              value: '610581',
              name: '韩城市'
            },
            {
              value: '610582',
              name: '华阴市'
            }
          ]
        },
        {
          value: '610600',
          name: '延安市',
          children: [
            {
              value: '610602',
              name: '宝塔区'
            },
            {
              value: '610603',
              name: '安塞区'
            },
            {
              value: '610621',
              name: '延长县'
            },
            {
              value: '610622',
              name: '延川县'
            },
            {
              value: '610625',
              name: '志丹县'
            },
            {
              value: '610626',
              name: '吴起县'
            },
            {
              value: '610627',
              name: '甘泉县'
            },
            {
              value: '610628',
              name: '富县'
            },
            {
              value: '610629',
              name: '洛川县'
            },
            {
              value: '610630',
              name: '宜川县'
            },
            {
              value: '610631',
              name: '黄龙县'
            },
            {
              value: '610632',
              name: '黄陵县'
            },
            {
              value: '610681',
              name: '子长市'
            }
          ]
        },
        {
          value: '610700',
          name: '汉中市',
          children: [
            {
              value: '610702',
              name: '汉台区'
            },
            {
              value: '610703',
              name: '南郑区'
            },
            {
              value: '610722',
              name: '城固县'
            },
            {
              value: '610723',
              name: '洋县'
            },
            {
              value: '610724',
              name: '西乡县'
            },
            {
              value: '610725',
              name: '勉县'
            },
            {
              value: '610726',
              name: '宁强县'
            },
            {
              value: '610727',
              name: '略阳县'
            },
            {
              value: '610728',
              name: '镇巴县'
            },
            {
              value: '610729',
              name: '留坝县'
            },
            {
              value: '610730',
              name: '佛坪县'
            }
          ]
        },
        {
          value: '610800',
          name: '榆林市',
          children: [
            {
              value: '610802',
              name: '榆阳区'
            },
            {
              value: '610803',
              name: '横山区'
            },
            {
              value: '610822',
              name: '府谷县'
            },
            {
              value: '610824',
              name: '靖边县'
            },
            {
              value: '610825',
              name: '定边县'
            },
            {
              value: '610826',
              name: '绥德县'
            },
            {
              value: '610827',
              name: '米脂县'
            },
            {
              value: '610828',
              name: '佳县'
            },
            {
              value: '610829',
              name: '吴堡县'
            },
            {
              value: '610830',
              name: '清涧县'
            },
            {
              value: '610831',
              name: '子洲县'
            },
            {
              value: '610881',
              name: '神木市'
            }
          ]
        },
        {
          value: '610900',
          name: '安康市',
          children: [
            {
              value: '610902',
              name: '汉滨区'
            },
            {
              value: '610921',
              name: '汉阴县'
            },
            {
              value: '610922',
              name: '石泉县'
            },
            {
              value: '610923',
              name: '宁陕县'
            },
            {
              value: '610924',
              name: '紫阳县'
            },
            {
              value: '610925',
              name: '岚皋县'
            },
            {
              value: '610926',
              name: '平利县'
            },
            {
              value: '610927',
              name: '镇坪县'
            },
            {
              value: '610928',
              name: '旬阳县'
            },
            {
              value: '610929',
              name: '白河县'
            }
          ]
        },
        {
          value: '611000',
          name: '商洛市',
          children: [
            {
              value: '611002',
              name: '商州区'
            },
            {
              value: '611021',
              name: '洛南县'
            },
            {
              value: '611022',
              name: '丹凤县'
            },
            {
              value: '611023',
              name: '商南县'
            },
            {
              value: '611024',
              name: '山阳县'
            },
            {
              value: '611025',
              name: '镇安县'
            },
            {
              value: '611026',
              name: '柞水县'
            }
          ]
        }
      ]
    },
    {
      value: '620000',
      name: '甘肃省',
      children: [
        {
          value: '620100',
          name: '兰州市',
          children: [
            {
              value: '620102',
              name: '城关区'
            },
            {
              value: '620103',
              name: '七里河区'
            },
            {
              value: '620104',
              name: '西固区'
            },
            {
              value: '620105',
              name: '安宁区'
            },
            {
              value: '620111',
              name: '红古区'
            },
            {
              value: '620121',
              name: '永登县'
            },
            {
              value: '620122',
              name: '皋兰县'
            },
            {
              value: '620123',
              name: '榆中县'
            },
            {
              value: '620171',
              name: '兰州新区'
            }
          ]
        },
        {
          value: '620200',
          name: '嘉峪关市',
          children: [
            {
              value: '620201',
              name: '嘉峪关市'
            }
          ]
        },
        {
          value: '620300',
          name: '金昌市',
          children: [
            {
              value: '620302',
              name: '金川区'
            },
            {
              value: '620321',
              name: '永昌县'
            }
          ]
        },
        {
          value: '620400',
          name: '白银市',
          children: [
            {
              value: '620402',
              name: '白银区'
            },
            {
              value: '620403',
              name: '平川区'
            },
            {
              value: '620421',
              name: '靖远县'
            },
            {
              value: '620422',
              name: '会宁县'
            },
            {
              value: '620423',
              name: '景泰县'
            }
          ]
        },
        {
          value: '620500',
          name: '天水市',
          children: [
            {
              value: '620502',
              name: '秦州区'
            },
            {
              value: '620503',
              name: '麦积区'
            },
            {
              value: '620521',
              name: '清水县'
            },
            {
              value: '620522',
              name: '秦安县'
            },
            {
              value: '620523',
              name: '甘谷县'
            },
            {
              value: '620524',
              name: '武山县'
            },
            {
              value: '620525',
              name: '张家川回族自治县'
            }
          ]
        },
        {
          value: '620600',
          name: '武威市',
          children: [
            {
              value: '620602',
              name: '凉州区'
            },
            {
              value: '620621',
              name: '民勤县'
            },
            {
              value: '620622',
              name: '古浪县'
            },
            {
              value: '620623',
              name: '天祝藏族自治县'
            }
          ]
        },
        {
          value: '620700',
          name: '张掖市',
          children: [
            {
              value: '620702',
              name: '甘州区'
            },
            {
              value: '620721',
              name: '肃南裕固族自治县'
            },
            {
              value: '620722',
              name: '民乐县'
            },
            {
              value: '620723',
              name: '临泽县'
            },
            {
              value: '620724',
              name: '高台县'
            },
            {
              value: '620725',
              name: '山丹县'
            }
          ]
        },
        {
          value: '620800',
          name: '平凉市',
          children: [
            {
              value: '620802',
              name: '崆峒区'
            },
            {
              value: '620821',
              name: '泾川县'
            },
            {
              value: '620822',
              name: '灵台县'
            },
            {
              value: '620823',
              name: '崇信县'
            },
            {
              value: '620825',
              name: '庄浪县'
            },
            {
              value: '620826',
              name: '静宁县'
            },
            {
              value: '620881',
              name: '华亭市'
            }
          ]
        },
        {
          value: '620900',
          name: '酒泉市',
          children: [
            {
              value: '620902',
              name: '肃州区'
            },
            {
              value: '620921',
              name: '金塔县'
            },
            {
              value: '620922',
              name: '瓜州县'
            },
            {
              value: '620923',
              name: '肃北蒙古族自治县'
            },
            {
              value: '620924',
              name: '阿克塞哈萨克族自治县'
            },
            {
              value: '620981',
              name: '玉门市'
            },
            {
              value: '620982',
              name: '敦煌市'
            }
          ]
        },
        {
          value: '621000',
          name: '庆阳市',
          children: [
            {
              value: '621002',
              name: '西峰区'
            },
            {
              value: '621021',
              name: '庆城县'
            },
            {
              value: '621022',
              name: '环县'
            },
            {
              value: '621023',
              name: '华池县'
            },
            {
              value: '621024',
              name: '合水县'
            },
            {
              value: '621025',
              name: '正宁县'
            },
            {
              value: '621026',
              name: '宁县'
            },
            {
              value: '621027',
              name: '镇原县'
            }
          ]
        },
        {
          value: '621100',
          name: '定西市',
          children: [
            {
              value: '621102',
              name: '安定区'
            },
            {
              value: '621121',
              name: '通渭县'
            },
            {
              value: '621122',
              name: '陇西县'
            },
            {
              value: '621123',
              name: '渭源县'
            },
            {
              value: '621124',
              name: '临洮县'
            },
            {
              value: '621125',
              name: '漳县'
            },
            {
              value: '621126',
              name: '岷县'
            }
          ]
        },
        {
          value: '621200',
          name: '陇南市',
          children: [
            {
              value: '621202',
              name: '武都区'
            },
            {
              value: '621221',
              name: '成县'
            },
            {
              value: '621222',
              name: '文县'
            },
            {
              value: '621223',
              name: '宕昌县'
            },
            {
              value: '621224',
              name: '康县'
            },
            {
              value: '621225',
              name: '西和县'
            },
            {
              value: '621226',
              name: '礼县'
            },
            {
              value: '621227',
              name: '徽县'
            },
            {
              value: '621228',
              name: '两当县'
            }
          ]
        },
        {
          value: '622900',
          name: '临夏回族自治州',
          children: [
            {
              value: '622901',
              name: '临夏市'
            },
            {
              value: '622921',
              name: '临夏县'
            },
            {
              value: '622922',
              name: '康乐县'
            },
            {
              value: '622923',
              name: '永靖县'
            },
            {
              value: '622924',
              name: '广河县'
            },
            {
              value: '622925',
              name: '和政县'
            },
            {
              value: '622926',
              name: '东乡族自治县'
            },
            {
              value: '622927',
              name: '积石山保安族东乡族撒拉族自治县'
            }
          ]
        },
        {
          value: '623000',
          name: '甘南藏族自治州',
          children: [
            {
              value: '623001',
              name: '合作市'
            },
            {
              value: '623021',
              name: '临潭县'
            },
            {
              value: '623022',
              name: '卓尼县'
            },
            {
              value: '623023',
              name: '舟曲县'
            },
            {
              value: '623024',
              name: '迭部县'
            },
            {
              value: '623025',
              name: '玛曲县'
            },
            {
              value: '623026',
              name: '碌曲县'
            },
            {
              value: '623027',
              name: '夏河县'
            }
          ]
        }
      ]
    },
    {
      value: '630000',
      name: '青海省',
      children: [
        {
          value: '630100',
          name: '西宁市',
          children: [
            {
              value: '630102',
              name: '城东区'
            },
            {
              value: '630103',
              name: '城中区'
            },
            {
              value: '630104',
              name: '城西区'
            },
            {
              value: '630105',
              name: '城北区'
            },
            {
              value: '630121',
              name: '大通回族土族自治县'
            },
            {
              value: '630122',
              name: '湟中县'
            },
            {
              value: '630123',
              name: '湟源县'
            }
          ]
        },
        {
          value: '630200',
          name: '海东市',
          children: [
            {
              value: '630202',
              name: '乐都区'
            },
            {
              value: '630203',
              name: '平安区'
            },
            {
              value: '630222',
              name: '民和回族土族自治县'
            },
            {
              value: '630223',
              name: '互助土族自治县'
            },
            {
              value: '630224',
              name: '化隆回族自治县'
            },
            {
              value: '630225',
              name: '循化撒拉族自治县'
            }
          ]
        },
        {
          value: '632200',
          name: '海北藏族自治州',
          children: [
            {
              value: '632221',
              name: '门源回族自治县'
            },
            {
              value: '632222',
              name: '祁连县'
            },
            {
              value: '632223',
              name: '海晏县'
            },
            {
              value: '632224',
              name: '刚察县'
            }
          ]
        },
        {
          value: '632300',
          name: '黄南藏族自治州',
          children: [
            {
              value: '632321',
              name: '同仁县'
            },
            {
              value: '632322',
              name: '尖扎县'
            },
            {
              value: '632323',
              name: '泽库县'
            },
            {
              value: '632324',
              name: '河南蒙古族自治县'
            }
          ]
        },
        {
          value: '632500',
          name: '海南藏族自治州',
          children: [
            {
              value: '632521',
              name: '共和县'
            },
            {
              value: '632522',
              name: '同德县'
            },
            {
              value: '632523',
              name: '贵德县'
            },
            {
              value: '632524',
              name: '兴海县'
            },
            {
              value: '632525',
              name: '贵南县'
            }
          ]
        },
        {
          value: '632600',
          name: '果洛藏族自治州',
          children: [
            {
              value: '632621',
              name: '玛沁县'
            },
            {
              value: '632622',
              name: '班玛县'
            },
            {
              value: '632623',
              name: '甘德县'
            },
            {
              value: '632624',
              name: '达日县'
            },
            {
              value: '632625',
              name: '久治县'
            },
            {
              value: '632626',
              name: '玛多县'
            }
          ]
        },
        {
          value: '632700',
          name: '玉树藏族自治州',
          children: [
            {
              value: '632701',
              name: '玉树市'
            },
            {
              value: '632722',
              name: '杂多县'
            },
            {
              value: '632723',
              name: '称多县'
            },
            {
              value: '632724',
              name: '治多县'
            },
            {
              value: '632725',
              name: '囊谦县'
            },
            {
              value: '632726',
              name: '曲麻莱县'
            }
          ]
        },
        {
          value: '632800',
          name: '海西蒙古族藏族自治州',
          children: [
            {
              value: '632801',
              name: '格尔木市'
            },
            {
              value: '632802',
              name: '德令哈市'
            },
            {
              value: '632803',
              name: '茫崖市'
            },
            {
              value: '632821',
              name: '乌兰县'
            },
            {
              value: '632822',
              name: '都兰县'
            },
            {
              value: '632823',
              name: '天峻县'
            },
            {
              value: '632857',
              name: '大柴旦行政委员会'
            }
          ]
        }
      ]
    },
    {
      value: '640000',
      name: '宁夏回族自治区',
      children: [
        {
          value: '640100',
          name: '银川市',
          children: [
            {
              value: '640104',
              name: '兴庆区'
            },
            {
              value: '640105',
              name: '西夏区'
            },
            {
              value: '640106',
              name: '金凤区'
            },
            {
              value: '640121',
              name: '永宁县'
            },
            {
              value: '640122',
              name: '贺兰县'
            },
            {
              value: '640181',
              name: '灵武市'
            }
          ]
        },
        {
          value: '640200',
          name: '石嘴山市',
          children: [
            {
              value: '640202',
              name: '大武口区'
            },
            {
              value: '640205',
              name: '惠农区'
            },
            {
              value: '640221',
              name: '平罗县'
            }
          ]
        },
        {
          value: '640300',
          name: '吴忠市',
          children: [
            {
              value: '640302',
              name: '利通区'
            },
            {
              value: '640303',
              name: '红寺堡区'
            },
            {
              value: '640323',
              name: '盐池县'
            },
            {
              value: '640324',
              name: '同心县'
            },
            {
              value: '640381',
              name: '青铜峡市'
            }
          ]
        },
        {
          value: '640400',
          name: '固原市',
          children: [
            {
              value: '640402',
              name: '原州区'
            },
            {
              value: '640422',
              name: '西吉县'
            },
            {
              value: '640423',
              name: '隆德县'
            },
            {
              value: '640424',
              name: '泾源县'
            },
            {
              value: '640425',
              name: '彭阳县'
            }
          ]
        },
        {
          value: '640500',
          name: '中卫市',
          children: [
            {
              value: '640502',
              name: '沙坡头区'
            },
            {
              value: '640521',
              name: '中宁县'
            },
            {
              value: '640522',
              name: '海原县'
            }
          ]
        }
      ]
    },
    {
      value: '650000',
      name: '新疆维吾尔自治区',
      children: [
        {
          value: '650100',
          name: '乌鲁木齐市',
          children: [
            {
              value: '650102',
              name: '天山区'
            },
            {
              value: '650103',
              name: '沙依巴克区'
            },
            {
              value: '650104',
              name: '新市区'
            },
            {
              value: '650105',
              name: '水磨沟区'
            },
            {
              value: '650106',
              name: '头屯河区'
            },
            {
              value: '650107',
              name: '达坂城区'
            },
            {
              value: '650109',
              name: '米东区'
            },
            {
              value: '650121',
              name: '乌鲁木齐县'
            }
          ]
        },
        {
          value: '650200',
          name: '克拉玛依市',
          children: [
            {
              value: '650202',
              name: '独山子区'
            },
            {
              value: '650203',
              name: '克拉玛依区'
            },
            {
              value: '650204',
              name: '白碱滩区'
            },
            {
              value: '650205',
              name: '乌尔禾区'
            }
          ]
        },
        {
          value: '650400',
          name: '吐鲁番市',
          children: [
            {
              value: '650402',
              name: '高昌区'
            },
            {
              value: '650421',
              name: '鄯善县'
            },
            {
              value: '650422',
              name: '托克逊县'
            }
          ]
        },
        {
          value: '650500',
          name: '哈密市',
          children: [
            {
              value: '650502',
              name: '伊州区'
            },
            {
              value: '650521',
              name: '巴里坤哈萨克自治县'
            },
            {
              value: '650522',
              name: '伊吾县'
            }
          ]
        },
        {
          value: '652300',
          name: '昌吉回族自治州',
          children: [
            {
              value: '652301',
              name: '昌吉市'
            },
            {
              value: '652302',
              name: '阜康市'
            },
            {
              value: '652323',
              name: '呼图壁县'
            },
            {
              value: '652324',
              name: '玛纳斯县'
            },
            {
              value: '652325',
              name: '奇台县'
            },
            {
              value: '652327',
              name: '吉木萨尔县'
            },
            {
              value: '652328',
              name: '木垒哈萨克自治县'
            }
          ]
        },
        {
          value: '652700',
          name: '博尔塔拉蒙古自治州',
          children: [
            {
              value: '652701',
              name: '博乐市'
            },
            {
              value: '652702',
              name: '阿拉山口市'
            },
            {
              value: '652722',
              name: '精河县'
            },
            {
              value: '652723',
              name: '温泉县'
            }
          ]
        },
        {
          value: '652800',
          name: '巴音郭楞蒙古自治州',
          children: [
            {
              value: '652801',
              name: '库尔勒市'
            },
            {
              value: '652822',
              name: '轮台县'
            },
            {
              value: '652823',
              name: '尉犁县'
            },
            {
              value: '652824',
              name: '若羌县'
            },
            {
              value: '652825',
              name: '且末县'
            },
            {
              value: '652826',
              name: '焉耆回族自治县'
            },
            {
              value: '652827',
              name: '和静县'
            },
            {
              value: '652828',
              name: '和硕县'
            },
            {
              value: '652829',
              name: '博湖县'
            },
            {
              value: '652871',
              name: '库尔勒经济技术开发区'
            }
          ]
        },
        {
          value: '652900',
          name: '阿克苏地区',
          children: [
            {
              value: '652901',
              name: '阿克苏市'
            },
            {
              value: '652922',
              name: '温宿县'
            },
            {
              value: '652923',
              name: '库车县'
            },
            {
              value: '652924',
              name: '沙雅县'
            },
            {
              value: '652925',
              name: '新和县'
            },
            {
              value: '652926',
              name: '拜城县'
            },
            {
              value: '652927',
              name: '乌什县'
            },
            {
              value: '652928',
              name: '阿瓦提县'
            },
            {
              value: '652929',
              name: '柯坪县'
            }
          ]
        },
        {
          value: '653000',
          name: '克孜勒苏柯尔克孜自治州',
          children: [
            {
              value: '653001',
              name: '阿图什市'
            },
            {
              value: '653022',
              name: '阿克陶县'
            },
            {
              value: '653023',
              name: '阿合奇县'
            },
            {
              value: '653024',
              name: '乌恰县'
            }
          ]
        },
        {
          value: '653100',
          name: '喀什地区',
          children: [
            {
              value: '653101',
              name: '喀什市'
            },
            {
              value: '653121',
              name: '疏附县'
            },
            {
              value: '653122',
              name: '疏勒县'
            },
            {
              value: '653123',
              name: '英吉沙县'
            },
            {
              value: '653124',
              name: '泽普县'
            },
            {
              value: '653125',
              name: '莎车县'
            },
            {
              value: '653126',
              name: '叶城县'
            },
            {
              value: '653127',
              name: '麦盖提县'
            },
            {
              value: '653128',
              name: '岳普湖县'
            },
            {
              value: '653129',
              name: '伽师县'
            },
            {
              value: '653130',
              name: '巴楚县'
            },
            {
              value: '653131',
              name: '塔什库尔干塔吉克自治县'
            }
          ]
        },
        {
          value: '653200',
          name: '和田地区',
          children: [
            {
              value: '653201',
              name: '和田市'
            },
            {
              value: '653221',
              name: '和田县'
            },
            {
              value: '653222',
              name: '墨玉县'
            },
            {
              value: '653223',
              name: '皮山县'
            },
            {
              value: '653224',
              name: '洛浦县'
            },
            {
              value: '653225',
              name: '策勒县'
            },
            {
              value: '653226',
              name: '于田县'
            },
            {
              value: '653227',
              name: '民丰县'
            }
          ]
        },
        {
          value: '654000',
          name: '伊犁哈萨克自治州',
          children: [
            {
              value: '654002',
              name: '伊宁市'
            },
            {
              value: '654003',
              name: '奎屯市'
            },
            {
              value: '654004',
              name: '霍尔果斯市'
            },
            {
              value: '654021',
              name: '伊宁县'
            },
            {
              value: '654022',
              name: '察布查尔锡伯自治县'
            },
            {
              value: '654023',
              name: '霍城县'
            },
            {
              value: '654024',
              name: '巩留县'
            },
            {
              value: '654025',
              name: '新源县'
            },
            {
              value: '654026',
              name: '昭苏县'
            },
            {
              value: '654027',
              name: '特克斯县'
            },
            {
              value: '654028',
              name: '尼勒克县'
            }
          ]
        },
        {
          value: '654200',
          name: '塔城地区',
          children: [
            {
              value: '654201',
              name: '塔城市'
            },
            {
              value: '654202',
              name: '乌苏市'
            },
            {
              value: '654221',
              name: '额敏县'
            },
            {
              value: '654223',
              name: '沙湾县'
            },
            {
              value: '654224',
              name: '托里县'
            },
            {
              value: '654225',
              name: '裕民县'
            },
            {
              value: '654226',
              name: '和布克赛尔蒙古自治县'
            }
          ]
        },
        {
          value: '654300',
          name: '阿勒泰地区',
          children: [
            {
              value: '654301',
              name: '阿勒泰市'
            },
            {
              value: '654321',
              name: '布尔津县'
            },
            {
              value: '654322',
              name: '富蕴县'
            },
            {
              value: '654323',
              name: '福海县'
            },
            {
              value: '654324',
              name: '哈巴河县'
            },
            {
              value: '654325',
              name: '青河县'
            },
            {
              value: '654326',
              name: '吉木乃县'
            }
          ]
        },
        {
          value: '659000',
          name: '自治区直辖县级行政',
          children: [
            {
              value: '659001',
              name: '石河子市'
            },
            {
              value: '659002',
              name: '阿拉尔市'
            },
            {
              value: '659003',
              name: '图木舒克市'
            },
            {
              value: '659004',
              name: '五家渠市'
            },
            {
              value: '659006',
              name: '铁门关市'
            }
          ]
        }
      ]
    }
  ]
  return cityData
}
